import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loader from './Loader';
import AppHeader from './AppHeader';
import $ from "jquery";
import ModalAlert from './ModalAlert';

class VerifyOTP extends Component {
	constructor(props) {
		super(props);
		this.state = {
			LOADING: true, 
			Notifications: "false",
			modalType:'ALERT', 
			notifType:'', 
			modalTitle:'',
			customerid: localStorage.getItem('userData2'),
			customerdet: []
		};
	}

	handleSubmit = (e) => {
		e.preventDefault();

		let err = 0;
		if ($('#frmOTP')[0].checkValidity() === false) {
			e.preventDefault();
			e.stopPropagation();
			err++;
		}
		$('#frmOTP')[0].classList.add('was-validated');

		if(err===0)
		{
			const method = "POST";
			const body = new FormData(this.form);
          	fetch(global.config.bURL+'userDetails.php', { method, body })
          	.then(function (response) {
              return response.json();
          	})
          	.then(result => {
              let notify = '';
              let notifyType = "success";
              if(result.err!=='' && result.err!==undefined) {
              	notify = result.err;
              	notifyType = "error";
              }

              let customer = result.customer;
              if(customer!=='' && customer!==undefined)
              {
              	localStorage.setItem('userData',customer);
              	localStorage.removeItem("userData2");
              	localStorage.removeItem("userPhone");
              }

              this.setState({ 
              	notifType:notifyType,
              	Notifications: notify
              });
              $('#submitLoading').css('display','none');
              if(notify!=='')
              	window.$('#ModalAlert').modal('show');
          });
		}
	}

	componentDidMount() {
		const posturl = global.config.bURL+"userDetails.php";
		const postData = {
			method: 'POST',
		    headers: { 'Content-Type': 'application/json' },
		    body: JSON.stringify({mode: 'CUSTOMER', id: this.state.customerid})
		}
		
		fetch(posturl, postData)
			.then(res => res.json())
			.then(result => {
				// console.log('results obtained', result);
		
				let notify = '';
				if(result.err!=='' && result.err!==undefined)
				    notify = result.err;
		
				this.setState({ 
					LOADING: false,
					customerdet: result.user,
				});
		
				if(notify!=='') {
		    		window.$('#ModalAlert').modal('show');
				}
			})
			.catch(error => {
		        this.setState({ Notifications: error.toString() });
		        console.error('There was an error!', error);
		    });
	}

	render() {
		let AppHeaderClass = 'appHeader no-border bg-coach-header text-light pt-2 pb-2';
		let AppHeaderLeft = false;//'showLINK';
		let AppHeaderLeftLink = '/register';
		let AppHeaderTitle = <h5 className="text-upper lt-spc1 text-grey5b pt-05 pb-05">Verification Code</h5>;
		let AppHeaderIMAGE = '';
		let AppHeaderRight = '';
		let AppHeaderRightLink = '';

		if(localStorage.getItem('userData')) {
			return (<Redirect to="/home" />)
		}

		return(
			<>

				{this.state.LOADING && 
	                <Loader />
	            }

	            <AppHeader 
	                AppHeaderClass={AppHeaderClass} 
	                AppHeaderLeft={AppHeaderLeft} 
	                AppHeaderLeftLink={AppHeaderLeftLink} 
	                AppHeaderTitle={AppHeaderTitle} 
	                AppHeaderRight={AppHeaderRight} 
	                AppHeaderRightLink={AppHeaderRightLink} 
	            />

				<div id="appCapsule">
					<div className="section otp-block register full mt-0 mb-2 basic-form">
						<div className="wide-block no-border mt-1 pb-1 pt-2 pr-3 pl-3">
							<div className="mb-3 pb-0">
								<div className="image-cover">
									<div className="inside-cover">
										<img src="assets/images/forgot-password.png" alt="alt" className="imaged" />
									</div>
								</div>
								<p className="text-center font-18 ft-wt-500 text-black0 pr-1 pl-1 l-30">Enter the 4 digit verification code you received on +91 {(this.state.customerdet && this.state.customerdet.MOBILE)?this.state.customerdet.MOBILE:'XXXXXXXXXX'}</p>
							</div>

							<form id="frmOTP" ref={el =>(this.form = el)} onSubmit={this.handleSubmit} className="needs-validation" noValidate autoComplete="off">
  			                <input type="hidden" value="VERIFY_OTP" className="form-control" name="mode" id="mode"  />
  			                <input type="hidden" defaultValue={localStorage.getItem('userData2')} className="form-control" name="id" id="id"  />
								<div className="listview image-listview no-line no-space flush mr-2 ml-2 pb-4">
									<div className="form-group boxed">
										<div className="input-wrapper">
											<input type="text" className="placeholder-size box-shadowg2 square-inputbox form-control verify-input verify-input-1" name="otp" id="otp" placeholder="••••" maxLength="4" />
										</div>
									</div>
									<div className="form-group basic">
										<div className="input-wrapper">
											<div className="w-100 pt-2">
												<button type="submit" className="btn w-100 rounded bt-bg-gold btn-block btn-lg mr-0 ml-0 text-upper font-14 lt-spc2 ft-wt-700 btn-ht60 box-shadowg">Send</button>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>

				<ModalAlert modalType={this.state.modalType} Notifications={this.state.Notifications} type={this.state.notifType} modalTitle={this.state.modalTitle} />

			</>
		)
	}
}

export default VerifyOTP;