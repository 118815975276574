import React from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
import Loader from './Loader';
import CurrentLocation from './Map2';
import AppHeader from './AppHeader';
import ModalAlert from './ModalAlert';
import $ from 'jquery';

const mapStyles = {
    width: '90%',
    height: '300px'
  };
  
class AddAddress2 extends React.Component {
    constructor(props) {
        //console.log(props);
        super(props);
        this.state = {
            LOADING: true,
            addressAdded: false, 
            CustomerAddresses: '',
            Notifications: false,
            modalType:'ALERT', 
            notifType:'error', 
            modalTitle:'',
            customerid: localStorage.getItem('userData'),
            DeliveryCirlce: '',
            DeliveryArea: '',
            showingInfoWindow: false,  //Hides or the shows the infoWindow
            activeMarker: {},          //Shows the active marker upon click
            selectedPlace: {},          //Shows the infoWindow to the selected place upon a marker
            address: '',
            address2: '',
            address3: '',
            road: '',
            area: '',
            lat: '',
            long: '',
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let err = 0;
        if ($('#frmAddAddress')[0].checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            err++;
        }
        $('#frmAddAddress')[0].classList.add('was-validated');

        if(err==0)
        {
            $('#submitLoading').css('display','block');
            /*const body = new FormData(this.form);
            fetch(global.config.bURL+'customerRegister.php', { method, body })*/

            const posturl = global.config.bURL+"customeraddaddress_api.php";
            const method = "POST";
            const body = new FormData(this.form);
            var that = this;

            fetch(posturl, { method, body })
            .then(function (response) {
                //console.log(response);
                return response.json();
            })
            .then(function (result) {

                let notify = 'Address updated successfully';
                let notifyType = 'success';
                let addressAdded = false;
                if(result.err!=='' && result.err!==undefined)
                {
                    notify = result.err;
                    notifyType = 'error';
                    addressAdded = true;
                }

                that.setState({
                    Notifications: notify,
                    notifType: notifyType
                })

                window.$('#submitLoading').css('display','none');
                if(notify!='')
                    window.$('#ModalAlert').modal('show');
          });
        }
    }

    componentDidMount() {
        this.setState({ LOADING:false });

        localStorage.setItem('MS_PASSED_LAT', "15.5388");
        localStorage.setItem('MS_PASSED_LONG', "73.8315");
        navigator.geolocation.getCurrentPosition(function(position) {
            localStorage.setItem('MS_PASSED_LAT', position.coords.latitude);
            localStorage.setItem('MS_PASSED_LONG', position.coords.longitude);
        });
    }

    componentWillMount() {
        let mount = $("div").hasClass("modal-backdrop");
        if(mount==true)
        {
            $('body').removeClass('modal-open');
            $(".modal-backdrop").remove();
            //window.location.reload(true);
        }

        let lat = localStorage.getItem('MS_PASSED_LAT');
        let long = localStorage.getItem('MS_PASSED_LONG');

        if(lat===null || lat==='{}' || long===null || long==='{}')
        {
            lat = localStorage.getItem('MS_CIRCLE_LAT');
            long = localStorage.getItem('MS_CIRCLE_LONG');
        }

        if(lat===null || lat==='{}' || long===null || long==='{}')
        {
            lat = "15.5388";
            long = "73.8315";
        }

        //console.log(lat);
        //console.log(long);

        this.setState({ lat:lat, long:long});

        const requestOption = {
            method: 'GET'
        };

        fetch('https://maps.googleapis.com/maps/api/geocode/json?address='+lat+','+long+'&key=AIzaSyCTkUK9u3gNBcNnw4PDgbIfXCYDiixfd7U', requestOption)
        .then(res => res.json())
        .then(response => {

            var road = '';
            var block = '';
            var area = '';
            var area2 = '';
            var area3 = '';

            for(var j = 0; j<response.results.length; j++)
            {
                if(response.results[j].address_components)
                {
                    for(var i in response.results[j].address_components)
                    {
                        if(response.results[j].address_components[i].types[0]==='route')
                            road = response.results[j].address_components[i].long_name;
                        if(response.results[j].address_components[i].types[0]==='neighborhood' && response.results[j].address_components[i].types[1]==='political')
                            block = response.results[j].address_components[i].long_name;
                        if(area==='' && response.results[j].address_components[i].types[0]==='political' && response.results[j].address_components[i].types[1]==='sublocality')
                            area = response.results[j].address_components[i].long_name;
                        if(area==='' && response.results[j].address_components[i].types[0]==='locality' && response.results[j].address_components[i].types[1]==='political')
                            area = response.results[j].address_components[i].long_name;
                        if(area!=='' && area2==='' && response.results[j].address_components[i].types[0]==='locality' && response.results[j].address_components[i].types[1]==='political')
                            area2 = response.results[j].address_components[i].long_name;
                            
                        //console.log(response.results[j].address_components[i].long_name);
                    }
                }
            }

            let address = response.results[0].formatted_address;

            if(area2!=='') area += ', '+area2;

            this.setState({ address: '', address2:'', address3:block, road:road, area:area, lat:lat, long:long, landmark:'' });
        })
        .catch(error => {
            console.warn(error);
        });
    }

    onMarkerClick = (props, marker, e) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });

    handleGetAddress = (address,address3,road,area,lat,long) => {
        this.setState({ address: address, address3: address3, road:road, area:area, lat:lat, long:long})
    }

    updateGoogleMarker = (e) => {
        var address = e.target.value;
        if(address!='')
        {
            var that = this;
            fetch('https://shoppee.pinpae.com/pwa/api/addresslatlong_api.php?mode=LATLONG&address='+address,{
                mode: 'cors',
                credentials: 'same-origin',
                headers: {
                    'Access-Control-Allow-Origin': '*', 
                    Accept: 'application/json', 
                    'Content-Type': 'application/json',
                },
            }).then(function (response) {
                return response.json();
            }).then(function (result) {
                //console.log(result);
                if(result.DATA=='Y')
                {
                    that.setState({ address: address, lat:result.LAT, long:result.LONG });
                }
            });
        }
    }

    onMarkerDragEnd = (coord) => {
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();
        
        const requestOption = {
            method: 'GET'
        };
  
        //console.log(lat +','+ lng);
        let address = '';
        fetch('https://maps.googleapis.com/maps/api/geocode/json?address='+lat+','+lng+'&key=AIzaSyCTkUK9u3gNBcNnw4PDgbIfXCYDiixfd7U', requestOption)
        .then(res => res.json())
        .then(response => {

            var road = '';
            var block = '';
            var area = '';

            for(var j = 0; j<response.results.length; j++)
            {
                if(response.results[j].address_components)
                {
                    for(var i in response.results[j].address_components)
                    {
                        if(response.results[j].address_components[i].types[0]==='route')
                            road = response.results[j].address_components[i].long_name;
                        if(response.results[j].address_components[i].types[0]==='neighborhood' && response.results[j].address_components[i].types[1]==='political')
                            block = response.results[j].address_components[i].long_name;
                        if(response.results[j].address_components[i].types[0]==='political' && response.results[j].address_components[i].types[1]==='sublocality')
                            area = response.results[j].address_components[i].long_name;
                        if(area==='' && response.results[j].address_components[i].types[0]==='locality' && response.results[j].address_components[i].types[1]==='political')
                            area = response.results[j].address_components[i].long_name;

                        //console.log(response.results[j].address_components[i].long_name);
                    }
                }
            }

          address = response.results[0].formatted_address;
          this.setState({ address: address, road:road, address3:block, area:area, lat:lat, long:lng})
          
        })
        .catch(error => {
            console.warn(error);
        });
  
    };

    onClose = props => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    render() { 

        if(!localStorage.getItem('userData')) {
            return (<Redirect to={'/logout'} />)
        }
        
        //localStorage.removeItem("MS_userData");
        //localStorage.removeItem("MS_cartData");
        //localStorage.removeItem("MS_delCity");
        //localStorage.removeItem("MS_delArea");
        //console.log(localStorage.getItem('MS_userData'));
        
        /*if(this.state.addressAdded)
        {
            return (<Redirect to={'/checkout'}/>)
        }*/

        let AppHeaderClass = 'appHeader no-border';
        let AppHeaderLeft = 'showLINK';
        let AppHeaderLeftLink = '/booking2';
        let AppHeaderTitle = 'New Address';
        let AppHeaderIMAGE = '';
        let AppHeaderRight = '';
        let AppHeaderRightLink = '';
        let AppHeaderRightPic = process.env.PUBLIC_URL+'/assets/images/unknown-b.jpg';
        let customerName = (this.state.customerdet)?this.state.customerdet.NAME:'Customer';

        return (

            <div>
                {this.state.LOADING && <Loader />}

                {AppHeaderTitle != '' &&
                  <AppHeader 
                      AppHeaderClass={AppHeaderClass} 
                      AppHeaderLeft={AppHeaderLeft} 
                      AppHeaderLeftLink={AppHeaderLeftLink} 
                      AppHeaderTitle={AppHeaderTitle} 
                      AppHeaderRight={AppHeaderRight} 
                      AppHeaderRightLink={AppHeaderRightLink} 
                      AppHeaderRightPic={AppHeaderRightPic} 
                      customerName={customerName} 
                  />
                }

                <div id="appCapsule" className="pt-0">
                    <div className="login-form mt-1">
                        <div className="section p-0" style={{height: '300px', width: '100%', marginTop: '57px'}}>
                            <div id="map"> {/*onClick={this.onMarkerClick}*/}
                                <CurrentLocation
                                    centerAroundCurrentLocation
                                    google={this.props.google}
                                    handleGetAddress={this.handleGetAddress}
                                    initialCenter={{ lat: this.state.lat, lng: this.state.long }}
                                >
                                    <Marker 
                                        draggable={true} 
                                        name={this.state.address}
                                        position={{ lat: this.state.lat, lng: this.state.long }}   
                                        onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}/>

                                    <InfoWindow
                                    marker={this.state.activeMarker}
                                    visible={this.state.showingInfoWindow}
                                    onClose={this.onClose}
                                    >
                                    <div>
                                        <h4>{this.state.selectedPlace.name}</h4>
                                    </div>
                                    </InfoWindow>
                                </CurrentLocation>
                            </div>
                        </div>
                        <div className="section mt-1 mb-5 register basic-form">
                            <form id="frmAddAddress" ref={el => (this.form = el)} onSubmit={this.handleSubmit} className="needs-validation" noValidate>
                                <input type="hidden" value="ADD_ADDRESS" className="form-control" name="mode" id="mode" />
                                <input type="hidden" value={this.state.customerid} className="form-control" name="customer" id="customer" />
                                <input type="hidden" defaultValue={this.state.lat} className="form-control" name="lat" id="lat" required />
                                <input type="hidden" defaultValue={this.state.long} className="form-control" name="long" id="long" required />
                                <div className="form-group basic  mt-3">
                                    <div className="input-wrapper">
                                        <label className="label eform-label" htmlFor="txtaddress">Apartment/House/Office No:</label>
                                        <input type="text" className="square-inputbox form-control" name="txtaddress" id="txtaddress" required defaultValue={this.state.address} autoComplete="off"/> {/* onBlur={this.updateGoogleMarker} */}
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                            <div className="valid-feedback">&nbsp;</div> 
                                            <div className="invalid-feedback">Please enter your apartment/house/office no.</div> 
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label eform-label" htmlFor="txtaddress2">Floor, Building:</label>
                                        <input type="text" className="form-control" name="txtaddress2" id="txtaddress2" defaultValue={this.state.address2} autoComplete="off"/>
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                            <div className="valid-feedback">&nbsp;</div> 
                                            <div className="invalid-feedback">Please enter your floor, building.</div> 
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label eform-label" htmlFor="txtaddress3">Block:</label>
                                        <input type="text" className="form-control" name="txtaddress3" id="txtaddress3" defaultValue={this.state.address3} autoComplete="off"/>
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                            <div className="valid-feedback">&nbsp;</div> 
                                            <div className="invalid-feedback">Please enter your block.</div> 
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label eform-label" htmlFor="txtroad">Road:</label>
                                        <input type="text" className="form-control" name="txtroad" id="txtroad" defaultValue={this.state.road} autoComplete="off"/>
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                            <div className="valid-feedback">&nbsp;</div> 
                                            <div className="invalid-feedback">Please enter your road.</div> 
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label eform-label" htmlFor="txtarea">Area:</label>
                                        <input type="text" className="form-control" name="txtarea" id="txtarea" defaultValue={this.state.area} autoComplete="off"/>
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                            <div className="valid-feedback">&nbsp;</div> 
                                            <div className="invalid-feedback">Please enter your area.</div> 
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label eform-label" htmlFor="txtlandmark">Landmark:</label>
                                        <input type="text" className="square-inputbox form-control" id="txtlandmark" name="txtlandmark" autoComplete="off"/>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div> 
                                        <div className="invalid-feedback">Please enter landmark.</div>  
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper">
                                        <label className="label eform-label" htmlFor="txtlabel">Label:</label>
                                        <input type="text" className="square-inputbox form-control" id="txtlabel" name="txtlabel" placeholder="Ex: Home, Work, Office etc..." required autoComplete="off"/>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div> 
                                        <div className="invalid-feedback">Please enter label.</div>  
                                    </div>
                                </div>
                                <div className="form-group basic">
                                    <div className="input-wrapper default-address">
                                        <label className="label eform-label font-13" htmlFor="txtlabel">Set as default address:</label>
                                        <div className="flex-disp">
                                            <div className="custom-control custom-checkbox" >
                                                <input type="radio" className="custom-control-input" id="addrY" name="defaultAddr" defaultValue="Y" />
                                                <label className="custom-control-label font-13" htmlFor="addrY">Yes</label>
                                            </div>
                                            <div className="custom-control custom-checkbox" >
                                                <input type="radio" className="custom-control-input" id="addrN" name="defaultAddr" defaultValue="N" />
                                                <label className="custom-control-label font-13" htmlFor="addrN">No</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="form-group boxed mb-1">
                                    <div className="wid-20">
                                        <div className="input-wrapper">
                                            <label className="label eform-label" htmlFor="txtcode">Code</label>
                                            <input type="text" className="square-inputbox form-control" name="txtcode" id="txtcode" required minLength="3" maxLength="6" defaultValue='+91' autoComplete="off" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                            <div className="valid-feedback">&nbsp;</div>
                                            <div className="invalid-feedback">Please enter your area code.</div>
                                        </div>
                                    </div>
                                    <div className="wid-80"> 
                                        <div className="input-wrapper">
                                            <label className="label" htmlFor="txtmobile">Mobile No</label>
                                            <input type="text" className="square-inputbox form-control" name="txtmobile" id="txtmobile" required minLength="10" maxLength="11" pattern="[0-9]{10}" autoComplete="off" />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                            <div className="valid-feedback">&nbsp;</div>
                                            <div className="invalid-feedback">Please enter your 10 digit mobile no.</div>
                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                </div>*/}
                                <div className="form-button-group">
                                    <button type="submit" className="btn w-100 rounded bt-bg-gold btn-block btn-lg mr-0 ml-0 text-upper font-14 lt-spc2 ft-wt-600 btn-ht60 box-shadowg">
                                        <span className="spinner-border spinner-border-sm mr-05" id="submitLoading" style={{display: 'none'}} role="status" aria-hidden="true"></span>
                                        SAVE
                                    </button>
                                </div>
                            </form>
                        </div>
                        {this.state.Notifications!='' &&
                            <ModalAlert modalType={this.state.modalType} goto_url="/booking2" Notifications={this.state.Notifications} type={this.state.notifType} modalTitle={this.state.modalTitle} />
                        }
                    </div>
                </div>
            </div>
        );
    }
}
  
//export default AddAddress;

export default GoogleApiWrapper({
    apiKey: 'AIzaSyCTkUK9u3gNBcNnw4PDgbIfXCYDiixfd7U'
  })(AddAddress2);