import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loader from './Loader';
import ModalAlert from './ModalAlert';
import AppBottomMenu from './AppBottomMenu';
import AppHeader from './AppHeader';
import AppSidebarMenu from './AppSidebarMenu';
import Moment from 'react-moment';
import $ from "jquery";

class Profile extends Component {
   constructor(props) {
    	super(props);

      this.state = {
            LOADING: true,
            Notifications: "",
            modalType: 'ALERT', 
            notifType: 'error', 
            modalTitle: '',
            del_aid: "",
            customerid: localStorage.getItem('userData'),
            customerdet:  [],
      }

      this.getUserAddresses = this.getUserAddresses.bind(this);
   }


   getUserAddresses() {
      const posturl = global.config.bURL+"userDetails.php";
      const postData = {
         method: 'POST',
           headers: { 'Content-Type': 'application/json' },
           body: JSON.stringify({ mode: 'CUSTOMER', id: this.state.customerid })
      }

      fetch(posturl, postData)
         .then(res => res.json())
         .then(result => {
            // console.log('results obtained', result);

            let notify = '';
            if(result.err!=='' && result.err!==undefined)
                notify = result.err;

            this.setState({
               LOADING: false,
               customerdet: result.user
            })
            
            if(notify!=='')
                window.$('#ModalAlert').modal('show');
         })
         .catch(error => {
               this.setState({ Notifications: error.toString() });
               console.error('There was an error!', error);
           });
    }

    _confirm_delete = (addr_id) => {
      this.setState({del_aid:addr_id})
      if(addr_id != '')
         window.$("#DelAddrModal").modal("show");
    }

    handleDeleteAddr = (addr_id) => {
      const posturl = global.config.bURL+"customeraddaddress_api.php";
      const postData = {
         method: 'POST',
           headers: { 'Content-Type': 'application/json' },
           body: JSON.stringify({ mode: 'DELETE_ADDRESS', id: this.state.customerid, aid:addr_id })
      }

      fetch(posturl, postData)
         .then(res => res.json())
         .then(result => {
            // console.log('results obtained', result);

            let notify = '';
            let ntype = 'error';
            if(result.err!=='' && result.err!==undefined)
                notify = result.err;

             if(result.updated == 'Y')
             {
               notify = "Address deleted successfully";
               ntype =  'success';
             }

            this.setState({
               LOADING: false,
               Notifications: notify,
               notifType: ntype
            }, ()=> {
               if(notify!=='')
                   window.$('#ModalAlert').modal('show');

                this.getUserAddresses();
            })
            
         })
         .catch(error => {
               this.setState({ Notifications: error.toString() });
               console.error('There was an error!', error);
           }); 
    }


   componentDidMount() {
      this.getUserAddresses();
   }

    render() {
        if(!localStorage.getItem('userData')) {
            return (<Redirect to={'/logout'} />)
        }

         let AppHeaderClass = 'appHeader no-border';
         let AppHeaderLeft = '';
         let AppHeaderLeftLink = '/login';
         let AppHeaderTitle = 'Addresses';
         let AppHeaderIMAGE = '';
         let AppHeaderRight = 'showPROFILE';
         let AppHeaderRightLink = '';
         let AppHeaderRightPic = process.env.PUBLIC_URL+'/assets/images/unknown-b.jpg';
         let customerName = (this.state.customerdet)?this.state.customerdet.NAME:'Customer';

        return(
        <>
            {this.state.LOADING && 
                <Loader />
            }
            
            {AppHeaderTitle != '' &&
              <AppHeader 
                  AppHeaderClass={AppHeaderClass} 
                  AppHeaderLeft={AppHeaderLeft} 
                  AppHeaderLeftLink={AppHeaderLeftLink} 
                  AppHeaderTitle={AppHeaderTitle} 
                  AppHeaderRight={AppHeaderRight} 
                  AppHeaderRightLink={AppHeaderRightLink} 
                  AppHeaderRightPic={AppHeaderRightPic} 
                  customerName={customerName} 
              />
            }

            <div id="appCapsule" className="pt-1">
               <div className="section user-options mt-4 pr-0 pl-0">
                  <ul className="edit-addr user-edits address-option listview image-listview media mb-0 pb-3">
                     {this.state.customerdet && this.state.customerdet.ADDRESSES ?
                        this.state.customerdet.ADDRESSES.map((address, i)=>{
                           let address_str = address.ADDRESS

                           if(address.ADDRESS2!='' && address.ADDRESS2!=null)
                              address_str += ', '+address.ADDRESS2;

                           if(address.ADDRESS3!='' && address.ADDRESS3!=null)
                              address_str += ', '+address.ADDRESS3;

                           if(address.ROAD!='' && address.ROAD!=null)
                              address_str += '<br/>'+address.ROAD;

                           if(address.AREA!='' && address.AREA!=null)
                              address_str += ' - '+address.AREA;

                           if(address.LANDMARK!='' && address.LANDMARK!=null)
                              address_str += '<br/>Landmark: '+address.LANDMARK;

                           if(address.CITY!='' && address.CITY!=null)
                              address_str += '<br/>'+address.CITY;

                           if(address.STATE!='' && address.STATE!=null)
                              address_str += ' '+address.STATE;

                           if(address.PINCODE!='' && address.PINCODE!=null)
                              address_str += ' '+address.PINCODE;

                           return(
                              <li key={i}>
                                 <div className="item">
                                    <div className="icon-box">
                                       <ion-icon name="business" role="img" className="md hydrated" aria-label="image outline"></ion-icon>
                                    </div>
                                    <div className="in">
                                       <div>
                                          <div className="">
                                             <span className="ft-wt-500">{address.LABEL}</span>
                                             <p className="font-13 l-17 text-dgrey7d pb-05 mb-05 ft-wt-400" dangerouslySetInnerHTML={{__html: address_str }} />
                                             <div class="stateline address-btn">
                                                 <Link to={"/editaddress/"+address.CADDR_ID} className="btn btn-xsm square btn-outline-edit newshadowed shadowed mr-1 mb-1 text-upper">Edit</Link>
                                                 <button type="button" onClick={(e)=>this._confirm_delete(address.CADDR_ID)} className="btn btn-xsm square btn-outline-danger newshadowed shadowed mr-1 mb-1 text-upper">Delete</button>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 
                              </li>
                           )
                        })
                        :
                        <li className="set1 close-service title-cut-sub text-grey4 font-15 ft-wt-500 lt-spc05 pb-05">No addresses found</li>
                     }
                  </ul>

                  <div className="form-group basic pr-2 pl-2">
                     <div className="input-wrapper">
                         <div className="w-100 pt-3">
                           <Link to="/addaddress" className="btn w-100 rounded bt-bg-gold btn-block btn-lg mr-0 ml-0 text-upper font-14 lt-spc2 ft-wt-600 btn-ht60 box-shadowg">Add New</Link>
                       </div>
                     </div>
                  </div>
                  
               </div>

               {this.state.customerdet != '' &&
                  <AppSidebarMenu username={customerName} userpic={AppHeaderRightPic} />
               }
            </div>

            <AppBottomMenu activeClass="profile" />
            {this.state.Notifications!='' &&
               <ModalAlert modalType={this.state.modalType} Notifications={this.state.Notifications} type={this.state.notifType} modalTitle={this.state.modalTitle} />
            }

            <div className="modal fade dialogbox" id="DelAddrModal" data-backdrop="static" tabIndex="-1" role="dialog">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Delete Address</h5>
                  </div>
                  <div className="modal-body">You are about to delete this address, continue ?</div>
                  <div className="modal-footer">
                    <div className="btn-inline"> 
                        <button type="button" className="btn btn-text-secondary" data-dismiss="modal">CANCEL</button> 
                        <button type="button" onClick={(e)=>this.handleDeleteAddr(this.state.del_aid)} className="btn btn-text-primary" data-dismiss="modal">CONFIRM</button> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </>
        )
    }
}

export default Profile;