import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

class SearchBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchService: "",
        }

        this.handleServiceSearch = this.handleServiceSearch.bind(this);
    }

    handleServiceSearch(event) {
        let srchText = event.target.value;
        let isEnterKey = event.Key;
        console.log(isEnterKey);

        if(isEnterKey == 'Enter')
        {
            alert('Submitting form');
        }
        // console.log('searching for:', srchText);
        this.setState({
            searchService: srchText
        })
    }

    render() {
        return(
            <div className="section full mt-1 ">
               <div className="wide-block no-border bg-transparent pt-0 pb-2">
                   <form className="search-form" action="/searchresult" autoComplete="off">
                       <div className="form-group searchbox search-cover">
                        <input type="text" className="form-control btn-ht50 mr-05" name="search" value={this.state.searchService} onChange={this.handleServiceSearch} placeholder="Find a service" />
                           <button type="submit" className="btn bt-bg-gold btn-search">
                               <span className="spinner-border spinner-border-sm mr-05" id="submitLoading" style={{display: 'none'}} role="status" aria-hidden="true"></span>
                                <i className="">
                                    <ion-icon name="search-outline"></ion-icon>
                                </i>
                           </button>
                       </div>
                   </form>
               </div>
           </div>
        )
    }
}
export default SearchBar;