import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';

class Refund extends Component {
	constructor(props) {
		super(props);
		this.state = {
			LOADING:true
		}
	}

	componentDidMount() {
		this.setState({
			LOADING:false
		})
	}

	render() {
		let AppHeaderClass = 'appHeader no-border bg-coach-header text-light pt-2 pb-2';
		let AppHeaderLeft = 'showLINK';
		let AppHeaderLeftLink = '/profile';
		let AppHeaderTitle = <h5 className="text-upper lt-spc1 text-grey5b pt-05 pb-0 mb-0">Cancellation & Refund Policy</h5>;
		let AppHeaderRight = '';
		let AppHeaderRightLink = '';

		return(
			<>
			    {this.state.LOADING && 
			        <Loader />
			    }

			    <AppHeader 
                    AppHeaderClass={AppHeaderClass} 
                    AppHeaderLeft={AppHeaderLeft} 
                    AppHeaderLeftLink={AppHeaderLeftLink} 
                    AppHeaderTitle={AppHeaderTitle} 
                    AppHeaderRight={AppHeaderRight} 
                    AppHeaderRightLink={AppHeaderRightLink} 
                />

                <div id="appCapsule">
                	<div className="section register full mt-0 mb-2 basic-form">
                		<div className="wide-block no-border mt-1 pb-1 pt-0 pr-3 pl-3">
                			<p className="mt-10 font-14 ft-wt-400 lt-spc05 text-dgrey">CANCELLATION</p>
                			<ul className="marked-list">
                				<li>As a general rule you shall not be entitled to cancel your service once you have received confirmation of the same. If you cancel your appointment after it has been confirmed, Amanora shall have a right to charge you a cancellation fee of a minimum INR 100/- upto the service value, with a right to either not to refund the value or recover from your subsequent service, the complete/ deficit cancellation fee, as applicable, to compensate our staff and service providers. Amanora shall also have the right to charge you a cancellation fee for the appointments cancelled by Amanora for the reasons specified under clause 1(iii) of this cancellation and refunds policy. In case of cancellations for the reasons attributable to Amanora  or its Spa and Salon, Amanora  shall not charge you any cancellation fee.</li>
                				<li>However, in the unlikely event of service being unavailable, we will contact you on the phone number provided to us at the time of booking your appointment for the service and inform you of such unavailability. In such an event you will be entitled to cancel the entire service and shall be entitled to a refund in accordance with our refund policy.</li>
                				<li>We reserve the sole right to cancel your service in the following circumstance:<br/>
                					<ol className="marked-list">
                						<li>failure to contact you by phone or email at the time of confirming the service booking;</li>
                						<li>failure to serve you due to lack of information, direction or authorization from you or</li>
                						<li>unavailability of all the service booked by you at the time of booking or</li>
                						<li>unavailability of all the services booked by you at the time of booking</li>
                					</ol>
                				</li>
                			</ul>

                			<p className="mt-10 font-14 ft-wt-400 lt-spc05 text-dgrey">REFUND</p>
                			<ul className="marked-list">
                				<li>You shall be entitled to a refund only if you pre-pay for your service at the time of booking your appointment on the Platform and only in the event of any of the following circumstances:
                					<ol className="marked-list pt-1">
                						<li>us cancelling your appointment due to<br/>

                							<ol className="marked-list pt-1">
                								<li>service unavailable for the date scheduled</li>
                								<li>failure to contact you by phone or email at the time of confirming the appointment booking; or
                								</li>
                								<li>failure to contact you by phone or email at the time of confirming the appointment; or</li>
                							</ol>

                						</li>
                						<li>you cancelling the appointment at the time of confirmation.</li>
                					</ol>
                				</li>
                				<li>Our decision on refunds shall be at our sole discretion and shall be final and binding.</li>
                				<li>All refund amounts shall be credited to your account within 3-4 business days in accordance with the terms that may be stipulated by the bank which has issued the credit / debit card.</li>


                			</ul>

                			<p className="mt-10">In case of payment at the time of booking, you will not be required to pay for</p>
                			<ul className="marked-list">
                				<li>services that are not satisfactory or upto your expectations;</li>
                				<li>services not provided during your appointment.</li>
                			</ul>
                			<br/>
                		</div>
                	</div>
                </div>
			</>
		)
	}
}
export default Refund;