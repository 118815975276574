import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import {chatNewMessage, pushIDUpdate} from './Common';
import $ from "jquery";


const menuItems = [
        { 'title': 'Home', 'url': '/home', 'tag': 'home', 'icon':<img src={process.env.PUBLIC_URL+"/assets/images/home-icon.png"} alt="service icon"/>},
        { 'title': 'Services', 'url':'/category', 'tag':'category', 'icon':<img src={process.env.PUBLIC_URL+"/assets/images/service-icon.png"} alt="service icon"/>},
        // { 'title': 'Offers', 'url': '/offers', 'tag': 'offers', 'icon':<img src={process.env.PUBLIC_URL+"/assets/images/offers-icon.png"} alt="service icon"/>},
        { 'title': 'Bookings', 'url': '/appointments', 'tag': 'appointment', 'icon':<img src={process.env.PUBLIC_URL+"/assets/images/appointment-icon.png"} alt="service icon"/>},
        { 'title': 'Profile', 'url': '/profile', 'tag': 'profile', 'icon':<img src={process.env.PUBLIC_URL+"/assets/images/profile-icon.png"} alt="service icon"/>},
];

// <span class="badge badge-danger">5</span>
class AppBottomMenu extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            activeClass: props.activeClass,
            customerid: localStorage.getItem('userData'),
        }
    }

    render() {
        return (
            <div className="appBottomMenu footer-menu bg-footer pt-05 pb-05">
                {menuItems.length >0 &&
                    menuItems.map((menuitem, i)=>{
                        let activeClass = "";
                        let activeClass2 = "";
                        let url_str = "#";

                        if(menuitem.tag==this.state.activeClass) {
                            activeClass = 'active';
                            activeClass2 = 'glow';
                        }

                        url_str = menuitem.url;

                        return(
                            <Link to={url_str} className={"item " + activeClass} key={i}>
                                <div className="col">
                                    <div className={activeClass2}>
                                        {menuitem.icon}
                                    </div>
                                    <strong>{menuitem.title}</strong>
                                </div>
                            </Link>
                        )

                        {/*if(menuitem.tag=='home')
                        {
                            return(
                                <Link to={url_str} className={"item " + activeClass} key={i}>
                                    <div className="col">
                                        <div className="action-button">
                                            {menuitem.icon}
                                        </div>
                                    </div>
                                </Link>
                            )
                        }
                        else
                        {
                            
                        }*/}
                    })
                }
            </div>
        );
    }
}

export default AppBottomMenu