import React from 'react';
import ReactDOM from 'react-dom';
import { Link, Redirect } from 'react-router-dom';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const HomeSubCatSlider = (props) => {
	// console.log(props);
    let Items = props.serviceSubCat && props.serviceSubCat.map((subcat, i) => {
            let s_url = '/services/'+subcat.ID;
            let image_str = (subcat.IMAGE)?subcat.IMAGE:process.env.PUBLIC_URL+"/assets/images/hair-img.jpg";

            if(subcat.TYPE_ID == props.type_id)
            {
                return (
                        <div className="item" key={i} style={{width:'300px'}}>
                            <Link className="service-slider" to={s_url}>
                              <div className="card mb-0">
                                 <div className="slider-cover">
                                    <img src={image_str} className="card-img-top imaged w-100" alt={subcat.NAME} />
                                 </div>
                                 <div className="card-body pt-07 pb-07 pr-06 pl-06">
                                    <p className="card-text text-upper lt-spc05 font-size-14 ft-wt-700 mb-0">{subcat.NAME}</p>
                                 </div>
                              </div>
                            </Link>
                        </div>
                );
            }
        });

    const options = {
        items:1,
        autoWidth: true,
        nav: false,
        loop: false,
        dots: false,
        stagePadding: 30,
        margin: 15,
        mergeFit: true,
        stageClass: 'exclusive-slider',
        stageOuterClass: 'exclusive-slider-outer',
    };

    return (
        <OwlCarousel className="owl-theme" {...options}>
            {Items}
        </OwlCarousel>
    );
}

export default HomeSubCatSlider;