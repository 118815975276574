import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import AppBottomMenu from './AppBottomMenu';
import BookingStrip2 from './BookingStrip2';
import Moment from 'react-moment';
import $ from "jquery";

class Offers extends Component {
    constructor(props) {
    	super(props);

        this.state = {
            LOADING: true,
            Notifications: false,
            modalType: 'ALERT', 
            notifType: 'error', 
            modalTitle: '',
            customerid: localStorage.getItem('userData'),
            offersdet: []
        }

        this.getOffers = this.getOffers.bind(this);
    }

    getOffers() {
    	const posturl = global.config.bURL+"offers.php";
    	const postData = {
    		method: 'POST',
    	    headers: { 'Content-Type': 'application/json' },
    	    body: JSON.stringify({mode: 'OFFERS', customerid:this.state.customerid})
    	}
    	
    	fetch(posturl, postData)
    		.then(res => res.json())
    		.then(result => {
    			// console.log('results obtained', result);
    	
    			let notify = '';
    			if(result.err!=='' && result.err!==undefined)
    			    notify = result.err;
    	
    			this.setState({ 
    				LOADING: false,
    				offersdet: result.offers,
    			});
    	
    			if(notify!=='') {
    	    		window.$('#ModalAlert').modal('show');
    			}
    		})
    		.catch(error => {
    	        this.setState({ Notifications: error.toString() });
    	        console.error('There was an error!', error);
    	    });
    }

    componentDidMount() {
        this.getOffers();
    }

    render() {
    	/*if(!localStorage.getItem('userData')) {
    	    return (<Redirect to={'/logout'} />)
    	}*/

    	let AppHeaderClass = 'appHeader no-border bg-coach-header text-light pt-2 pb-2';
    	let AppHeaderLeft = 'showLINK';
    	let AppHeaderLeftLink = '/home';
    	let AppHeaderTitle = <h5 className="text-upper lt-spc1 text-grey5b mb-0 pt-05 pb-05">Offers</h5>
    	let AppHeaderRight = '';
    	let AppHeaderRightLink = '';

    	return(
    		<>
    			{this.state.LOADING && 
    			    <Loader />
    			}

    			<AppHeader 
    			    AppHeaderClass={AppHeaderClass} 
    			    AppHeaderLeft={AppHeaderLeft} 
    			    AppHeaderLeftLink={AppHeaderLeftLink} 
    			    AppHeaderTitle={AppHeaderTitle} 
    			    AppHeaderRight={AppHeaderRight} 
    			    AppHeaderRightLink={AppHeaderRightLink} 
    			/>

    			<div id="appCapsule">
    			   <div className="section offer-section service-slider service-block pt-1 pb-7">
    			      <div className="row">
                        {this.state.offersdet && this.state.offersdet.length > 0 &&
                            this.state.offersdet.map((offers, i) => {
                                let image_str = (offers.PIC)?offers.PIC:'assets/images/hair-img.jpg';

                                return(
                                    <div className="col-12" key={'offer-'+i}>
                                       <Link to={'/offerdetails/'+offers.ID}>
                                          <div className="item">
                                             <div className="card mb-3">
                                                <div className="slider-cover">
                                                   <img src={image_str} className="card-img-top imaged w-100" alt="image" />
                                                </div>
                                                <div className="card-body pt-2 pb-3 pr-2 pl-2">
                                                   <p className="card-text text-upper font-16 ft-wt-700 mb-0">{offers.NAME}</p>
                                                   <p className="card-text pt-05 text-grey4 font-14 ft-wt-600 mb-0"><span className="text-lightred">{offers.TYPE}</span></p>

                                                </div>
                                             </div>
                                          </div>
                                       </Link>
                                    </div>
                                )
                            })
                        }
    			      </div>
    			   </div>
    			</div>

                <AppBottomMenu activeClass="offers" />
                <BookingStrip2 />
    		</>
    	)
    }
}

export default Offers;