import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import { CompareDate, formatDate, isMonday, GetnextDate } from './Common';
import AppBottomMenu from './AppBottomMenu';
import Moment from 'react-moment';
import $ from "jquery";

class BookingCalendar extends Component {
    constructor(props) {
    	super(props);

    	this.state = {
    		LOADING: true,
    		Notifications: false,
    		modalType: 'ALERT', 
    		notifType: 'error', 
    		modalTitle: '',
    		customerid: localStorage.getItem('userData'),
    		TODAY: isMonday(new Date())?formatDate(GetnextDate()):formatDate(new Date()),
    		bookingDet: [],
    		monthName: "",
    		startMonth: "",
    		nextMonth: "",
    		prevMonth: "",
    		year: "",
    		monthWeeks: [],
    		weeks: [],
    		days: [],
    		selected_date: (props.selected_date)?props.selected_date:isMonday(new Date())?formatDate(GetnextDate()):formatDate(new Date()),
    		selected_timeslot: (props.selected_timeslot)?props.selected_timeslot:'',
    		selected_timeslot2: localStorage.getItem('cartTime2'),
    		timeslots_arr: [],
    		redirectBooking2: 'N'

    	}

    	this.handleTimeSlot = this.handleTimeSlot.bind(this);
    	localStorage.setItem('cartDate', this.state.selected_date);
    	localStorage.setItem('cartTime', this.state.selected_timeslot);
	}

	handledtSelected = (clicked_id, date_str) => {

		var ALLOW_SELECTION = 'Y';
		if(isMonday(new Date(date_str)))
			ALLOW_SELECTION = 'N';

		if(ALLOW_SELECTION==='Y')
		{
			// // console.log('hello world ...', this);
			window.$('.ui-state-default').removeClass('ui-state-highlight');
			window.$('#'+clicked_id).addClass('ui-state-highlight');		
			localStorage.setItem('cartDate',date_str);
			localStorage.removeItem('cartTime');
			localStorage.removeItem('cartTime2');
			this.setState({
				LOADING: true,
				selected_date: date_str,
				selected_timeslot2: "",
			})

			const posturl = global.config.bURL+"bookingCalendar.php";
			const postData = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({mode: 'CALENDAR2', selected_date: date_str })
			}
			
			fetch(posturl, postData)
				.then(res => res.json())
				.then(result => {
					// console.log('results obtained', result);
			
					let notify = '';
					if(result.err!=='' && result.err!==undefined)
						notify = result.err;
			
					this.setState({ 
						LOADING: false,
						monthWeeks: result.MONTH_WEEKS,
						monthName: result.MONTH_NAME,
						startMonth: this.state.startMonth,
						nextMonth: result.NEXT,
						prevMonth: result.PREV,
						year: result.YEAR,
						weeks: result.WEEK,
						days: result.CALENDAR,
						timeslots_arr: result.timeslots,
						selected_date: (this.state.selected_date && this.state.selected_date!='')?this.state.selected_date:this.state.TODAY,
						selected_timeslot: (this.state.selected_timeslot && this.state.selected_timeslot!='')?this.state.selected_timeslot:'',
					});
			
					if(notify!=='') {
						window.$('#CalendarAlert').modal('show');
					}
				})
				.catch(error => {
					this.setState({ Notifications: error.toString() });
					console.error('There was an error!', error);
				});
		}
		else
		{
			this.setState({ Notifications: 'We are off on Sunday.' });
			window.$('#CalendarAlert').modal('show');
		}
	}

	handleTimeSlot(event) {
		let time_slot = event.target.value;
		let slot_text = $("#customRadioLabel_"+time_slot).html();

		localStorage.setItem('cartTime', time_slot);
		localStorage.setItem('cartTime2', slot_text);
		this.setState({
			selected_timeslot2: slot_text,
		})
	}

	getCalendarDates = (start_month) => {

		let selected_date = this.state.selected_date;

		const posturl = global.config.bURL+"bookingCalendar.php";
		const postData = {
			method: 'POST',
		    headers: { 'Content-Type': 'application/json' },
		    body: JSON.stringify({mode: 'CALENDAR', start: start_month, selected_date: selected_date })
		}
		
		fetch(posturl, postData)
			.then(res => res.json())
			.then(result => {
				// console.log('results obtained', result);
		
				let notify = '';
				if(result.err!=='' && result.err!==undefined)
				    notify = result.err;
		
				this.setState({ 
					LOADING: false,
					monthWeeks: result.MONTH_WEEKS,
					monthName: result.MONTH_NAME,
					startMonth: this.state.startMonth,
					nextMonth: result.NEXT,
					prevMonth: result.PREV,
					year: result.YEAR,
					weeks: result.WEEK,
					days: result.CALENDAR,
					timeslots_arr: result.timeslots,
					selected_date: (this.state.selected_date && this.state.selected_date!='')?this.state.selected_date:this.state.TODAY,
					selected_timeslot: (this.state.selected_timeslot && this.state.selected_timeslot!='')?this.state.selected_timeslot:'',
				});
		
				if(notify!=='') {
		    		window.$('#CalendarAlert').modal('show');
				}
			})
			.catch(error => {
		        this.setState({ Notifications: error.toString() });
		        console.error('There was an error!', error);
		    });
	}

	handleBooking = () => {
		let err = 0;
		let notify = '';
		let date = localStorage.getItem('cartDate');
		let timeslot = localStorage.getItem('cartTime');

		if(date=='' || date==undefined) {
			err++;
			notify = "Select a preferred date";
		}
		else if(timeslot=='' || timeslot==undefined) {
			err++;
			notify = "Select a preferred time";
		}

		if(err!=0) {
			this.setState({
				Notifications: notify
			}, () => {
				window.$('#CalendarAlert').modal('show');
			})
		}

		// console.log('handle booking:', err);

		if(err==0)
		{
			// proceed next;
			// console.log('proceed booking:');
			this.setState({
				redirectBooking2: 'Y'
			});
			let mount = $("div").hasClass("modal-backdrop");
			if (mount == true) {
			    $('body').removeClass('modal-open');
			    $(".modal-backdrop").remove();
			}
		}
	}

	componentWillMount() {
	    let mount = $("div").hasClass("modal-backdrop");
	    if (mount == true) {
	        $('body').removeClass('modal-open');
	        $(".modal-backdrop").remove();
	    }
	}

	componentDidMount() {
		this.getCalendarDates(this.state.startMonth);
	}

	render() {

		if(this.state.redirectBooking2 == 'Y') {
			return (<Redirect to={'/booking2'} />);
		}

		return (
		<>
		<div className="calendar-modal modal fade modalbox" id="ModalCalendar" tabIndex="-1" role="dialog">
		   <div className="modal-dialog" role="document">
		      <div className="modal-content">
		         <div className="modal-header pageTitle">
		            <h5 className="text-upper w-100 lt-spc1 text-center text-grey5b mb-0 pt-05 pb-05">Date & Time</h5>
		            <Link to="#" data-dismiss="modal" className="font-16 text-grey7 ft-wt-700">x</Link>
		         </div>
		         <div className="modal-body pt-1">
		            <div id="ui-datepicker-div" className="ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all">
		               <div className="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-all">
		                  <button type="button" onClick={()=>this.getCalendarDates(this.state.prevMonth)} data-prev={this.state.prevMonth} className="ui-datepicker-prev ui-corner-all ui-state-disabled" title="Prev">
		                     <span className="ui-icon ui-icon-circle-triangle-w">
		                        <ion-icon name="chevron-back-outline">  
		                        </ion-icon>
		                     </span>
		                  </button>
		                  <button type="button" onClick={()=>this.getCalendarDates(this.state.nextMonth)} data-next={this.state.nextMonth} className="ui-datepicker-next ui-corner-all" data-handler="next" data-event="click" title="Next">
		                     <span className="ui-icon ui-icon-circle-triangle-e">
		                        <ion-icon name="chevron-forward-outline"></ion-icon>
		                     </span>
		                  </button>
		                  <div className="ui-datepicker-title"><span className="ui-datepicker-month">{this.state.monthName}&nbsp;{this.state.year}</span></div>
		               </div>
		               <table className="ui-datepicker-calendar">
		                  <thead>
		                     <tr>
		                     	{this.state.weeks && this.state.weeks.length!=0 &&
		                     		this.state.weeks.map((day, i) => {
		                     			let day_name =  day.substring(0, 3);
		                     			return(
		                     				<th key={i} scope="col" className="ui-datepicker-week-end"><span title={day}>{day_name}</span></th>			
		                     			)
		                     		})
		                     	}
		                     </tr>
		                  </thead>
		                  <tbody>
		                  	{this.state.monthWeeks && this.state.monthWeeks.length != 0 &&
		                  		this.state.monthWeeks.map((weekid, i) => {	
		                  			let week = weekid.WEEK;

		                  			return(
		                  				<tr key={i}>
		                  					{this.state.days && this.state.days.length > 0 &&
		                  					    this.state.days.map((cal_day, i)=>{
		                  					    	if(cal_day.WEEK==week)
                                                    {
                                                    	let dt_id = 'date-'+i;
                                                    	let dtselected_class = "ui-state-default";
                                                    	if(this.state.selected_date == cal_day.DATE2)
                                                    	{
                                                    		dtselected_class = "ui-state-default ui-state-highlight";
                                                    	}

                                                    	if(cal_day.DATE2 < this.state.TODAY) {
                                                    		dtselected_class = "ui-state-default old-date";
                                                    		return(
                                                    			<td key={i} className=""><span id={dt_id} data-date={cal_day.DATE2} className={dtselected_class}>{cal_day.DATE}</span></td>
                                                    		)	
                                                    	}
                                                    	else {	                                                    	
	                                                    	return(
	                                                    		<td key={i} onClick={()=>this.handledtSelected(dt_id, cal_day.DATE2)} className=""><span id={dt_id} data-date={cal_day.DATE2} className={dtselected_class}>{cal_day.DATE}</span></td>
	                                                    	)
	                                                    }
                                                    }
		                  					    })
		                  					}
		                  				</tr>
		                  			)
		                  		})
		                  	}
		                  </tbody>
		               </table>
		            </div>
		            <div className="section full mt-2 time-select pb-1">
		               <div className="wide-block pt-2 pb-2 pr-0 pl-0 no-border">
		               		{this.state.timeslots_arr && this.state.timeslots_arr.length!=0 &&
		               			this.state.timeslots_arr.map((timestr, i) => {
		               				let checked = false;
		               				let isDisabled = false;

		               				if(this.state.selected_date == this.state.TODAY) {
		               					// if selected today, disable passed time;
		               					// isDisabled = true;
		               				}

		               				if(this.state.selected_timeslot == timestr.id) {
		               					checked = true;
		               				}

		               				return(
		               					<div className="custom-control custom-radio mb-3 " key={i} >
		               					   <input type="radio" readOnly={isDisabled} name="rdtimeslot" id={'customRadio_'+timestr.id} className="custom-control-input" defaultValue={timestr.id} defaultChecked={checked} onClick={(isDisabled === false)?(e)=>this.handleTimeSlot(e):undefined} />
		               					   <label className="time-radio custom-control-label" id={'customRadioLabel_'+timestr.id} htmlFor={'customRadio_'+timestr.id}>{timestr.slot}</label>
		               					</div>				
		               				)
		               			})
		               		}
		                  <div className="clear"></div>
		               </div>
		            </div>
		         </div>
		         <div className="section double mt-1 mb-5">
	               <div className="form-button-group bg-back">
	                 <div className="w-50 text-left">
	                 	{this.state.selected_timeslot2 && this.state.selected_timeslot2!=0 &&
				         	<p className="mb-0 text-upper ft-wt-500 text-black font-12">{this.state.selected_timeslot2}<br/>
				         		<span className="ml-0 ft-wt-600 font-15 text-gold"><Moment date={this.state.selected_date} format="DD MMM YYYY"/></span>
				         	</p>
	                 	}
			         </div>
	                 <div className="w-50 text-right">
	                   <button type="button" onClick={()=>this.handleBooking()} className="btn btn-checkout btn-coach-pink text-upper rounded btn-block text-gold btn-mid lt-spc05">Next</button>
	 				 </div>	             
	               </div>
		         </div>
		      </div>
		   </div>
		</div>
		<div className="modal fade dialogbox overlay-back" id="CalendarAlert" data-backdrop="static" tabIndex="-1" role="dialog">
		    <div className="modal-dialog" role="document">
		        <div className="modal-content">
		            {this.state.notifType=='error' &&
		                <div className="modal-icon text-danger mb-2">
		                    <ion-icon name="close-circle"></ion-icon>
		                </div>
		            }

		            {this.state.notifType=='success' &&
		                <div className="modal-icon text-success mb-2">
		                    <ion-icon name="checkmark-circle"></ion-icon>
		                </div>   
		            }

		            <div className="modal-body"> 
		                <p className="pb-0 mb-0" dangerouslySetInnerHTML={{ __html: this.state.Notifications}} />
		            </div>
		            <div className="modal-footer">
		                <div className="btn-inline"> 
		                    <button type="button" className="btn" data-dismiss="modal">OK</button>
		                </div>
		            </div>
		        </div>
		    </div>
		</div>
		</>
		)
	}
}

export default BookingCalendar;