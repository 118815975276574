import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import AppBottomMenu from './AppBottomMenu';
import ModalAlert from './ModalAlert';
import Moment from 'react-moment';
import ReactStars from "react-rating-stars-component";
import $ from "jquery";

class Reorder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            LOADING: true,
            Notifications: "",
            modalType: 'ALERT', 
            notifType: 'error', 
            modalTitle: '',
            rate_stars: 2,
            rate_feedback: "",
            customerid: localStorage.getItem('userData'),
            feedback_det: [],
            booking_id: props.match.params.bid,
            UPDATED: "N",
            status: "",
            CART_REDIRECT: 'N'
        }

        this.getBookingDetails = this.getBookingDetails.bind(this);
    }

    getBookingDetails() {
        const posturl = global.config.bURL+"getBooking.php";
        const postData = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({mode: 'VIEW', customerid:this.state.customerid, bookingcode:this.state.booking_id })
        }
        
        fetch(posturl, postData)
            .then(res => res.json())
            .then(result => {
                console.log('results obtained', result);

                let  mainCatId = (localStorage.getItem('maincatid2'))?localStorage.getItem('maincatid2'):1;

                let BOOKINGS = result.BOOKINGS;
                if(BOOKINGS.length>0)
                {
                    BOOKINGS.map((booking, i) => {
                        let tid = booking.TYPE_ID;

                        if(booking.DAT.length > 0)
                        {
                            booking.DAT.map((booking_dat, i)=>{

                                let pax = booking_dat.QTY;
                                let services_arr = (localStorage.getItem('cartData'))?JSON.parse(localStorage.getItem('cartData')):[];
                                let sid = booking_dat.S_VARID;
                                
                                let current_click = {id: sid, price: "", pax:pax, tid:tid};
                                localStorage.setItem('current_click', JSON.stringify(current_click));
                                if(tid == mainCatId || services_arr=='')
                                {
                                    localStorage.setItem('maincatid2', tid);
                                    services_arr.push(current_click);
                
                                    localStorage.setItem('cartData', JSON.stringify(services_arr));
                                }
                            });
                        }
                    });                    
                }

                this.setState({ CART_REDIRECT: 'Y' });
                
            })
            .catch(error => {
                this.setState({ Notifications: error.toString() });
                console.error('There was an error!', error);
            });
    }

    componentDidMount() {
        this.getBookingDetails();
    }

    render() {
        if(!localStorage.getItem('userData')) {
            return (<Redirect to={'/logout'} />)
        }

        if(this.state.CART_REDIRECT==='Y')
        {
            return (<Redirect to={'/booking'} />)
        }


        return(
            <>
                {this.state.LOADING && 
                    <Loader />
                }

                <div id="appCapsule">
                    <div className="section register full mt-0 mb-2 basic-form">
                        <div className="wide-block no-border mt-1 pb-1 pt-2 pr-3 pl-3">
                            <form id="frmUpdate" ref={el =>(this.form = el)} onSubmit={this.handleSubmit} className="star-feedback needs-validation" noValidate autoComplete="off">
                                <input type="hidden" value="FEEDBACK" className="form-control" name="mode" id="mode"  />
                                <input type="hidden" value={localStorage.getItem('userData')} className="form-control" name="customerid" id="customerid"  />
                                <input type="hidden" value={this.state.booking_id} className="form-control" name="bookingid" id="bookingid"  />
                                <input type="hidden" defaultValue={localStorage.getItem('WEBPUSHR_ID')} className="form-control" name="push_id" id="push_id"  />
                                <input type="hidden" defaultValue={this.state.rate_stars} className="form-control" name="rating" id="rating"  />

                                <div className="listview image-listview no-line no-space flush mr-2 ml-2 pb-4">
                                    {this.state.status!='' &&
                                        <ReactStars
                                            classNames=""
                                            count={5}
                                            value={this.state.rate_stars}
                                            edit={(this.state.status == 'N')?true:false}
                                            onChange={this._changeStar}
                                            size={50}
                                            isHalf={false}
                                            emptyIcon={<i className="far fa-star"></i>}
                                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                                            fullIcon={<i className="fa fa-star"></i>}
                                            activeColor="#ebae28"
                                        />
                                    }
                                    <div className="form-group basic">
                                        <div className="input-wrapper guest-detail float-label pt-3">
                                            <label className="eform-label" htmlFor="txtname">Your comment</label>
                                            <textarea id="feedback" readOnly={(this.state.status == 'N')?false:true} name="feedback" rows="1" class="form-control" defaultValue={this.state.rate_feedback}></textarea>
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>

                                    {this.state.status == 'N' &&
                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <div className="w-100 pt-3">
                                                  <button type="submit" className="btn w-100 rounded bt-bg-gold btn-block btn-lg mr-0 ml-0 text-upper font-14 lt-spc2 ft-wt-600 btn-ht60 box-shadowg">
                                                    <span className="spinner-border spinner-border-sm mr-05" id="submitLoading" style={{ display: 'none' }} role="status" aria-hidden="true"></span>
                                                    Submit
                                                  </button>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                          </form>
                      </div>
                  </div>

                  {this.state.Notifications != '' &&
                    <ModalAlert modalType={this.state.modalType} Notifications={this.state.Notifications} type={this.state.notifType} modalTitle={this.state.modalTitle} />
                  }
                </div>
            </>
        )
    }
}

export default Reorder;