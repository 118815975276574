import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';

class BookingStrip2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
    	serviceCat: [],
    	userName: props.username,
    	userPic: props.userpic
    }

    this.getMainCat = this.getMainCat.bind(this);
  }

  getMainCat() {
  	const posturl = global.config.bURL+"serviceCategory.php";
  	const postData = {
  		method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ mode: 'SERVICE_CATEGORY', type: "parent", cond: "" })
  	}

  	fetch(posturl, postData)
  		.then(res => res.json())
  		.then(result => {
  			// console.log('results obtained', result);

  			let notify = '';
  			if(result.err!=='' && result.err!==undefined)
  			    notify = result.err;

  			this.setState({
          serviceCat: result.category
  			})
  			
  			if(notify!=='')
  			    window.$('#ModalAlert').modal('show');
  		})
  		.catch(error => {
            this.setState({ Notifications: error.toString() });
            console.error('There was an error!', error);
        });
  }

  componentDidMount() {
  	this.getMainCat();
  }

  render() {
  	return(
		<div className="modal fade panelbox panelbox-left" id="sidebarPanel" tabIndex="-1" role="dialog">
		   <div className="modal-dialog" role="document">
		       <div className="modal-content">
		           <div className="modal-body p-0">

		               {/*<!-- profile box -->*/}
		               <div className="profileBox">
		                   <div className="image-wrapper">
		                       <img src={this.state.userPic} alt="image" className="imaged rounded" />
		                   </div>
		                   <div className="in">
		                      <strong>{this.state.userName}</strong>
		                   </div>
		                   <Link to="#" className="close-sidebar-button" data-dismiss="modal">
		                       <ion-icon name="close"></ion-icon>
		                   </Link>
		               </div>
		               {/*<!-- * profile box -->*/}


		               <ul className="listview flush transparent no-line image-listview mt-2">
	    			         	{this.state.serviceCat && this.state.serviceCat.length !==0 &&
	    			         		this.state.serviceCat.map((category, i) => {
	    			         			let c_url = '/category/'+category.ID+'/1';
	    			         			return(
	    			         				<li key={i}>
	    			         					<Link to={c_url} className="item">
	    			         						<div className="in">{category.NAME}</div>
	    			         					</Link>
	    			         				</li>
		    			         		)
	    			         		})
	    			         	}
											<li>
												<Link to="/contactus" className="item">
													<div className="in">Contact Us</div>
												</Link>
											</li>
		               </ul>

		           </div>

		           <div className="sidebar-buttons">

		               <a href="https://www.facebook.com/AmanoraTheBeautyLounge/" target="_blank" className="button">
		                   <ion-icon name="logo-facebook"></ion-icon>
		               </a>
		               <a href="https://www.instagram.com/amanorathebeautylounge/" target="_blank" className="button">
		                   <ion-icon name="logo-instagram"></ion-icon>
		               </a>
		               <a href="https://wa.me/+91 7438888777" target="_blank" className="button">
		                   <ion-icon name="logo-whatsapp"></ion-icon>
		               </a>
		               <a href="https://www.google.com/maps/place/Amanora+The+Beauty+Lounge/@15.5399741,73.8197612,17z/data=!3m1!4b1!4m5!3m4!1s0x3bbfc1197a75894f:0x3f5ed34063af479f!8m2!3d15.5399689!4d73.8219499" target="_blank" className="button">
		                  <ion-icon name="location-outline"></ion-icon>
		               </a>
		           </div>
		       </div>
		   </div>
		</div>
  	)
  }
}

export default BookingStrip2;