import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loader from './Loader';
import AppHeader from './AppHeader';
import $ from "jquery";
import ModalAlert from './ModalAlert';

class ForgotPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			LOADING: true, 
			Notifications: false,
			modalType:'ALERT', 
			notifType:'error',
			modalTitle:'',
		};
	}

	handleSubmit = (e) => {
		e.preventDefault();

		let err = 0;
		if ($('#frmReset')[0].checkValidity() === false) {
			e.preventDefault();
			e.stopPropagation();
			err++;
		}
		$('#frmReset')[0].classList.add('was-validated');

		if(err===0)
		{
			$('#submitLoading').css('display','block');

			const method = "POST";
			const body = new FormData(this.form);
          	fetch(global.config.bURL+'forgotPassword.php', { method, body })
          	.then(function (response) {
              return response.json();
          	})
          	.then(result => {
              let notify = '';
              let notifyType = "success";
              if(result.err!=='' && result.err!==undefined) {
              	notify = result.err;
              	notifyType = "error";
              }

              if(result.linkSent=='Y') {
              	notify = "Email has been sent to your email id";
              }
              else if(result.linkSent=='N') {
              	notify = "Email could not be sent, server error";
              	notifyType = "error";
              }

              this.setState({ 
              	notifType:notifyType,
              	Notifications: notify
              });
              $('#submitLoading').css('display','none');
              if(notify!=='')
              	window.$('#ModalAlert').modal('show');
          });
      }
  	}

	componentDidMount() {
		this.setState({
			LOADING: false
		})
	}

	render() {
		let AppHeaderClass = 'appHeader no-border bg-coach-header text-light pt-2 pb-2';
		let AppHeaderLeft = 'showLINK';
		let AppHeaderLeftLink = '/login';
		let AppHeaderTitle = <h5 className="text-upper lt-spc1 text-grey5b pt-05 pb-05">Forgot Password</h5>
		let AppHeaderRight = '';
		let AppHeaderRightLink = '';

		return(
			<>
				{this.state.LOADING && 
				    <Loader />
				}

				<AppHeader 
				    AppHeaderClass={AppHeaderClass} 
				    AppHeaderLeft={AppHeaderLeft} 
				    AppHeaderLeftLink={AppHeaderLeftLink} 
				    AppHeaderTitle={AppHeaderTitle} 
				    AppHeaderRight={AppHeaderRight} 
				    AppHeaderRightLink={AppHeaderRightLink} 
				/>

				<div id="appCapsule">
					<div className="section register full mt-0 mb-2 basic-form">
						<div className="wide-block no-border mt-1 pb-1 pt-2 pr-3 pl-3">
							<div className="mb-5 pb-2">
								<div className="image-cover">
									<div className="inside-cover">
										<img src={process.env.PUBLIC_URL+"/assets/images/forgot-password.png"} alt="alt" className="imaged" />
									</div>
								</div>
								<p className="text-center font-18 ft-wt-500 text-black0 pr-4 pl-4 l-30">Do not worry! <br/>we will help you to reset a new password</p>
							</div>

							<form id="frmReset" ref={el =>(this.form = el)} onSubmit={this.handleSubmit} className="needs-validation" noValidate autoComplete="off">
								<input type="hidden" value="FORGOT_PASSWORD" className="form-control" name="mode" id="mode"  />
								<input type="hidden" defaultValue={localStorage.getItem('WEBPUSHR_ID')} className="form-control" name="push_id" id="push_id"  />

								<div className="listview image-listview no-line no-space flush mr-2 ml-2 pb-4">
									<div className="form-group basic">
										<div className="input-wrapper guest-detail float-label">
											<input type="email" className="form-control" id="txtemail" name="txtemail" required="required" />
											<label className="floating-label" htmlFor="name">Registered email</label>
											<i className="clear-input">
												<ion-icon name="close-circle"></ion-icon>
											</i>
											<div className="invalid-feedback">Enter registered email</div>
										</div>
									</div>

									<div className="form-group basic">
										<div className="input-wrapper">
											<div className="w-100 pt-2">
												<button type="submit" className="btn w-100 rounded bt-bg-gold btn-block btn-lg mr-0 ml-0 text-upper font-14 lt-spc2 ft-wt-700 btn-ht60 box-shadowg">
												Send
												<span className="spinner-border spinner-border-sm mr-05" id="submitLoading" style={{ display: 'none' }} role="status" aria-hidden="true"></span>
												</button>
											</div>
										</div>
									</div>
								</div>
							</form>

						</div>
					</div>
				</div>

				<ModalAlert modalType={this.state.modalType} Notifications={this.state.Notifications} type={this.state.notifType} modalTitle={this.state.modalTitle} />
			</>
			)
	}
}

export default ForgotPassword;