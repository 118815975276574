import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import AppBottomMenu from './AppBottomMenu';
import Moment from 'react-moment';
import $ from "jquery";

class Notifications extends Component {
    constructor(props) {
    	super(props);

        this.state = {
            LOADING: true,
            Notifications: false,
            modalType: 'ALERT', 
            notifType: 'error', 
            modalTitle: '',
            customerid: localStorage.getItem('userData'),
            notification_det:  [],
        }

        this.getUserNotifications = this.getUserNotifications.bind(this);
    }

    getUserNotifications() {
        const posturl = global.config.bURL+"customerNotifications.php";
        const postData = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({mode: "GET_NOTIFICATIONS", customerid: this.state.customerid})
        }
        
        fetch(posturl, postData)
            .then(res => res.json())
            .then(result => {
                // console.log('results obtained', result);
        
                let notify = '';
                if(result.err!=='' && result.err!==undefined)
                    notify = result.err;
        
                this.setState({ 
                    LOADING: false,
                    notification_det: result.NOTIFICATIONS
                });
        
                if(notify!=='') {
                    window.$('#ModalAlert').modal('show');
                }
            })
            .catch(error => {
                this.setState({ Notifications: error.toString() });
                console.error('There was an error!', error);
            });
    }

    componentDidMount() {
        this.getUserNotifications();   
    }

    render() {
        if(!localStorage.getItem('userData')) {
            return (<Redirect to={'/logout'} />)
        }

        let AppHeaderClass = 'appHeader no-border bg-coach-header text-light pt-2 pb-2';
        let AppHeaderLeft = 'showLINK';
        let AppHeaderLeftLink = '/profile';
        let AppHeaderTitle = <h5 className="text-upper lt-spc1 text-grey5b mb-0 pt-05 pb-05">Notifications</h5>
        let AppHeaderRight = '';
        let AppHeaderRightLink = '';

        return(
            <>
                {this.state.LOADING && 
                    <Loader />
                }

                <AppHeader 
                    AppHeaderClass={AppHeaderClass} 
                    AppHeaderLeft={AppHeaderLeft} 
                    AppHeaderLeftLink={AppHeaderLeftLink} 
                    AppHeaderTitle={AppHeaderTitle} 
                    AppHeaderRight={AppHeaderRight} 
                    AppHeaderRightLink={AppHeaderRightLink} 
                />

                <div id="appCapsule">
                  <div className="message-divider">
                    Friday, Sep 20, 10:40 AM
                  </div>
                  <div className="message-item">
                    <img src="assets/img/sample/avatar/avatar1.jpg" alt="avatar" className="avatar" />
                    <div className="content">
                      <div className="bubble">
                        Notification test 1
                      </div>
                      <div className="footer">8:40 AM</div>
                    </div>
                  </div>
                </div>

                <AppBottomMenu activeClass="profile" />
            </>
        )
    }
}

export default Notifications;