import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import ModalAlert from './ModalAlert';
import AppBottomMenu from './AppBottomMenu';
import BookingStrip2 from './BookingStrip2';
import $ from "jquery";

class Slider extends Component {
    constructor(props) {
      super(props);

      this.state = {
        LOADING: true,
        Notifications: false,
        modalType: 'ALERT', 
        notifType: 'error', 
        modalTitle: '',
        customerid: localStorage.getItem('userData'),
        sliderdet: [],
        sliderid: props.match.params.sid,
      }

      this.getSlider = this.getSlider.bind(this);
    }

    getSlider() {
      const posturl = global.config.bURL+"slider.php";
      const postData = {
        method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({mode: 'SLIDER', customerid:this.state.customerid, sliderid: this.state.sliderid})
      }
      
      fetch(posturl, postData)
        .then(res => res.json())
        .then(result => {
          // console.log('results obtained', result);
      
          let notify = '';
          if(result.err!=='' && result.err!==undefined)
              notify = result.err;
      
          this.setState({ 
            LOADING: false,
            sliderdet: result.slider,
          });
      
          if(notify!=='') {
              window.$('#ModalAlert').modal('show');
          }
        })
        .catch(error => {
              this.setState({ Notifications: error.toString() });
              console.error('There was an error!', error);
          });
    }

    componentDidMount() {
      this.getSlider();
    }

    render() {
      /*if(!localStorage.getItem('userData')) {
        return (<Redirect to={'/logout'}/>)
      }*/


      let AppHeaderClass = 'appHeader no-border for-service scrolled bg-transparent text-grey pt-2 pb-2';
      let AppHeaderLeft = 'showLINK';
      let AppHeaderLeftLink = '/home';
      let AppHeaderTitle = <h5 className="text-upper lt-spc1 text-grey5b mb-0 pt-05 pb-05">{(this.state.sliderdet && this.state.sliderdet.length>0)?this.state.sliderdet[0].NAME:''}</h5>
      let AppHeaderRight = '';
      let AppHeaderRightLink = '';

      return(
      <>
        {this.state.LOADING && 
                  <Loader />
              }

              {this.state.sliderdet && this.state.sliderdet.length>0 &&
                <AppHeader 
                      AppHeaderClass={AppHeaderClass} 
                      AppHeaderLeft={AppHeaderLeft} 
                      AppHeaderLeftLink={AppHeaderLeftLink} 
                      AppHeaderTitle={AppHeaderTitle} 
                      AppHeaderRight={AppHeaderRight} 
                      AppHeaderRightLink={AppHeaderRightLink} 
                  />
                }

                <div id="appCapsule" className="pt-0">
                    <div className="section full">
                        <div className="service-banner">
                          <img src={(this.state.sliderdet && this.state.sliderdet.length>0)?this.state.sliderdet[0].PIC:''} width="100%" alt="service banner image" />
                          {/*<h3>{(this.state.sliderdet && this.state.sliderdet.length>0)?this.state.sliderdet[0].NAME:''}</h3>*/}
                        </div>
                    </div>

                   <div className="offers-details service-mod section-curve service-list">
                      <ul className="listview image-listview media no-bborder pb-0 mb-0 no-border">
                          <li>
                             <div className="item pt-0 pb-1 pl-0">
                                <div className="in">
                                   <div className="text-gre5 ft-wt-600 font-18 w-65 text-upper">
                                      {(this.state.sliderdet && this.state.sliderdet.length>0)?this.state.sliderdet[0].NAME:''}
                                   </div>
                                   <div className="text-lightred ft-wt-600 font-16 w-40 text-right">
                                      {(this.state.sliderdet && this.state.sliderdet.length>0)?this.state.sliderdet[0].TYPE:''}
                                   </div>
                                </div>
                             </div>
                          </li>
                      </ul> 
                      <div className="font-14 pb-05 mb-05" dangerouslySetInnerHTML={{__html: (this.state.sliderdet && this.state.sliderdet.length>0)?this.state.sliderdet[0].FULL_DESC:'' }} />
                   </div>
                </div>

              <AppBottomMenu activeClass="category" />
              <BookingStrip2 />
              <ModalAlert modalType={this.state.modalType} Notifications={this.state.Notifications} type={this.state.notifType} modalTitle={this.state.modalTitle} />
          </>
      )
    }
}

export default Slider;