import React from 'react';
import {BrowserRouter, Route, Switch, Link} from 'react-router-dom';
// import Index from './Index';

// basic pages
import Login from './Login';
import Register from './Register';
import VerifyOTP from './VerifyOTP';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import ChangePassword from './ChangePassword';
import Logout from './Logout';

// required pages
import Terms from './terms';
import Privacy from './privacy';
import Refund from './refund';
import ContactUs from './contactus';

// operation pages
import Home from './Home';
import Category from './Category';
import CategoryTmp from './CategoryTmp';
import Services from './Services';
import Booking from './Booking';
import Booking2 from './Booking2';
import Membership from './membership';
import LoyaltyCard from './LoyaltyCard';
import OnlinePay from './OnlinePay';
import PaymentResponse from './PaymentResponse';
import PaymentResponse2 from './PaymentResponse2';
import Offers from './Offers';
import OfferDetails from './OfferDetails';
import SliderDetails from './SliderDetails';
import Profile from './Profile';
import Appointments from './Appointments';
import Notifications from './Notifications';
import EditProfile from './EditProfile';
import SearchResult from './SearchResult';
import Addresses from './Addresses';
import AddAddress from './AddAddress';
import AddAddress2 from './AddAddress2';
import EditAddress from './EditAddress';
import Feedback from './Feedback';
import Reorder from './Reorder';

const Routes = () => (
	<BrowserRouter>
		<Switch>
			<Route path="/" component={Home} exact={true} />
			<Route path="/Home" component={Home} exact={true} />
			<Route path="/SearchResult" component={SearchResult} exact={true} />
			<Route path="/Login" component={Login} exact={true} />
			<Route path="/CategoryTmp" component={CategoryTmp} exact={true} />
			<Route path="/Category/:cid?/:tid?" component={Category} exact={true} />
			<Route path="/Services/:cid?" component={Services} exact={true} />
			<Route path="/Booking" component={Booking} exact={true} />
			<Route path="/Booking2" component={Booking2} exact={true} />
			<Route path="/OnlinePay" component={OnlinePay} exact={true} />
			<Route path="/PaymentResponse" component={PaymentResponse} exact={true} />
			<Route path="/PaymentResponse2" component={PaymentResponse2} exact={true} />
			<Route path="/Offers" component={Offers} exact={true} />
			<Route path="/Membership" component={Membership} exact={true} />
			<Route path="/LoyaltyCard" component={LoyaltyCard} exact={true} />
			<Route path="/OfferDetails/:oid" component={OfferDetails} exact={true} />
			<Route path="/SliderDetails/:sid" component={SliderDetails} exact={true} />
			<Route path="/Register" component={Register} exact={true} />
			<Route path="/ForgotPassword" component={ForgotPassword} exact={true} />
			<Route path="/ResetPassword/:code" component={ResetPassword} exact={true} />
			<Route path="/ChangePassword" component={ChangePassword} exact={true} />
			<Route path="/Profile" component={Profile} exact={true} />
			<Route path="/EditProfile" component={EditProfile} exact={true} />
			<Route path="/Appointments" component={Appointments} exact={true} />
			<Route path="/Notifications" component={Notifications} exact={true} />
			<Route path="/Logout" component={Logout} exact={true} />
			<Route path="/Terms" component={Terms} exact={true} />
			<Route path="/Privacy" component={Privacy} exact={true} />
			<Route path="/Refund" component={Refund} exact={true} />
			<Route path="/ContactUs" component={ContactUs} exact={true} />
			<Route path="/VerifyOTP" component={VerifyOTP} exact={true} />
			<Route path="/Addresses" component={Addresses} exact={true} />
			<Route path="/AddAddress" component={AddAddress} exact={true} />
			<Route path="/AddAddress2" component={AddAddress2} exact={true} />
			<Route path="/EditAddress/:aid" component={EditAddress} exact={true} />
			<Route path="/Feedback/:bid" component={Feedback} exact={true} />
			<Route path="/Reorder/:bid" component={Reorder} exact={true} />
			<Route component={Home} />
		</Switch>
	</BrowserRouter>
);
export default Routes;