import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import ModalAlert from './ModalAlert';
import AppBottomMenu from './AppBottomMenu';
import BookingStrip2 from './BookingStrip2';
import SearchBar from './SearchBar';
import $ from "jquery";

class Category extends Component {
    constructor(props) {
    	super(props);

    	this.state = {
    		LOADING: true,
    		Notifications: false,
    		modalType: 'ALERT', 
    		notifType: 'error', 
    		modalTitle: '',
    		categoryid: (props.match.params.cid)?props.match.params.cid:0,
    		mainCatId: (props.match.params.tid)?props.match.params.tid:localStorage.getItem('maincatid'),
    		serviceCat: [],
    		serviceSubCat: [],
    		maincat:[],
    		customerid: localStorage.getItem('userData'),
    		propUrl: '',
    		propRedirect: false
    	}

    	this.getSubCat = this.getSubCat.bind(this);
    	localStorage.setItem('backClick', this.state.categoryid);
    }

    getMainCat() {
    	const posturl = global.config.bURL+"serviceCategory.php";
    	const postData = {
    		method: 'POST',
	        headers: { 'Content-Type': 'application/json' },
	        body: JSON.stringify({ mode: 'SERVICE_CATEGORY', type: "parent", cond: "" })
    	}

    	fetch(posturl, postData)
    		.then(res => res.json())
    		.then(result => {
    			// console.log('results obtained', result);

    			let notify = '';
    			if(result.err!=='' && result.err!==undefined)
    			    notify = result.err;

    			this.setState({
    				serviceCat: result.category
    			})
    			
    			if(notify!=='')
    			    window.$('#ModalAlert').modal('show');
    		})
    		.catch(error => {
	            this.setState({ Notifications: error.toString() });
	            console.error('There was an error!', error);
	        });
    }

    getSubCat() {
    	const posturl = global.config.bURL+"serviceCategory.php";
    	let cond_str = (this.state.categoryid)?" and iParentID="+this.state.categoryid: " and iParentID<>0";
    	
    	/*if(this.state.mainCatId!=0 && this.state.mainCatId!=undefined && this.state.mainCatId!="")
    		cond_str += " and iTypeID="+this.state.mainCatId;*/

    	const postData = {
    		method: 'POST',
	        headers: { 'Content-Type': 'application/json' },
	        body: JSON.stringify({ mode: 'SERVICE_CATEGORY', type: "subcat", cond: cond_str })
    	}

    	fetch(posturl, postData)
    		.then(res => res.json())
    		.then(result => {
    			// console.log('results obtained', result);

    			let notify = '';
    			if(result.err!=='' && result.err!==undefined) {
    			    notify = result.err;
    			}
    			else {
	    			this.setState({
	    				serviceSubCat: result.category2,
	    				maincat: result.types,
	    				LOADING: false
	    			})
	    		}
    			
    			if(notify!=='')
    			    window.$('#ModalAlert').modal('show');
    		})
    		.catch(error => {
	            this.setState({ Notifications: error.toString() });
	            console.error('There was an error!', error);
	        });
    }

    registermaincat = (main_id) => {
    	// console.log('changing main id', main_id);
    	localStorage.setItem('maincatid', main_id);
    }

    componentDidMount() {
    	this.getMainCat();
    	this.getSubCat();
    }

    componentDidUpdate(prevProps) {
    	var catid = prevProps.match.params.cid;
    	var typeid = prevProps.match.params.tid;
    	var new_catid = this.props.match.params.cid;
    	var new_typeid = this.props.match.params.tid;

  		if (new_catid !== catid || new_typeid !== typeid ) 
  		{
  			window.location.reload();
  			// console.log('reloading now');
  			/*this.setState({
  				propUrl: this.props.match.url,
				propRedirect: true
  			});*/
  		}
	}

    render() {
    	/*if(!localStorage.getItem('userData')) {
    		return (<Redirect to={'/logout'}/>)
    	}*/

    	let AppHeaderClass = 'appHeader no-border bg-coach-header text-light pt-2 pb-2';
    	let AppHeaderLeft = 'showLINK';
    	let AppHeaderLeftLink = '/home';
    	let AppHeaderTitle = <h5 className="text-upper lt-spc1 text-grey5b mb-0 pt-05 pb-05">Services</h5>
    	let AppHeaderRight = '';
    	let AppHeaderRightLink = '';

    	if(this.state.propRedirect)
    	{
    		return(<Redirect to={this.state.propUrl} />);
    	}

    	return(
			<>
				{this.state.LOADING && 
	                <Loader />
	            }

	            <AppHeader 
                    AppHeaderClass={AppHeaderClass} 
                    AppHeaderLeft={AppHeaderLeft} 
                    AppHeaderLeftLink={AppHeaderLeftLink} 
                    AppHeaderTitle={AppHeaderTitle} 
                    AppHeaderRight={AppHeaderRight} 
                    AppHeaderRightLink={AppHeaderRightLink} 
                />

                <div id="appCapsule">
	                <div className="section full mt-0 mb-2 multitab">
	                    <div className="choose-from wide-block pt-0 pb-0 pr-0 pl-0 mt-2  mr-2 ml-2 curve">
	                        <ul className="nav nav-tabs style1" role="tablist">
	                        	{/*Main tab here*/}
	                        	<li className="nav-item">
	                        	    <a className="nav-link active" data-toggle="tab" href="#home" role="tab">
	                        	        <svg xmlns="http://www.w3.org/2000/svg" width="12.163" height="18.906" viewBox="0 0 12.163 18.906">
		                                     <path id="scissors" d="M16.8,14.834c1.307,0,2.365,1.362,2.365,3.041S18.1,20.916,16.8,20.916s-2.365-1.361-2.365-3.041a3.364,3.364,0,0,1,.981-2.456L13.082,12.07l-2.333,3.348a3.362,3.362,0,0,1,.982,2.456c0,1.68-1.059,3.041-2.365,3.041S7,19.555,7,17.875s1.059-3.041,2.365-3.041a1.861,1.861,0,0,1,.393.051l2.348-4.215L9.653,7.148c-2-3.466-.419-5.138-.419-5.138l3.848,6.909,3.848-6.909s1.582,1.672-.419,5.138l-2.454,3.522L16.4,14.886a1.813,1.813,0,0,1,.393-.052ZM9.365,16.173a1.549,1.549,0,0,0-1.351,1.689,1.549,1.549,0,0,0,1.351,1.689,1.549,1.549,0,0,0,1.351-1.689A1.549,1.549,0,0,0,9.365,16.173Zm3.717-6.011a.676.676,0,1,0,.676.676A.676.676,0,0,0,13.082,10.161Zm2.365,7.7A1.549,1.549,0,0,0,16.8,19.551a1.549,1.549,0,0,0,1.351-1.689A1.549,1.549,0,0,0,16.8,16.173,1.549,1.549,0,0,0,15.447,17.862Z" transform="translate(-7 -2.01)" fill="#fff"></path>
		                                </svg>
	                        	        <p className="mb-0 text-upper">Salon</p>
	                        	    </a>
	                        	</li>
	                        	<li className="nav-item">
	                        	    <a className="nav-link" data-toggle="tab" href="#salon" role="tab">
	                        	        <svg id="home3" xmlns="http://www.w3.org/2000/svg" width="17.186" height="15.488" viewBox="0 0 17.186 15.488">
										  <path id="home3-2" data-name="home3" d="M17.186,12.178,13.963,8.736V4.311H11.815V6.442L8.593,3,0,12.178v.574H2.148v5.736h4.68V13.571h3.529v4.917h4.68V12.751h2.148Z" transform="translate(0 -3)" fill="#fff"></path>
										</svg>
	                        	        <p className="mb-0 text-upper">Home</p>
	                        	    </a>
	                        	</li>
	                        </ul>
	                    </div>

	                    <div className="tab-content mt-1">
	                    	{/*Main tab content here*/}
	                    	<div className="tab-pane fade show active" id="home" role="tabpanel">
	                    	    <div className="section pt-2 pb-0 pr-0 pl-0 mb-0">
		                    	    <div className="pl-2 pr-2">
		                    	    	<ul className="nav nav-tabs lined" role="tablist">
	                        	            {/*salon tab subtab titles here : start*/}
		                    	    	    <li className="nav-item">
		                    	    	        <a className="nav-link active" data-toggle="tab" href="#salon-hair" role="tab">
		                    	    	            <div className="cat-image">
		                    	    	               <img src="https://amanorabeautylounge.com/uploads/category/21-category-smallimg1-20220211.1207.png" width="100%" alt="Baby Products" />
		                    	    	            </div>
	                    	    	                <p className="ft-wt-500 mb-0 pt-05">Hair</p>
		                    	    	        </a>
		                    	    	    </li>
		                    	    	    <li className="nav-item">
		                    	    	        <a className="nav-link" data-toggle="tab" href="#salon-skin" role="tab">
			                    	    	        <div className="cat-image">
		                    	    	               <img src="https://amanorabeautylounge.com/uploads/category/21-category-smallimg1-20220211.1207.png" width="100%" alt="Baby Products" />
		                    	    	            </div>
		                    	    	            <p className="ft-wt-500 mb-0 pt-05">Skin</p>
		                    	    	        </a>
		                    	    	    </li>
		                    	    	    <li className="nav-item">
		                    	    	        <a className="nav-link" data-toggle="tab" href="#salon-body" role="tab">
			                    	    	        <div className="cat-image">
		                    	    	               <img src="https://amanorabeautylounge.com/uploads/category/21-category-smallimg1-20220211.1207.png" width="100%" alt="Baby Products" />
		                    	    	            </div>
		                    	    	            <p className="ft-wt-500 mb-0 pt-05">Body</p>
		                    	    	        </a>
		                    	    	    </li>
		                    	    	    <li className="nav-item">
		                    	    	        <a className="nav-link" data-toggle="tab" href="#salon-nails" role="tab">
			                    	    	        <div className="cat-image">
		                    	    	               <img src="https://amanorabeautylounge.com/uploads/category/21-category-smallimg1-20220211.1207.png" width="100%" alt="Baby Products" />
		                    	    	            </div>
		                    	    	            <p className="ft-wt-500 mb-0 pt-05">Nails</p>
		                    	    	        </a>
		                    	    	    </li>
		                    	    	    <li className="nav-item">
		                    	    	        <a className="nav-link" data-toggle="tab" href="#salon-makeup" role="tab">
			                    	    	        <div className="cat-image">
		                    	    	               <img src="https://amanorabeautylounge.com/uploads/category/21-category-smallimg1-20220211.1207.png" width="100%" alt="Baby Products" />
		                    	    	            </div>
		                    	    	            <p className="ft-wt-500 mb-0 pt-05">Makeup</p>
		                    	    	        </a>
		                    	    	    </li>
	                        	            {/*salon tab subtab titles here : end*/}
		                    	    	</ul>

	                                    <div className="tab-content mt-1">
		                    	    	    {/*salon tab sub here Subtab content : start*/}
	    	    	                    	<div className="tab-pane fade" id="salon-hair" role="tabpanel">
	    	    	                    	    <div className="section pt-2 pb-0 pr-0 pl-0 mb-0">
	    	    		                    	    <div className="">
	    	    		                    	    	salon-hair
	    	    		                    	    </div>
	    	    	                    	    </div>
	    	    	                    	</div>
	    	    	                    	<div className="tab-pane fade" id="salon-skin" role="tabpanel">
	    	    	                    	    <div className="section pt-2 pb-0 pr-0 pl-0 mb-0">
	    	    		                    	    <div className="">
	    	    		                    	    	salon-skin
	    	    		                    	    </div>
	    	    	                    	    </div>
	    	    	                    	</div>
	    	    	                    	<div className="tab-pane fade" id="salon-body" role="tabpanel">
	    	    	                    	    <div className="section pt-2 pb-0 pr-0 pl-0 mb-0">
	    	    		                    	    <div className="">
	    	    		                    	    	salon-body
	    	    		                    	    </div>
	    	    	                    	    </div>
	    	    	                    	</div>
	    	    	                    	<div className="tab-pane fade" id="salon-nails" role="tabpanel">
	    	    	                    	    <div className="section pt-2 pb-0 pr-0 pl-0 mb-0">
	    	    		                    	    <div className="">
	    	    		                    	    	salon-nails
	    	    		                    	    </div>
	    	    	                    	    </div>
	    	    	                    	</div>
	    	    	                    	<div className="tab-pane fade" id="salon-makeup" role="tabpanel">
	    	    	                    	    <div className="section pt-2 pb-0 pr-0 pl-0 mb-0">
	    	    		                    	    <div className="">
	    	    		                    	    	salon-makeup
	    	    		                    	    </div>
	    	    	                    	    </div>
	    	    	                    	</div>
			                    	    	{/*salon tab sub here Subtab content : end*/}
	                                    </div>
		                    	    </div>
	                    	    </div>
	                    	</div>

	                    	<div className="tab-pane fade" id="salon" role="tabpanel">
	                    	    <div className="section pt-2 pb-0 pr-0 pl-0 mb-0">
		                    	    <div className="pl-2 pr-2">
		                    	    	<ul className="nav nav-tabs lined" role="tablist">
	                        	            {/*home tab subtab titles here : start*/}
		                    	    	    Home service tabs
	                        	            {/*home tab subtab titles here : end*/}
		                    	    	</ul>

	                                    <div className="tab-content mt-1">
		                    	    	    {/*home  tab sub here Subtab content : start*/}
	    	    	                    	
			                    	    	{/*home tab sub here Subtab content : end*/}
	                                    </div>
		                    	    </div>
	                    	    </div>
	                    	</div>
	                    	<div>
	                    		{/*Subtab title*/}
	                    	</div>

	                    	<div>
	                    		{/*Subtab content here*/}
	                    	</div>
	                    </div>
	                </div>

                   	
                </div>

	            <AppBottomMenu activeClass="category" />
	            <BookingStrip2 />
	            <ModalAlert modalType={this.state.modalType} Notifications={this.state.Notifications} type={this.state.notifType} modalTitle={this.state.modalTitle} />
	        </>
    	)
    }
}

export default Category;