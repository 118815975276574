import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import ModalAlert from './ModalAlert';
import AppBottomMenu from './AppBottomMenu';
import BookingStrip from './BookingStrip';
import SearchBar from './SearchBar';
import {servicePrice} from './Common';
import $ from "jquery";

class Services extends Component {
    constructor(props) {
    	super(props);

    	this.state = {
    		LOADING: true,
    		Notifications: "",
    		modalType: 'ALERT', 
    		notifType: 'error', 
    		modalTitle: '',
    		serviceSubCat: [],
    		services: [],
        maincat:[],
    		subcatid: props.match.params.cid,
    		customerid: localStorage.getItem('userData'),
    		redirectBooking: 'N',
        selectedCartData: [],
        selectedCartTotal: 0,
        selectedCartTotal2: 0,
        cartUpdated: 'N',
        backClick: localStorage.getItem('backClick'),
        currentType: localStorage.getItem('maincatid'),
        mainCatId: (localStorage.getItem('maincatid2'))?localStorage.getItem('maincatid2'):1,
        minOrder:0,
    	}

    	this.getSubCat = this.getSubCat.bind(this);
    	this.getServices = this.getServices.bind(this);
      this.getCartDetails = this.getCartDetails.bind(this);
    }

    getCartDetails() {
      this.setState({cartUpdated:'N'});
      // console.log('cart updatedddd:', this.state.cartUpdated);
      const posturl = global.config.bURL+"getCartDetails.php";
      const postData = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({mode: 'CART', mainid: localStorage.getItem('maincatid2'), service_det: localStorage.getItem('cartData') })
      }

      fetch(posturl, postData)
      .then(res => res.json())
      .then(result => {
        // console.log('results obtained', result);

        let notify = '';
        if(result.err!=='' && result.err!==undefined)
          notify = result.err;

        this.setState({ 
          selectedCartData: result.cart_id,
          selectedCartTotal: result.cart_total,
          selectedCartTotal2: result.cart_total2,
          cartUpdated: "Y",
          minOrder: result.MIN_ORDER,
          mainCatId: localStorage.getItem('maincatid2')
        }, ()=>{
          if(result.cart_id && result.cart_id.length==0)
          {
            // console.log('removing maincatid2');
            localStorage.removeItem('maincatid2');
          }
        });

        if(notify!=='') {
          window.$('#ModalAlert').modal('show');
        }
      })
      .catch(error => {
        this.setState({ Notifications: error.toString() });
        console.error('There was an error!', error);
      });
    }

    getSubCat() {
    	const posturl = global.config.bURL+"serviceCategory.php";
    	const postData = {
    		method: 'POST',
	        headers: { 'Content-Type': 'application/json' },
	        body: JSON.stringify({ mode: 'SERVICE_CATEGORY', type: "subcat", cond: "and iCatID="+this.state.subcatid })
    	}

    	fetch(posturl, postData)
    		.then(res => res.json())
    		.then(result => {
    			// console.log('results obtained', result);

    			let notify = '';
    			if(result.err!=='' && result.err!==undefined) {
    			    notify = result.err;
    			}

    			this.setState({
    				serviceSubCat: result.category2[0],
            maincat: result.types
    			})
    			
    			if(notify!=='')
    			    window.$('#ModalAlert').modal('show');
    		})
    		.catch(error => {
	            this.setState({ Notifications: error.toString() });
	            console.error('There was an error!', error);
	        });
    }

    getServices() {
    	const posturl = global.config.bURL+"services.php";
    	const postData = {
    		method: 'POST',
    	    headers: { 'Content-Type': 'application/json' },
    	    body: JSON.stringify({mode: 'SERVICES', type: "", cond: " and iCatID="+this.state.subcatid })
    	}
    	
    	fetch(posturl, postData)
    		.then(res => res.json())
    		.then(result => {
    			// console.log('results obtained', result);
    	
    			let notify = '';
    			if(result.err!=='' && result.err!==undefined) {
    			    notify = result.err;
    			}
    			else {
	    			this.setState({ 
	    				services: result.services,
	    				LOADING: false
	    			});
	    		}
    	
    			if(notify!=='') {
    	    		window.$('#ModalAlert').modal('show');
    			}
    		})
    		.catch(error => {
    	        this.setState({ Notifications: error.toString() });
    	        console.error('There was an error!', error);
    	    });
    }

    registermaincat = (main_id) => {
      // console.log('changing main id', main_id);
      localStorage.setItem('maincatid', main_id);
      this.setState({
        currentType:main_id
      })
    }

    clearcart = (sid, tid) => {
        // clear items from cart;
        // console.log("clear items from cart");
        localStorage.removeItem('cartData');
        localStorage.removeItem('cartDate');
        localStorage.removeItem('cartTime');
        localStorage.removeItem('COUPON_APPLIED');

        // add the selected to cart
        let last_click = JSON.parse(localStorage.getItem("current_click"));
        localStorage.setItem('maincatid2', last_click.tid);
        this.handleAddtoCart(last_click.id, last_click.tid);
    }

    handleAddtoCart = (sid, tid) => {
      $('#submitLoading_'+sid).css('display','block');
      $( "#cartbtn_"+sid ).fadeOut( 600 ).delay( 300 ).fadeIn( 0 );

    	let pax = 1;
    	let services_arr = (localStorage.getItem('cartData'))?JSON.parse(localStorage.getItem('cartData')):[];
    	let current_click = {id: sid, price: "", pax:pax, tid:tid};
      localStorage.setItem('current_click', JSON.stringify(current_click));

      // alert(tid == this.state.mainCatId || services_arr=='');

      if(tid == this.state.mainCatId || services_arr=='')
      {
        localStorage.setItem('maincatid2', tid);
      	services_arr.push(current_click);

      	localStorage.setItem('cartData', JSON.stringify(services_arr));

      	// console.log('Cart Data', localStorage.getItem('cartData'));
      	if(localStorage.getItem('cartData')) {
          this.getCartDetails();
          if(this.state.cartUpdated=='Y')
          {
            // console.log('closing loader');
            $('#submitLoading_'+sid).css('display','none');
          }
      	}
      }
      else
      {
        window.$('#confirmAlert').modal('show');
      }
    }

    handleaRemovefromCart = (sid) => {
      $('#submitLoading_'+sid).css('display','block');
      $( "#cartbtn_"+sid ).fadeOut( 600 ).delay( 300 ).fadeIn( 0 );

      let new_arr = [];
      let services_arr = (localStorage.getItem('cartData'))?JSON.parse(localStorage.getItem('cartData')):[];

      if(sid!='' && sid!=undefined) {
        services_arr.forEach((element, index) => {
          if(element.id!=sid)
          {
            new_arr.push(element);
          }
        });

        // set the new array
        let newarr = localStorage.setItem('cartData', JSON.stringify(new_arr));
      }
      this.getCartDetails();
      if(this.state.cartUpdated=='Y')
      {
        // console.log('closing loader');
        $('#submitLoading_'+sid).css('display','none');
      }
    }

    componentDidMount() {
      this.getSubCat();
    	this.getServices();
      this.getCartDetails();
    }

    render() {
    	/*if(!localStorage.getItem('userData')) {
    		return (<Redirect to={'/logout'}/>)
    	}*/

    	if(this.state.redirectBooking=='Y') {
    		// // console.log('redirection now ...');
    		return (<Redirect to={'/booking'}/>)	
    	}

    	let AppHeaderClass = 'appHeader no-border bg-coach-header text-light pt-2 pb-2';
    	let AppHeaderLeft = 'showLINK';
    	let AppHeaderLeftLink = (this.state.backClick && this.state.backClick!=0)?'/category/'+this.state.backClick:'/category';
    	let AppHeaderTitle = (this.state.serviceSubCat && this.state.serviceSubCat.PARENT_NAME)?<h5 className="text-upper lt-spc1 text-grey5b mb-0 pt-05 pb-05">{this.state.serviceSubCat.PARENT_NAME}</h5>:"";
    	let AppHeaderRight = '';
    	let AppHeaderRightLink = '';
      let selectedCount = (this.state.selectedCartData)?this.state.selectedCartData.length:0;

    	return(
			<>
				{this.state.LOADING && 
	                <Loader />
	            }

              {AppHeaderTitle != '' &&
	            <AppHeader 
                    AppHeaderClass={AppHeaderClass} 
                    AppHeaderLeft={AppHeaderLeft} 
                    AppHeaderLeftLink={AppHeaderLeftLink} 
                    AppHeaderTitle={AppHeaderTitle} 
                    AppHeaderRight={AppHeaderRight} 
                    AppHeaderRightLink={AppHeaderRightLink} 
                />
              }

                <div id="appCapsule" className="pt-0">
                	{/*{this.state.serviceSubCat &&
                		<div className="section full">
                		   <div className="service-banner">
                       <img src={this.state.serviceSubCat.IMAGE} alt="service banner image" />
                		      <h3>{this.state.serviceSubCat.NAME}</h3>
                		   </div>
                		</div>
                	}*/}

                    <div className="choose-from wide-block pt-0 pb-0 pr-0 pl-0 mt-7 mr-2 ml-2 mb-0 pb-0 curve">
                       <ul className="nav nav-tabs style1" role="tablist">
                          {this.state.maincat && this.state.maincat.length > 0 &&
                            this.state.maincat.map((main, i)=>{
                              
                              let tabUrl = main.URLNAME;
                              let activeClass = "nav-link";

                              if(main.ID == this.state.currentType)
                                activeClass = "nav-link active";

                              return(
                                <li className="nav-item" id={main.ID} key={i}>
                                    <a className={activeClass} data-toggle="tab" href={'#'+tabUrl} role="tab" onClick={()=>this.registermaincat(main.ID)}>
                                      <div dangerouslySetInnerHTML={{__html: main.ICON}} />
                                        <p className="mb-0 text-upper">{main.NAME}</p>
                                    </a>
                                </li>
                              )
                            })
                          }
                       </ul>
                    </div>

                    <SearchBar />

                    {this.state.serviceSubCat &&
                		<div className="section full">
                		   <div className="service-banner2 ml-2">
                		      <h3>{this.state.serviceSubCat.NAME}</h3>
                		   </div>
                		</div>
                	}

                      <div className="tab-content mt-1 mr-0 ml-0">
                          {this.state.maincat && this.state.maincat.length > 0 ?
                            this.state.maincat.map((main, i)=>{
                              
                              let tabUrl = main.URLNAME;
                              let activeClass = "tab-pane fade";

                              if(main.ID == this.state.currentType)
                                activeClass = "tab-pane fade show active";

                              if(main.COUNT>0)
                              {
                                {console.log(this.state.services)}
                                return(
                                  <div className={activeClass} id={tabUrl} role="tabpanel" key={i}>
                                    <div className="service-mod section-curve service-list no-gap">
                                       <ul className="listview image-listview media mb-2 no-bborder">
                                         {this.state.services && this.state.services.length!==0 ?
                                           this.state.services.map((service, i) => {
                                             let isItemAdded = this.state.selectedCartData.includes(service.VID);
                                             let shortDesc = (service.DESC.length>40)?service.DESC.substring(0,40)+'.. <a class="btn-readmore text-gold font-10" data-toggle="collapse" data-target="#showmore-'+service.VID+'" href="javascript:;">Read more</a>':service.DESC;
                                             let longDesc = service.DESC;
                                             let packageClass = "";
                                             if(service.PACKAGE == 'Y') packageClass = "bg-service-package";

                                             if(service.TID == this.state.currentType)
                                             {
                                               return (
                                                 <li key={i} className={packageClass}>
                                                    <div className="item pt-2 pb-2 pl-0">
                                                       <div className="in">
                                                         <div className="service-title ft-wt-500 ft-15">{service.NAME}</div>
                                                       </div>
                                                       <div className="in">
                                                          <div className="text-gre5 ft-wt-400 font-14 w-63 l-16" data-toggle="collapse" data-target={"#showmore-"+service.VID}>
                                                             {service.TIME && 
                                                               <div className="text-grey7 text-upper pt-05 ft-wt-400 font-13 lt-spc1">
                                                                 {service.TIME}
                                                               </div>
                                                             }
                                                             {service.DESC &&
                                                               <div id={"showmore-"+service.VID} className="text-grey7 pt-05 ft-wt-400 font-11 lt-spc1 accordion-body collapse" dangerouslySetInnerHTML={{__html: longDesc}} />
                                                             }
                                                             <div id={"showmore-"+service.VID} className="text-grey7 pt-05 ft-wt-400 font-11 lt-spc1 accordion-body collapse show" dangerouslySetInnerHTML={{__html: shortDesc}} />
                                                             <p className="card-text pt-05 text-grey4 font-11 ft-wt-400 mb-0"><span className="text-lightred">{service.OFFER_STR}</span></p>
                                                          </div>
                                                           <div className="w-25">
                                                             {service.CONSULT == 'N' ?
                                                               <>
                                                                 {service.HAS_OFFER=='Y'&&
                                                                   <div className="text-grey7 ft-wt-400 font-11 text-right">
                                                                     <strike><span className="arial">&#8377;</span> {service.DISP_RATE}</strike>
                                                                   </div>
                                                                 }
                                                                 <div className="text-lightred ft-wt-500 font-13 text-right">
                                                                   <span className="arial">&#8377;</span> {service.OFFER_PRICE_DISP}
                                                                 </div>
                                                               </>
                                                               :
                                                               <div className="text-grey7 ft-wt-600 font-10 text-right">
                                                                 On consultation
                                                               </div>
                                                             }
                                                           </div>
                                                          <div className="text-lightred ft-wt-600 font-16 w-20 text-right w-10 cart-cover">
                                                             <span className="spinner-border spin-loader spinner-border-sm m-0 " id={"submitLoading_"+service.VID} style={{ display: 'none' }} role="status" aria-hidden="true"></span>
                                                             {
                                                               (!isItemAdded)?
                                                               <button type="button" id={"cartbtn_"+service.VID} className="select-btn set4 mt-0 text-center font-12 ft-wt-700 mar-auto1" onClick={()=>this.handleAddtoCart(service.VID, service.TID)}><ion-icon name="add-outline"></ion-icon></button>:
                                                               <button type="button" id={"cartbtn_"+service.VID} className="select-btn set4 mt-0 text-center font-12 ft-wt-700 mar-auto1 select-check" onClick={()=>this.handleaRemovefromCart(service.VID)}><ion-icon className="select-check" name="checkmark-outline"></ion-icon></button>
                                                             }
                                                             <button type="button" className="select-btn select-btn1 set4 mt-0 text-center font-12 ft-wt-700 mar-auto1 select-check"></button>
                                                          </div>
                                                       </div>
                                                    </div>
                                                 </li>
                                               )
                                             }
                                           })
                                           : <li className=""><p className="norecords">No records found.</p></li>
                                         }
                                       </ul> 
                                    </div>
                                  </div>
                                )
                              }
                            })
                            : <p className="norecords">No records found.</p>
                          }
                      </div>
                </div>

                {this.state.selectedCartData && this.state.selectedCartData!= '' &&
                  <BookingStrip totalAmount={this.state.selectedCartTotal} totalAmount2={this.state.selectedCartTotal2} minOrder={this.state.minOrder} selectedCount={selectedCount} />
                }
	            <AppBottomMenu activeClass="category" />
	            <ModalAlert modalType={this.state.modalType} Notifications={this.state.Notifications} type={this.state.notifType} modalTitle={this.state.modalTitle} />

              <div className="modal fade dialogbox" id="confirmAlert" data-backdrop="static" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <p className="modal-title text-upper font-15 pb-1">Clear Cart</p>
                    </div>
                    <div className="modal-body mb-05"><p className="font-15">You have selected a service from another section <br/> Do you wish to clear your cart and proceed ?</p></div>
                    <div className="modal-footer">
                      <div className="btn-inline"> 
                          <button type="button" className="btn btn-text-secondary text-red font-14" data-dismiss="modal">CANCEL</button> 
                          <button type="button" onClick={()=>this.clearcart(this.state.rmvidStr)} className="btn btn-text-primary text-gold font-14" data-dismiss="modal">CONFIRM</button> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
	        </>
    	)
    }
}

export default Services;