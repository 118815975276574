
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import ModalAlert from './ModalAlert';
import AppBottomMenu from './AppBottomMenu';
import BookingStrip2 from './BookingStrip2';
import $ from "jquery";

class Services extends Component {
    constructor(props) {
      super(props);

      this.state = {
        LOADING: true,
        Notifications: false,
        modalType: 'ALERT', 
        notifType: 'error', 
        modalTitle: '',
        customerid: localStorage.getItem('userData'),
        offerdet: [],
        offercat: [],
        offerid: props.match.params.oid,
      }

      this.getOffers = this.getOffers.bind(this);
    }

    getOffers() {
      const posturl = global.config.bURL+"offers.php";
      const postData = {
        method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({mode: 'OFFERS', customerid:this.state.customerid, offerid: this.state.offerid})
      }
      
      fetch(posturl, postData)
        .then(res => res.json())
        .then(result => {
          // console.log('results obtained', result);
      
          let notify = '';
          if(result.err!=='' && result.err!==undefined)
              notify = result.err;
      
          this.setState({ 
            LOADING: false,
            offerdet: result.offers,
            offercat: result.offer_cat
          });
      
          if(notify!=='') {
              window.$('#ModalAlert').modal('show');
          }
        })
        .catch(error => {
              this.setState({ Notifications: error.toString() });
              console.error('There was an error!', error);
          });
    }

    componentDidMount() {
      this.getOffers();
    }

    render() {
      /*if(!localStorage.getItem('userData')) {
        return (<Redirect to={'/logout'}/>)
      }*/


      let AppHeaderClass = 'appHeader no-border for-service scrolled bg-transparent text-grey pt-2 pb-2';
      let AppHeaderLeft = 'showLINK';
      let AppHeaderLeftLink = '/offers';
      let AppHeaderTitle = <h5 className="text-upper lt-spc1 text-grey5b mb-0 pt-05 pb-05">{(this.state.offerdet && this.state.offerdet.length>0)?this.state.offerdet[0].NAME:''}</h5>
      let AppHeaderRight = '';
      let AppHeaderRightLink = '';

      return(
      <>
        {this.state.LOADING && 
                  <Loader />
              }

              {this.state.offerdet && this.state.offerdet.length>0 &&
                <AppHeader 
                      AppHeaderClass={AppHeaderClass} 
                      AppHeaderLeft={AppHeaderLeft} 
                      AppHeaderLeftLink={AppHeaderLeftLink} 
                      AppHeaderTitle={AppHeaderTitle} 
                      AppHeaderRight={AppHeaderRight} 
                      AppHeaderRightLink={AppHeaderRightLink} 
                  />
                }

                <div id="appCapsule" className="pt-0">
                    <div className="section full">
                        <div className="service-banner">
                          <img src={(this.state.offerdet && this.state.offerdet.length>0)?this.state.offerdet[0].PIC:''} width="100%" alt="service banner image" />
                          {/*<h3>{(this.state.offerdet && this.state.offerdet.length>0)?this.state.offerdet[0].NAME:''}</h3>*/}
                        </div>
                    </div>

                   <div className="offers-details service-mod section-curve service-list">
                      <ul className="listview image-listview media no-bborder pb-0 mb-0 no-border">
                          <li>
                             <div className="item pt-2 pb-1 pl-0">
                                <div className="in">
                                   <div className="text-gre5 ft-wt-700 font-22 w-65 text-upper">
                                      {(this.state.offerdet && this.state.offerdet.length>0)?this.state.offerdet[0].NAME:''}
                                   </div>
                                   <div className="text-lightred ft-wt-600 font-16 w-40 text-right">
                                      {(this.state.offerdet && this.state.offerdet.length>0)?this.state.offerdet[0].TYPE:''}
                                   </div>
                                </div>
                             </div>
                          </li>
                      </ul> 
                      <p className="font-14 pb-05 mb-05">{(this.state.offerdet && this.state.offerdet.length>0)?this.state.offerdet[0].FULL_DESC:''}</p>
                      {this.state.offerdet && this.state.offerdet.length>0 &&
                        <p className="font-16 text-yellow"><span  className="ft-wt-600">Validity:</span> {this.state.offerdet[0].FROM_DATE}- {this.state.offerdet[0].TO_DATE}</p>
                      }
                      <div className="service-chip">
                        <div className="text-upper pb-2">
                          {this.state.offercat && this.state.offercat.length > 0 &&
                            this.state.offercat[0].map((cat, i) => {
                              return(
                                <Link to={'/services/'+cat.ID} key={i} className="btn btn-outline-mix rounded newshadowed btn-lsm mr-1 mb-1">{cat.NAME}</Link>       
                              )
                            })
                          }
                        </div>
                      </div>
                   </div>
                </div>

              <AppBottomMenu activeClass="category" />
              <BookingStrip2 />
              <ModalAlert modalType={this.state.modalType} Notifications={this.state.Notifications} type={this.state.notifType} modalTitle={this.state.modalTitle} />
          </>
      )
    }
}

export default Services;