import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';

class Privacy extends Component {
	constructor(props) {
		super(props);
		this.state = {
			LOADING:true
		}
	}

	componentDidMount() {
		this.setState({
			LOADING:false
		})
	}

	render() {
		let AppHeaderClass = 'appHeader no-border bg-coach-header text-light pt-2 pb-2';
		let AppHeaderLeft = 'showLINK';
		let AppHeaderLeftLink = '/profile';
		let AppHeaderTitle = <h5 className="text-upper lt-spc1 text-grey5b pt-05 pb-0 mb-0">Privacy Policy</h5>;
		let AppHeaderRight = '';
		let AppHeaderRightLink = '';

		return(
			<>
			    {this.state.LOADING && 
			        <Loader />
			    }
			    <AppHeader 
                    AppHeaderClass={AppHeaderClass} 
                    AppHeaderLeft={AppHeaderLeft} 
                    AppHeaderLeftLink={AppHeaderLeftLink} 
                    AppHeaderTitle={AppHeaderTitle} 
                    AppHeaderRight={AppHeaderRight} 
                    AppHeaderRightLink={AppHeaderRightLink} 
                />

                <div id="appCapsule">
                	<div className="section register full mt-0 mb-2 basic-form">
                		<div className="wide-block no-border mt-1 pb-1 pt-0 pr-3 pl-3">
                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Amanora's ambition is to be an exemplary corporate citizen to help make the world a more beautiful place. We place great value on honesty and clarity and we are committed to building a strong and lasting relationship with our consumers based on trust and mutual benefit. Part of this commitment means safeguarding and respecting your privacy and your choices. Respecting your privacy is essential to us. This is why we set out &ldquo;Our Privacy Promise&rdquo; and our full Privacy Policy below.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Our Privacy Promise</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">1) We respect your privacy and your choices. 2) We make sure that privacy and security are embedded in everything we do. 3) We do not send you marketing communications unless you have asked us to. You can change your mind at any time. 4) We never offer or sell your data. 5) We are committed to keeping your data safe and secure. This includes only working with trusted partners. 6) We are committed to being open and transparent about how we use your data. 7) We do not use your data in ways that we have not told you about. 8) We respect your rights, and always try to accommodate your requests as far as is possible, in line with our own legal and operational responsibilities.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">For more information about our privacy practices, below we set out what types of personal data we may receive from you directly or from your interaction with us, how we may use it, who we may share it with, how we protect it and keep it secure, and your rights around your personal data. Of course all situations may not apply to you. This Privacy Policy gives you an overview of all possible situations in which we could interact together.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">The more you interact with us, the more you let us know you and the more we are able to offer you tailored services.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">When you share personal data with us or when we collect personal data about you, we use it in line with this Policy. Please read this information and our Q&amp;A page (if any) carefully. If you have any questions or concerns about your personal data, please contact us at advisor.info@amanorasalon.com.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">WHAT WILL YOU FIND IN THIS PRIVACY POLICY ?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Who are we ? What is personal data ? What data do we collect from you and how do use it ? How do we collect or receive your data ? Automated Decision Making Profilling Who may access your personal data? Where we store your personal data? How long do we keep your personal data? Is my Personal data secure? Links to third party sites and social login Social media and user generated content Your rights and choices Contact</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> Who we are</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Amanora (India) Limited is responsible for the personal data that you share with us. When we say &ldquo;Amanora&rdquo;, &ldquo;us&rdquo;, &ldquo;our&rdquo; or &ldquo;we&rdquo;, this is who we are referring to. Amanora is the &ldquo;data controller&rdquo; for the purposes of applicable data protection laws.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Amanora represents several different brands and products. For more information on Amanora, and the brands it represents, please see https://www.loreal.com/en/uki/</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Amanora is part of the Amanora Group, which operates in 140 countries around the world. For details on the Amanora Group, please see http://www.loreal.com/group.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">What is personal data?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">&ldquo;Personal data&rdquo; means any information or pieces of information that could identify you either directly (e.g. your name) or indirectly (e.g. through pseudonymized data such as a unique ID number). This means that personal data includes things like email/home addresses/mobile phone, usernames, profile pictures, personal preferences and shopping habits, user generated content, financial information, and welfare information. It could also include unique numerical identifiers like your computer&rsquo;s IP address or your mobile device&rsquo;s MAC address, as well as cookies.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">What data do we collect from you and how do we use it?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Amanora believes that you, the consumer, are at the heart of what we do. We love hearing from you, learning about you, and creating and delivering products that you enjoy. And we know that many of you love talking to us. Because of this, there are many ways that you might share your personal data with us, and that we might collect it.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">How do we collect or receive your data?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">We might collect or receive data from you via our websites, forms, apps, devices, Amanora products or brands pages on social media or otherwise. Sometimes you give this to us directly (e.g. when you create an account, when you contact us, when you purchase from our websites/apps or stores/beauty salon), sometimes we collect it (e.g. using cookies to understand how you use our websites/apps) or sometimes we receive your data from other third parties, including other Amanora Group entities.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">When we collect data, we indicate the mandatory fields via asterisks where such data is necessary for us to:</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">-Perform our contract with you (e.g. to deliver the products you have purchase on our websites/apps); -Provide you with the service you have asked for (e.g. to provide you with a newsletter); or -Comply with legal requirements (e.g. invoicing).</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">If you do not provide the data marked with an asterisk, this may affect our ability to provide the products and services.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Below are the purposes we can use data collected from you in case consent has been provided by you while providing the data at any of your engagement with us, over and above what has been explained in the subsequent sections:</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> Beauty Profile   Contact us   Contest Games   Email Marketing   Group Marketing   Phone Marketing   Postal Marketing   Profiling Marketing   Rating &amp; reviews   Sampling pages   SMS Marketing   Term of use   Terms &amp; conditions   User generated consent</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Depending on the purpose for which the data is used, the legal basis for the processing of your data can be:</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> Your consent;  Our legitimate interest, which can be:  &bull; Improvement of our products and services: more specifically, our business interests to help us better understand your needs and expectations and therefore improve our services, websites / Apps / devices, products and brands for our consumers&rsquo; benefit. &bull; Fraud prevention: to ensure payment is complete and free from fraud and misappropriation. &bull; Securing our tools: to keep tools used by you (our websites/Apps/devices) safe and secure and to ensure they are working properly and are continually improving.  The performance of a contract: more specifically to perform the services you request from us;  Legal grounds where a processing is required by law.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> Information overview on your interactions with us and their consequences on your data</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Account creation and management</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Information collected during the creation of an account on Amanora websites/apps, through a social media login, or in store.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">What personal data may we receive from you directly or resulting from your interaction with us?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Depending on how much you are interacting with us, those data may include:  &bull; Name and surname;   &bull; Gender;  &bull; Email address;  &bull; Address;  &bull; Phone number;  &bull; Photo;  &bull; Birthday or age range;  &bull; ID, username, and password;  &bull; Personal description or preferences;   &bull; Order details;  &bull; Social media profile (where you use social login or share this personal data with us).</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">How and why we may use your data?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">To:  &bull; Manage your orders;  &bull; Manage any competitions, promotions, surveys or contests;  &bull; Respond to your questions and otherwise interact with you;  &bull; Offer you a loyalty program;  &bull; Allow you to manage your preferences;  &bull; Offer personalized services based on your beauty characteristics;  &bull; Monitor and improve our websites/apps ;  &bull; Run analytics or collect statistics  &bull; Secure our websites/apps and protect you and us against fraud;</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">What is the legal basis for using your personal data?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> &bull; Performance of a contract To provide you with the service you requested (e.g. create an account, complete a survey, or purchasing a product).</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">  &bull; Consent To send you direct marketing communications.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> &bull; Legitimate Interest  To ensure our websites/apps remain secure, to protect them against fraud, and to help us better understand your needs and expectations and therefore improve our services, products and brands.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Newsletter and commercial communications subscription</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">What personal data may we receive from you directly or resulting from your interaction with us?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Depending on how much you are interacting with us, those data may include:  &bull; Email address;  &bull; Name and surname;   &bull; Personal description or preferences;   &bull; Social media profile (where you use social login or share this personal data with us).</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">How and why we may use your data?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">To:  &bull; Send you marketing communications which may be tailored to your &ldquo;profile&rdquo; based on the personal data we know about you, and your preferences (incl. location of your favorite store);</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> &bull; Run analytics or collect statistics.  &bull; Keep an up-to-date suppression list if you have asked not to be contacted;</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">What is the legal basis for using your personal data?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> &bull; Consent To send you direct marketing communications.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">&bull; Legitimate Interest To tailor our marketing communications, understand their effectiveness, and ensure you receive the most relevant experience; to help us better understand your needs and expectations and therefore improve our services, products and brands.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">  &bull; Legal grounds To keep your details on a suppression list if you have asked us not to send you any direct marketing anymore.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Purchases and order management</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> Information collected during the purchase process made on Amanora website/apps/social pages or in store.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">What personal data may we receive from you directly or resulting from your interaction with us?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Depending on how much you are interacting with us, those data may include:  &bull; Name and surname;   &bull; Email address;  &bull; Address (delivery and invoicing);  &bull; Phone number;  &bull; Personal description or preferences;   &bull; Social media profile (where you use social login or share this personal data with us);   &bull; Transaction information including purchased products and store location;  &bull; Payment and information; or  &bull; Purchase history</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">How and why we may use your data?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">To   &bull; Contact you to finalize your order where you have saved your shopping cart or placed products in your cart without completing the checkout process;   &bull; Inform you when a product you wanted to purchase is available;  &bull; Process and follow your order including delivering the product to the address you indicated;   &bull; Manage the payment of your order. To be noted, payment information (credit card number / Paypal information / bank account details) are not collected by us but directly by payment service providers;   &bull; Manage any contact you have with us regarding your order;</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> &bull; Secure the transactions against fraud. To be noted, we use a third party provider&rsquo;s solution to detect fraud and ensure the payment is complete and made by you or someone authorized by you;  &bull; Enrich your profile if you place a purchase using your account information;  &bull; Measure satisfaction;  &bull; Manage any dispute relating to a purchase;  &bull; For statistics purposes.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">What is the legal basis for using your personal data?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">&bull; Performance of a contract:  To provide you with the service you requested (purchase).</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> &bull; Legitimate interest  To protect you and us from fraudulent transaction and to ensure the payment is complete and free from fraud and misappropriation.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Online browsing</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Information collected by cookies or similar technologies (&ldquo;Cookies&rdquo;*) as part of your browsing on Amanora website/apps and/or on third-party website/apps.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">* Cookies are small text files stored on your device (computer, tablet or mobile) when you are on the Internet, including on L&rsquo;Oreal Group&rsquo;s websites.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">What personal data may we receive from you directly or resulting from your interaction with us?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Depending on how much you are interacting with us, those data may include:  &bull; Data related to your use of our websites/apps:   &bull; Where you came from;  &bull; Login details;  &bull; Pages you looked at;  &bull; Videos you watched;  &bull; Ads you click on or tap;  &bull; Products you search for;  &bull; Your location;  &bull; Duration of your visit;  &bull; Products you selected to create your basket.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Technical information:   &bull; IP address;  &bull; browser information;   &bull; device information.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">A unique identifier granted to each visitor and the expiration date of such identifier.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">How and why we may use your data?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">We use Cookies, where relevant, with other personal data you have already shared with us (such as previous purchases, or whether you&rsquo;re signed up to our email newsletters) or the following purposes:</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">&bull; To allow proper functioning of our website/apps:</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">proper display of the content creation and remembering of a cart; creation and remembering of your login; interface personalisation such as language; parameters attached to your device including your screen resolution, etc; improvement of our websites/apps, for example, by testing new ideas. </p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">&bull; To ensure the website/app is secure and safe and protect you against fraud or misuse of our websites or services, for example through performing troubleshooting.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">&bull; To run statistics:</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">To avoid visitors being recorded twice;  To know users&rsquo; reaction to our advertising campaigns;  To improve our offers; To know how you discovered our websites / apps. &bull; To deliver online behavioural advertising:</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">to show you online advertisements for products which may be of interest to you, based on your previous behaviour;  to show you ads and content on social media platforms. &bull; To tailor our services for you:</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">to send you recommendations, marketing, or content based on your profile and interests;  to display our websites/apps in a tailored way like remembering your cart or login, your language, the user-interface customization cookies (i.e. the parameters attached to your device including your screen resolution, font preference, etc). &bull; To allow sharing of our content on social media (sharing buttons intended to display the site).</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">What is the legal basis for using your personal data?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">&bull; Legitimate interest:  To ensure we are providing you with websites/apps, advertising and communications that are working properly and are continually improving for cookies that are (i) essential for the functioning of our websites/apps, (ii) used to keep our websites/apps safe and secure.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> &bull; Consent For all other cookies.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Promotional operations</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Information collected during a game, contests, promotional offer, sample requests, surveys.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> What personal data may we receive from you directly or resulting from your interaction with us?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Depending on how much you are interacting with us, those data may include:  &bull; Name and surname;  &bull; Email address;  &bull; Phone number;  &bull; Birth date;  &bull; Gender;  &bull; Address;  &bull; Personal description or preferences;   &bull; Social media profile (where you use social login or share this personal data with us);  &bull; Other information you have shared with us about yourself (e.g. via your &ldquo;My Account&rdquo; page, by contacting us, or by providing your own content such as photos or a review, or a question via the chat function available on some websites/apps, or by participating in a contest, game, survey).</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">  How and why we may use your data?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> &bull; To complete tasks that you have asked us to, for example to manage your participation in contests, games and surveys, including to take into account your feedback and suggestions;  &bull; For statistics purposes.  &bull; To send you marketing communications</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">What is the legal basis for using your personal data?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">&bull; Performance of contract To provide you with the service you requested.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> &bull; Legitimate Interest To to help us better understand your needs and expectations and therefore improve our services, products and brands.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">&bull; Consent To send you direct marketing communications.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">User generated content</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Information collected when you submitted some content on one of our social platforms or accepted the re-use of content you posted on social media platforms by us.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> What personal data may we receive from you directly or resulting from your interaction with us?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Depending on how much you are interacting with us, those data may include:  &bull; Name and surname or alias;  &bull; Email address;  &bull; Photo;</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> &bull; Personal description or preferences;   &bull; Social media profile (where you use social login or share this personal data with us);  &bull; Other information you have shared with us about yourself (e.g. via your &ldquo;My Account&rdquo; page, by contacting us, or by providing your own content such as photos or a review, or a question via the chat function available on some websites/apps).</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">How and why we may use your data?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> &bull; In accordance with the specific terms and conditions accepted by you:</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">To post your review or content; To promote our products.  &bull; For statistics purposes.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">What is the legal basis for using your personal data?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">&bull; Consent To reuse the content you posted online.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> &bull; Legitimate Interest To help us better understand your needs and expectations and therefore improve and promote our services, products and brands.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Use of Apps and devices</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Information collected as part of your use of our Apps and/or devices.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> What personal data may we receive from you directly or resulting from your interaction with us?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Depending on how much you are interacting with us, those data may include:  &bull; Name and surname;  &bull; Email address;  &bull; Location;  &bull; Birth date;  &bull; Personal description or preferences;  &bull; Photo;  &bull; Welfare data including skin tone, skin/hair type  &bull; Geolocation.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">How and why we may use your data ?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">To  &bull; Provide you with the service requested (for example, virtually test our products, purchase our products through the App or on related e-com websites; advice and notifications regarding your sun exposure, your hair routine);   &bull; Analyse your welfare characteristics and recommend the appropriate products (including bespoke products) and routines;  &bull; Provide you product &amp; routine recommendations;  &bull; For research and innovation by scientists within Amanora Group;  &bull; For monitoring and improvement of our Apps and devices;  &bull; For statistics purposes.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">What is the legal basis for using your personal data?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">&bull; Performance of a contract To provide you with the service requested (including, where needed, analysis by the research and innovation team of the algorithm necessary to provide the service).</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> &bull; Legitimate Interest To always improve our products and services to match your needs and expectations and for research and innovation purposes.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Enquiries</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Information collected when you ask questions (e.g. through our consumer care or contact us forms on websites) relating to our brands, our products and their use.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> What personal data may we receive from you directly or resulting from your interaction with us?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Depending on how much you are interacting with us, those data may include:  &bull; Name and surname;  &bull; Phone number;  &bull; Email address;  &bull; Other information you have shared with us about yourself in relation to your enquiry (which may include welfare and health data).</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">How and why we may use your data?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> &bull; To answer your enquiries; Where needed, to connect you with the relevant services   &bull; For statistics purposes.  &bull; For Cosmetovigilance:</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">To monitor and prevent any undesirable effect linked to the use of our products; To perform studies relating to the safe use of our products; To perform and follow-up on corrective measures taken, where needed. What is the legal basis for using your personal data?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">&bull; Consent To process your enquiry.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> &bull; Legitimate interest To help us better understand our customers&rsquo; needs and expectations and therefore improve our services, products and brands.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> &bull; Legal grounds To comply with the legal obligation to monitor undesirable effects of its products.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Sponsorship</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">For purposes of securing transactions placed through our websites/apps/devices against fraud and misappropriation, we use third party provider&rsquo;s solution(s).The method of fraud detection is based on, for example, simple comparisons, association, clustering, prediction and outlier detections using intelligent agents, data fusion techniques and various data mining techniques.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">This fraud detection process may be completely automated or may involve human intervention where a person takes the final decision. In any case, we take all reasonable precautions and safeguards to limit access to your data.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">As a result of automatic fraud detection, you may (i) experience delay in the processing of your order / request whilst your transaction is being reviewed by us; and (ii) be limited or excluded from the benefit of a service if a risk of fraud is identified. You have the right to access information on which we base our decision. Please see &ldquo;Your Rights and Choices&rdquo; section below.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Automated decision making</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">When we send or display personalised communications or content, we may use some techniques qualified as &ldquo;profiling&rdquo; (i.e. any form of automated processing of personal data consisting of using those data to evaluate certain personal aspects relating to a natural person, in particular to analyse or predict aspects concerning that natural person&rsquo;s personal preferences, interests, economic situation, behaviour, location, health, reliability, or movements). This means that we may collect personal data about you in the different scenarios as mentioned above. We centralize this data and analyse it to evaluate and predict your personal preferences and/or interests. Based on our analysis, we send or display communications and/or content tailored to your interests/needs.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">You have the right to object to the use of your data for &ldquo;profiling&rdquo; in certain circumstances. Please see &ldquo;Your Rights and Choices&rdquo; section below.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Profiling</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">What personal data may we receive from you directly or resulting from your interaction with us?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Depending on how much you are interacting with us, those data may include:  &bull; Name and surname;  &bull; Phone number;  &bull; Email address.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">How and why we may use your data ?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> &bull; To send information on our products and or information tagged in a wish list to a person at another person&rsquo;s request.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">What is the legal basis for using your personal data?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">&bull; Performance of a contract To process the request.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">  And</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> &bull; Legitimate interest To contact the person at another person&rsquo;s request.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Who may access your Personal data?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">We may share your personal data within Amanora Group to comply with our legal obligations, to prevent fraud and/or to secure our tools, to improve our products and services, or after having obtained your consent to do so.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Depending on the purposes for which they were collected, and only on a need-to-know basis some of your personal data may be accessed by Amanora Group entities worldwide, where possible in a pseudonimized way (not allowing direct identification), and where necessary to provide you with requested services.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">We may also share your personal data in a pseudonimized way (not allowing direct identification) with Amanora Research &amp; Innovation scientists, including those located outside of your country, for research and innovation purposes.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Where permitted, we may also share some of your personal data including those collected through Cookies between our brands to harmonize and update the information you share with us, to perform statistics based on your characteristics and to tailor our communications.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Please visit the Amanora group website, for further details on the Amanora Group, its brands and its locations.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">We may share your personal data for marketing purposes with third party or entities of the Amanora Group.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">We only share your personal data with third parties for direct marketing purposes with your consent. In this context, your data is processed by such third party, acting as a data controller, and its own terms and conditions and privacy notice apply. You should carefully check their documentation before consenting to the disclosure of your information to that third party.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Your personal data may also be processed on our behalf by our trusted third party providers.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">We rely on trusted third parties to perform a range of business operations on our behalf. We only provide them with the information they need to perform the service, and we require that they do not use your personal data for any other purpose. We always use our best efforts to ensure that all third parties we work with keep your personal data secure. For instance, we may entrust services that require the processing of your personal data to:</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> &bull; Third parties that assist and help us in providing digital and e-commerce services such as social listening, store locator, loyalty programs, identity management, ratings and reviews, CRM, web analytics and search engine, user generated content curation tools;   &bull; Advertising, marketing, digital and social media agencies to help us to deliver advertising, marketing, and campaigns, to analyse their effectiveness, and to manage your contact and questions;  &bull; Third parties required to deliver a product to you e.g. postal/delivery services;  &bull; Third parties that assist and help us in providing IT services, such as platform providers, hosting services, maintenance and support on our databases as well as on our software and applications that may contain data about you (such services could sometimes imply access to your data to perform the required tasks);  &bull; Payment service providers and credit reference agencies for the purpose of assessing your credit score and verifying your details where this is a condition of entering into a contract with you;  &bull; Third parties that assist us for customer care and cosmetovigilance purposes.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">We may also disclose your personal data to third parties:</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> &bull; In the event that we sell any business or assets, in which case we may disclose your personal data to the prospective buyer of such business or assets. If Amanora or a part of its assets is acquired by a third party, personal data held by it about its customers relating to those assets is one of the transferred assets. Where appropriate, in such case, the buyer acting as the new data controller processes your data and its privacy policy governs the processing of your personal data.   &bull; If we are under a duty to disclose or share your personal data in order to comply with a legal obligation, or in order to enforce or apply our terms of use/sales or other terms and conditions you have agreed to; or to protect the rights, property, or safety of Amanora, our customers, or employees.   &bull; If we have your consent to do so  &bull; Or if we are permitted to do so by law.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">We may disclose your personal data to our partners:</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> &bull; In the event the service you subscribe to was co-created by Amanora and a partner (for example, a co-branded app). In such case, Amanora and the partner process your personal data each for their own purposes and as such your data is processed:</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> &bull; By Amanora in accordance with this Privacy Policy;</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> &bull; By the partner acting also as a data controller under its own terms and conditions and in accordance with its own privacy policy.   &bull; In the event you agreed to receive marketing and commercial communications from a Amanora partner through a dedicated opt-in (for instance, through an App branded by Amanora and made available to its partners). In such case, your data is processed by the partner acting as a data controller under its own terms and conditions, and in accordance with its privacy policy.  &bull; We may publish on our supports content from social networks. In the event you consult content from social networks on our website/apps, a Cookie from such social network may be stored on your device. We invite you to read the Cookie Policy of these social networks for more information.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> We do not offer or sell your personal data.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Where we Store your Personal data</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">The data that we collect from you may be transferred to, accessed from, and stored at a destination outside India. It may also be processed by staff members operating outside India who work for us or for one of our service providers.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Amanora transfers personal data outside of India only in a secure and lawful way. As some countries may not have laws governing the use and transfer of personal data, we take steps to make sure that third parties adhere to the commitments set out in this Policy. These steps may include reviewing third parties&rsquo; privacy and security standards and/or entering into appropriate contracts.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">For further information, please contact us as per the &ldquo;Contact&rdquo; section below.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">How long do we keep your Personal data</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">We only keep your personal data for as long as we need it for the purpose for which we hold your personal data, to meet your needs, or to comply with our legal obligations.  To determine the data retention period of your data, we use the following criteria:</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> &bull; Where you purchase products and services, we keep your personal data for the duration of our contractual relationship;  &bull; Where you participate in a promotional offer, we keep your personal data for the duration of the promotional offer;  &bull; Where you contact us for an enquiry, we keep your personal data for the duration needed for the processing of your enquiry;  &bull; Where you create an account, we keep your personal data until you require us to delete it or after a period of inactivity (no active interaction with brands) defined in accordance with local regulations and guidance;  &bull; Where you have consented to direct marketing, we keep your personal data until you unsubscribe or require us to delete it or after a period of inactivity (no active interaction with brands) defined in accordance with local regulations and guidance;  &bull; Where cookies are placed on your computer, we keep them for as long as necessary to achieve their purposes (e.g. for the duration of a session for shopping cart cookies or session ID cookies) and for a period defined in accordance with local regulations and guidance.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">We may retain some personal data to comply with our legal or regulatory obligations, as well as to allow us to manage our rights (for example to assert our claims in Courts) or for statistical or historical purposes.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">When we no longer need to use your personal data, it is removed from our systems and records or anonymized so that you can no longer be identified from it.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Is your Personal data Secure?</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">If you have any questions or concerns about how we treat and use your personal data, or would like to exercise any of your rights above, please contact us at advisor.info@amanorasalon.com or by writing to us at A-Wing, 8th Floor, Marathon Futurex, NM Joshi Marg, Lower Parel, Mumbai, Maharashtra 400013</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">We are committed to keeping your personal data secure, and taking all reasonable precautions to do so. We contractually require that trusted third parties who handle your personal data for us do the same.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">We always do our best to protect your personal data and once we have received your personal data, we use strict procedures and security features to try to prevent unauthorized access. As the transmission of information via the internet is not completely secure, we cannot guarantee the security of your data transmitted to our site. As such, any transmission is at your own risk.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Links to third party sites and social login</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Our websites and Apps may from time to time contain links to and from the websites of our partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we are not responsible or liable for these policies. Please check these policies before you submit any personal data to these websites.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">We may also offer you the opportunity to use your social media login. If you do so, please be aware that you share your profile information with us depending on your social media platform settings. Please visit the relevant social media platform and review its privacy policy to understand how your personal data is shared and used in this context.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Social Media and User generated content</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Some of our websites and Apps allow users to submit their own content. Please remember that any content submitted to one of our social media platforms can be viewed by the public, so you should be cautious about providing certain personal data e.g., financial information or address details. We are not responsible for any actions taken by other individuals if you post personal data on one of our social media platforms and we recommend that you do not share such information.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Your Rights and Choices</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Amanora respects your right to privacy: it is important that you are able to control your personal data. You have the following rights:</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">The right to be informed What does this mean? You have the right to obtain clear, transparent and easily understandable information about how we use your personal data, and your rights. This is why we are providing you with the information in this Policy.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">The right of access What does this mean? You have the right to access to the personal data we hold about you (subject to certain restrictions).  We may charge a reasonable fee taking into account the administrative costs of providing the information. Requests manifestly unfounded, excessive or repetitive may not be answered to.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">To do this, please contact us at the details below.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> The right to rectification What does this mean? The right to rectification You have the right to have your personal data rectified if it is incorrect or outdated and/or completed if it is incomplete.  To do this, please contact us at the details below. If you have an account, it may be easier to correct your own data via your &ldquo;My Account&rdquo; function.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">The right to erasure/right to be forgotten What does this mean? In some cases, you have the right to have your personal data erased or deleted. Note this is not an absolute right, as we may have legal or legitimate grounds for retaining your personal data.  If you would like us to delete your personal data, please contact us at the details below.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">The right to object to direct marketing, including profiling What does this mean? You can unsubscribe or opt out of our direct marketing communication at any time.  It is easiest to do this by clicking on the &ldquo;unsubscribe&rdquo; link in any email or communication we send you. Otherwise, you can contact us using contact detail below.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">If you would like to object to any profiling, please contact us at the details below.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> The right to withdraw consent at any time for data processing based on consent What does this mean? You can withdraw your consent to our processing of your data when such processing is based on consent. The withdrawal of consent shall not affect the lawfulness of processing based on consent before its withdrawal. We refer to above section for &ldquo;what data do we collect from you and how do we use it&rdquo; especially the section &ldquo;What is our legal basis for processing your data?&rdquo; to identify where our processing is based on consent.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"></p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">If you would like to object to withdraw your consent, please contact us at the details below.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> The right to object to processing based on legitimate interests What does this mean? You can oppose at any time to our processing of your data when such processing is based on the legitimate interest. We refer to above sections &ldquo;what data do we collect from you and how do we use it&rdquo; especially the section &ldquo;What is our legal basis for processing your data?&rdquo; to identify where our processing is based on legitimate interests.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> To do so, please contact us at the details below.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> The right to lodge a complaint with a supervisory authority What does this mean? You have the right to contact the data protection authority of your country in order to lodge a complaint against the data protection and privacy practices of Amanora.  Do not hesitate to contact us at the details below before lodging any complaint with the competent data protection authority.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">The right to data portability What does this mean? You have rights to move, copy or transfer data from our database to another. This only applies to data that you have provided, where processing is based on a contract or your consent, and the processing is carried out by automated means. We refer to above sections &ldquo;what data do we collect from you and how do we use it&rdquo; especially the section &ldquo;What is our legal basis for processing your data?&rdquo; to identify where our processing is based on the performance of a contract or on consent.  For further details, please contact us at the details below.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">The right to restriction What does this mean? You have the right to request restriction of our processing of your data. This right means that our processing of your data is restricted, so we can store it, but not use nor process it further.  It applies in limited circumstances listed by the General Data Protection Regulation which are as follow:  &bull; the accuracy of the personal data is contested by the data subject (i.e., You), for a period enabling the controller to verify the accuracy of the personal data;  &bull; the processing is unlawful and the data subject (i.e., You) opposes the erasure of the personal data and requests the restriction of their use instead;  &bull; the controller (i.e., Amanora) no longer needs the personal data for the purposes of the processing, but they are required by the data subject for the establishment, exercise or defense of legal claims;  &bull; the data subject (i.e., You) has objected to processing based on the legitimate interests of the data controller pending the verification whether the legitimate grounds of the controller override those of the data subject.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">If you would like to request restriction, please contact us at the details below.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey"> The right to deactivate Cookies What does this mean? You have the right to deactivate Cookies. The settings from the Internet browsers are usually programmed by default to accept Cookies, but you can easily adjust it by changing the settings of your browser.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">Many cookies are used to enhance the usability or functionality of websites/apps; therefore, disabling cookies may prevent you from using certain parts of our websites/apps.</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">If you wish to restrict or block all the cookies which are set by our websites/apps (which may prevent you from using certain parts of the site), or any other websites/apps, you can do this through your browser settings. The Help function within your browser should tell you how. For more information, please consult the following links:  http://www.aboutcookies.org/;</p>

                			<p className="font-14 ft-wt-400 lt-spc05 text-dgrey">To deal with your request, we may require proof of your identity.</p>

                		</div>
                	</div>
                </div>
			</>
		)
	}
}
export default Privacy;