import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

class AppHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AppHeaderClass: props.AppHeaderClass,
            AppHeaderLeft: props.AppHeaderLeft,
            AppHeaderLeftLink: props.AppHeaderLeftLink,
            AppHeaderTitle: props.AppHeaderTitle,
            AppHeaderRight: props.AppHeaderRight,
            AppHeaderRightLink: props.AppHeaderRightLink,
            AppHeaderRightPic: props.AppHeaderRightPic,
            AppHeaderSearch: props.AppHeaderSearch || '',
            redirect: false,
            search_text: '',
            serviceCat: [],
        }

        this.getMainCat = this.getMainCat.bind(this);
    }

    getMainCat() {
        const posturl = global.config.bURL+"serviceCategory.php";
        const postData = {
            method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ mode: 'SERVICE_CATEGORY', type: "parent", cond: "" })
        }

        fetch(posturl, postData)
            .then(res => res.json())
            .then(result => {
                // console.log('results obtained', result);

                let notify = '';
                if(result.err!=='' && result.err!==undefined)
                    notify = result.err;

                this.setState({
                    serviceCat: result.category
                })
            })
            .catch(error => {
              this.setState({ Notifications: error.toString() });
              console.error('There was an error!', error);
          });
    }

    componentDidMount() {
        this.getMainCat();
    }

    render() {

        return (
            <>
            <div className="section full mt-2">
                <div className={this.state.AppHeaderClass}>
                    <div className="left">
                        {this.state.AppHeaderLeft == 'showLINK' ?
                            <Link to={process.env.PUBLIC_URL+this.state.AppHeaderLeftLink} className="headerButton goBack"><ion-icon name="chevron-back-outline"></ion-icon></Link>
                            :<Link to="#" className="headerButton" data-toggle="modal" data-target="#sidebarPanel"><ion-icon name="menu-outline"></ion-icon></Link>
                        }
                    </div>
                    <div className="pageTitle customer-name">{this.state.AppHeaderTitle}</div>
                    <div className="right">
                        {this.state.AppHeaderRight == 'showPROFILE' ?
                            <Link to={'/profile'} className="headerButton profile-btn">
                                <span className="imaged w48 rounded">{(this.props.customerName)?this.props.customerName.charAt(0):'C'}</span>
                               {/*<img className="imaged w48 rounded" src={this.state.AppHeaderRightPic} alt="profile" />*/}
                            </Link>
                            :
                            <Link to={this.state.AppHeaderRightLink} className="headerButton"></Link>
                        }
                    </div>
                </div>
            </div>

            <div className="modal fade panelbox panelbox-left" id="sidebarPanel" tabIndex="-1" role="dialog">
               <div className="modal-dialog" role="document">
                   <div className="modal-content">
                       <div className="modal-body p-0">

                           {/*<!-- profile box -->*/}
                           <div className="profileBox">
                                <img src={process.env.PUBLIC_URL+'/assets/images/amanora-logo.png'} alt="image" className="imaged" />
                                {/*<div className="image-wrapper">
                                   <img src={process.env.PUBLIC_URL+'/assets/images/amanora-logo.png'} alt="image" className="imaged rounded" />
                                </div>
                                <div className="in">
                                  <strong>{this.props.customerName}</strong>
                                </div>*/}
                               <Link to="#" className="close-sidebar-button" data-dismiss="modal">
                                   <ion-icon name="close"></ion-icon>
                               </Link>
                           </div>
                           {/*<!-- * profile box -->*/}


                           <ul className="listview flush transparent no-line image-listview mt-2">
                                {this.state.serviceCat && this.state.serviceCat.length !==0 &&
                                    this.state.serviceCat.map((category, i) => {
                                        let c_url = '/category/'+category.ID+'/1';
                                        return(
                                            <li key={i}>
                                                <Link to={c_url} className="item">
                                                    <div className="in">{category.NAME}</div>
                                                </Link>
                                            </li>
                                        )
                                    })
                                }
                                <li>
                                    <Link to="/membership" className="item">
                                        <div className="in">Membership</div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/LoyaltyCard" className="item">
                                        <div className="in">Loyalty Card</div>
                                    </Link>
                                </li>
                                {/*<li>
                                    <Link to="/contactus" className="item">
                                        <div className="in">Contact Us</div>
                                    </Link>
                                </li>*/}
                                <li>
                                    <div className="item text-gold">
                                        <ion-icon name="call-outline"></ion-icon>
                                        <a className="banner-contact text-gold text-gre5 mt-0 wow fadeInUp font-12" href="tel:+917438888666"> +91 7438888666 /</a>
                                        <a className="banner-contact text-gold mt-0 wow fadeInUp font-12" href="tel:+917438888777"> +91 7438888777</a>
                                    </div>
                                </li>
                           </ul>
                       </div>

                       <div className="sidebar-buttons">

                           <a href="https://www.facebook.com/AmanoraTheBeautyLounge/" target="_blank" className="button">
                               <ion-icon name="logo-facebook"></ion-icon>
                           </a>
                           <a href="https://www.instagram.com/amanorathebeautylounge/" target="_blank" className="button">
                               <ion-icon name="logo-instagram"></ion-icon>
                           </a>
                           <a href="https://wa.me/917438888777" target="_blank" className="button">
                               <ion-icon name="logo-whatsapp"></ion-icon>
                           </a>
                           <a href="https://www.google.com/maps/place/Amanora+The+Beauty+Lounge/@15.5399741,73.8197612,17z/data=!3m1!4b1!4m5!3m4!1s0x3bbfc1197a75894f:0x3f5ed34063af479f!8m2!3d15.5399689!4d73.8219499" target="_blank" className="button">
                              <ion-icon name="location-outline"></ion-icon>
                           </a>
                       </div>
                   </div>
               </div>
            </div>
            </>
        );
    }
}

export default AppHeader;