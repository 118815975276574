
 import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loader from './Loader';
import ModalAlert from './ModalAlert';
import AppBottomMenu from './AppBottomMenu';
import AppHeader from './AppHeader';
import Moment from 'react-moment';
import $ from "jquery";

class Profile extends Component {
    constructor(props) {
    	super(props);

        this.state = {
            LOADING: true,
            Notifications: "",
            modalType: 'ALERT', 
            notifType: 'error', 
            modalTitle: '',
            customerid: localStorage.getItem('userData'),
            customerdet:  [],
            upcomingBooking: [],
        }

        this.getUserDetails = this.getUserDetails.bind(this);
        this.getCustomerBookings = this.getCustomerBookings.bind(this);
    }

      getUserDetails() {
         const posturl = global.config.bURL+"userDetails.php";
         const postData = {
            method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ mode: 'CUSTOMER', id: this.state.customerid })
         }

         fetch(posturl, postData)
            .then(res => res.json())
            .then(result => {
               // console.log('results obtained', result);

               let notify = '';
               /*if(result.err!=='' && result.err!==undefined)
                   notify = result.err;*/

               this.setState({
                  customerdet: result.user,
                  LOADING: false
               })
               
               if(notify!=='')
                   window.$('#ModalAlert').modal('show');
            })
            .catch(error => {
                  console.error('There was an error!', error);
              });
      }

   getCustomerBookings() {
      const posturl = global.config.bURL+"getBooking.php";
      const postData = {
         method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({mode: 'CUSTOMER_BOOKING', customerid:this.state.customerid })
      }
      
      fetch(posturl, postData)
         .then(res => res.json())
         .then(result => {
            // console.log('results obtained', result);
      
            let notify = '';
            if(result.err!=='' && result.err!==undefined)
                notify = result.err;
      
            this.setState({ 
               upcomingBooking: (result.BOOKINGS)?result.BOOKINGS[0]:[]
            });
      
            if(notify!=='') {
               window.$('#ModalAlert').modal('show');
            }
         })
         .catch(error => {
              console.error('There was an error!', error);
          });
   }

    componentDidMount() {
        this.getUserDetails();
        this.getCustomerBookings();
    }

    render() {
        if(!localStorage.getItem('userData')) {
            return (<Redirect to={'/logout'} />)
        }

         let AppHeaderClass = 'appHeader no-border';
         let AppHeaderLeft = '';
         let AppHeaderLeftLink = '/login';
         let AppHeaderTitle = 'Profile';
         let AppHeaderIMAGE = '';
         let AppHeaderRight = 'showPROFILE';
         let AppHeaderRightLink = '';
         let AppHeaderRightPic = process.env.PUBLIC_URL+'/assets/images/unknown-b.jpg';
         let customerName = (this.state.customerdet)?this.state.customerdet.NAME:'Customer';

        return(
        <>
            {this.state.LOADING && 
                <Loader />
            }
            
            {AppHeaderTitle != '' &&
              <AppHeader 
                  AppHeaderClass={AppHeaderClass} 
                  AppHeaderLeft={AppHeaderLeft} 
                  AppHeaderLeftLink={AppHeaderLeftLink} 
                  AppHeaderTitle={AppHeaderTitle} 
                  AppHeaderRight={AppHeaderRight} 
                  AppHeaderRightLink={AppHeaderRightLink} 
                  AppHeaderRightPic={AppHeaderRightPic} 
                  customerName={customerName} 
              />
            }

            <div id="appCapsule" className="pt-1">
               {/*<div className="">
                  <div className="profile-pic mb-0 mt-2 pt-3 pb-1">
                     <div className="profile-cover-outer">
                        <div className="imaged rounded profile-cover">
                           <img src="assets/images/unknown.jpg" alt="image" />
                        </div>
                        <button type="button" className="select-pic"><ion-icon name="camera" role="img" className="md hydrated" aria-label="camera"></ion-icon></button>
                     </div>
                  </div>
               </div>  */}      

               <div className="section user-options mt-5 pr-0 pl-0">
                  <ul className="user-name listview image-listview media mb-0 no-border">
                     <li>
                        <Link to="#" className="item">
                           <div className="in">
                              <div>
                                 <div className="font-18 text-gre5 ft-wt-600">Hi, {customerName}</div>
                              </div>
                              {/*<Link to={'/editprofile'} className="edit-btn btn btn-ssm mb-0 font-10 text-upper ft-wt-600 let-1 text-gre8 text-right">
                                  <ion-icon name="pencil-outline"></ion-icon>
                              </Link>*/}
                           </div>
                        </Link>
                    </li>
                  </ul>
                  <ul className="user-edits listview image-listview media mb-0 pb-3">
                     <li>
                        <Link to="/appointments" className="item">
                           <div className="icon-box">
                              <ion-icon name="calendar-outline" role="img" className="md hydrated" aria-label="image outline"></ion-icon>
                           </div>
                           <div className="in">
                              <div>
                                 <div className="ft-wt-500">Appointments</div>
                              </div>
                             {/* {this.state.upcomingBooking && this.state.upcomingBooking.DT_APPOINTMENT &&
                                 <span className="text-lightred2 text-upper ft-wt-700 font-12 lt-spc05">
                                    <Moment date={this.state.upcomingBooking.DT_APPOINTMENT} format="DD MMM YYYY"/>
                                 </span>
                              }*/}
                           </div>
                        </Link>
                     </li>
                     {/*<li>
                        <Link to="/notifications" className="item">
                           <div className="icon-box">
                              <ion-icon name="notifications-outline" role="img" className="md hydrated" aria-label="image outline"></ion-icon>
                           </div>
                           <div className="in">
                              <div>
                                 <div className="ft-wt-500">Notification</div>
                              </div>
                              <span className="text-lightred2 text-upper ft-wt-500 font-12 lt-spc05"><span className="ft-wt-700">2</span> New</span>
                           </div>
                        </Link>
                     </li>*/}
                     <li>
                        <Link to="/addresses" className="item">
                           <div className="icon-box">
                              <ion-icon name="location-outline" role="img" className="md hydrated" aria-label="image outline"></ion-icon>
                           </div>
                           <div className="in">
                              <div>
                                 <div className="ft-wt-500">Addresses</div>
                              </div>
                           </div>
                        </Link>
                     </li>
                     <li>
                        <Link to="/editprofile" className="item">
                           <div className="icon-box">
                              <ion-icon name="settings-outline" role="img" className="md hydrated" aria-label="image outline"></ion-icon>
                           </div>
                           <div className="in">
                              <div>
                                 <div className="ft-wt-500">Edit Profile</div>
                              </div>
                           </div>
                        </Link>
                     </li>
                     <li>
                        <Link to="/changepassword" className="item">
                           <div className="icon-box">
                              <ion-icon name="key-outline" role="img" className="md hydrated" aria-label="image outline"></ion-icon>
                           </div>
                           <div className="in">
                              <div>
                                 <div className="ft-wt-500">Change Password</div>
                              </div>
                           </div>
                        </Link>
                     </li>

                     {/*<li>
                        <Link to="/terms" className="item">
                           <div className="icon-box">
                              <ion-icon name="document" role="img" className="md hydrated" aria-label="image outline"></ion-icon>
                              <ion-icon name="document-text-outline"></ion-icon>
                           </div>
                           <div className="in">
                              <div>
                                 <div className="ft-wt-500">Terms & Conditions</div>
                              </div>
                           </div>
                        </Link>
                     </li>


                     <li>
                        <Link to="/privacy" className="item">
                           <div className="icon-box">
                              <ion-icon name="document" role="img" className="md hydrated" aria-label="image outline"></ion-icon>
                              <ion-icon name="shield-checkmark-outline"></ion-icon>
                           </div>
                           <div className="in">
                              <div>
                                 <div className="ft-wt-500">Privacy Policy</div>
                              </div>
                           </div>
                        </Link>
                     </li>

                     <li>
                        <Link to="/refund" className="item">
                           <div className="icon-box">
                              <ion-icon name="document" role="img" className="md hydrated" aria-label="image outline"></ion-icon>
                              <ion-icon name="reader-outline"></ion-icon>
                           </div>
                           <div className="in">
                              <div>
                                 <div className="ft-wt-500">Cancellation & Refund Policy</div>
                              </div>
                           </div>
                        </Link>
                     </li>*/}

                    {/* <li>
                        <Link to="/contactus" className="item">
                           <div className="icon-box">
                              <ion-icon name="globe" role="img" className="md hydrated" aria-label="image outline"></ion-icon>
                           </div>
                           <div className="in">
                              <div>
                                 <div className="ft-wt-500">Contact Us</div>
                              </div>
                           </div>
                        </Link>
                     </li>
*/}
                     <li>
                        <Link to="/logout" className="item">
                           <div className="icon-box">
                              <ion-icon name="arrow-undo-outline" role="img" className="md hydrated" aria-label="image outline"></ion-icon>
                           </div>
                           <div className="in">
                              <div>
                                 <div className="ft-wt-500">Sign out</div>
                              </div>
                           </div>
                        </Link>
                     </li>
                  </ul>
               </div>
            </div>

            <AppBottomMenu activeClass="profile" />
            {this.state.Notifications!='' &&
               <ModalAlert modalType={this.state.modalType} Notifications={this.state.Notifications} type={this.state.notifType} modalTitle={this.state.modalTitle} />
            }
        </>
        )
    }
}

export default Profile;