import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
import Loader from './Loader';
import AppHeader from './AppHeader';
import $ from "jquery";
import ModalAlert from './ModalAlert';

class ResetPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			LOADING: true, 
			Notifications: false,
			modalType:'ALERT', 
			notifType:'error',
			modalTitle:'',
			customerid: '',
			custCode: props.match.params.code,
			customerdet: []
		}

		this.getUserDetails = this.getUserDetails.bind(this);
	}


	getUserDetails() {
    	const posturl = global.config.bURL+"userDetails.php";
    	const postData = {
    		method: 'POST',
	        headers: { 'Content-Type': 'application/json' },
	        body: JSON.stringify({ mode: 'CUSTOMER', id: this.state.custCode, encode: 'sms'})
    	}

    	fetch(posturl, postData)
    		.then(res => res.json())
    		.then(result => {
    			// console.log('results obtained', result);

    			let notify = '';
    			if(result.err!=='' && result.err!==undefined)
    			    notify = result.err;

    			this.setState({
    				customerdet: result.user,
    				LOADING: false,
    			})
    			
    			if(notify!=='')
    			    window.$('#ModalAlert').modal('show');
    		})
    		.catch(error => {
	            this.setState({ Notifications: error.toString() });
	            console.error('There was an error!', error);
	        });
    }

	handleSubmit = (e) => {
		e.preventDefault();

		let err = 0;
		if ($('#frmReset')[0].checkValidity() === false) {
			e.preventDefault();
			e.stopPropagation();
			err++;
		}
		$('#frmReset')[0].classList.add('was-validated');

		var pass = $('#txtpassword').val();
		var pass2 = $('#txtcnfpassword').val();

		if(pass!==pass2) {
			$('#err_cnfpassword').html('Password mismatch');
			err++;
		}

		if(err===0)
		{
			$('#txtpassword').val(window.hex_md5(pass));
			
			$('#submitLoading').css('display','block');

			const method = "POST";
			const body = new FormData(this.form);
          	fetch(global.config.bURL+'resetPassword.php', { method, body })
          	.then(function (response) {
              return response.json();
          	})
          	.then(result => {
              let notify = '';
              let notifyType = "success";
              if(result.err!=='' && result.err!==undefined) {
              	notify = result.err;
              	notifyType = "error";
              }

              if(result.reset=='Y') {
              	notify = "Password has been reset successfully. \n Please login to continue.";
              }
              else if(result.reset=='N') {
              	notify = "Password reset failed";
              	notifyType = "error";
              }

              this.setState({ 
              	notifType:notifyType,
              	Notifications: notify,
              	customerid: (result.customer)?result.customer:''
              },() =>{
              	// localStorage.setItem('userData', this.state.customerid);
              });


              $('#submitLoading').css('display','none');
              if(notify!=='')
              	window.$('#ModalAlert').modal('show');
          });
      }
  	}

	componentDidMount() {
		this.getUserDetails();
	}

	render() {

		// if(localStorage.getItem('userData')) {
		// 	return (<Redirect to={'/home'} />);
		// }

		let AppHeaderClass = 'appHeader no-border bg-coach-header text-light pt-2 pb-2';
		let AppHeaderLeft = 'showLINK';
		let AppHeaderLeftLink = '/login';
		let AppHeaderTitle = <h5 className="text-upper lt-spc1 text-grey5b pt-05 pb-05">Set New Password</h5>
		let AppHeaderRight = '';
		let AppHeaderRightLink = '';

		return(
			<>
				{this.state.LOADING && 
				    <Loader />
				}

				<div id="appCapsule">
				    <div className="section register full mt-0 mb-2 basic-form">
				        <div className="wide-block no-border mt-1 pb-1 pt-2 pr-3 pl-3">
				            <div className="mb-3">
				                <div className="image-cover">
				                    <div className="inside-cover">
				                       <img src={process.env.PUBLIC_URL+"/assets/images/forgot-password.png"} alt="alt" className="imaged" />
				                   </div>
				               </div>
				               <p className="text-center font-18 ft-wt-500 text-black0 pr-4 pl-4 l-30">{this.state.customerdet.NAME}</p>
				               <p className="text-center font-18 ft-wt-500 text-black0 pr-4 pl-4 l-30">{this.state.customerdet.EMAIL}</p>
				           </div>           

			           		<form id="frmReset" ref={el =>(this.form = el)} onSubmit={this.handleSubmit} className="needs-validation" noValidate autoComplete="off">
			           			<input type="hidden" value="RESET_PASSWORD" className="form-control" name="mode" id="mode"  />
			           			<input type="hidden" value={this.state.custCode} className="form-control" name="code" id="code"  />

				                <div className="listview image-listview no-line no-space flush mr-2 ml-2 pb-4">
				                    <div className="form-group basic">
				                        <div className="input-wrapper guest-detail float-label">
				                            <input type="password" className="form-control" id="txtpassword" name="txtpassword"  required="required" minLength="8" />
				                            <label className="floating-label" htmlFor="txtpassword">New Password</label>
				                            <i className="clear-input">
				                                <ion-icon name="close-circle"></ion-icon>
				                            </i>
				                            <div className="invalid-feedback">Enter password atleast 8 characters long</div>
				                        </div>
				                    </div>

				                    <div className="form-group basic">
				                        <div className="input-wrapper guest-detail float-label">
				                            <input type="text" className="form-control" id="txtcnfpassword"  required="required" minLength="8" />
				                            <label className="floating-label" htmlFor="txtcnfpassword">Confirm Password</label>
				                            <i className="clear-input">
				                                <ion-icon name="close-circle"></ion-icon>
				                            </i>
				                            <div id="err_cnfpassword" className="err-invalid"></div>
				                            <div className="invalid-feedback">Confirm entered password</div>
				                        </div>
				                    </div>
				                    
				                    <div className="form-group basic">
				                        <div className="input-wrapper">
				                            <div className="w-100 pt-3">
				                                <button type="submit" className="btn w-100 rounded bt-bg-gold btn-block btn-lg mr-0 ml-0 text-upper font-14 lt-spc2 ft-wt-700 btn-ht60 box-shadowg">Submit
				                                <span className="spinner-border spinner-border-sm mr-05" id="submitLoading" style={{ display: 'none' }} role="status" aria-hidden="true"></span>
				                                </button>
				                            </div>
				                        </div>
				                    </div>                           
				                </div>
				            </form>
				        </div>
				    </div>
				</div>

				<AppHeader 
				    AppHeaderClass={AppHeaderClass} 
				    AppHeaderLeft={AppHeaderLeft} 
				    AppHeaderLeftLink={AppHeaderLeftLink} 
				    AppHeaderTitle={AppHeaderTitle} 
				    AppHeaderRight={AppHeaderRight} 
				    AppHeaderRightLink={AppHeaderRightLink} 
				/>

				<ModalAlert modalType={this.state.modalType} Notifications={this.state.Notifications} type={this.state.notifType} modalTitle={this.state.modalTitle} />
			</>
		)
	}
}

export default ResetPassword;