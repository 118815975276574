import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import ModalAlert from './ModalAlert';
import AppBottomMenu from './AppBottomMenu';
import BookingCalendar from './BookingCalendar';
import Moment from 'react-moment';
import $ from "jquery";

class PaymentResponse extends Component {
    constructor(props) {
    	super(props);

        this.state = {
            LOADING: true,
            Notifications: false,
            modalType: 'ALERT', 
            notifType: 'error', 
            modalTitle: '',
            customerid: localStorage.getItem('userData'),
            bookingDet: [],
            bookingId: localStorage.getItem('bookingCode'),
            payStatus: localStorage.getItem('payStatus'),
        }

        // clear cart items
        localStorage.removeItem('cartData');
        localStorage.removeItem('cartDate');
        localStorage.removeItem('cartTime');
        localStorage.removeItem('COUPON_APPLIED');
        localStorage.removeItem('addrId');
        localStorage.removeItem('addrStr');
    }

   getBookingDetails() {
      const posturl = global.config.bURL+"getBooking.php";
      const postData = {
         method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({mode: 'VIEW2', bookingcode: this.state.bookingId, customer: this.state.customerid})
      }
      
      fetch(posturl, postData)
         .then(res => res.json())
         .then(result => {
            // console.log('results obtained', result);
      
            let notify = '';
            if(result.err!=='' && result.err!==undefined)
                notify = result.err;
      
            this.setState({ 
               LOADING: false,
               bookingDet: result.BOOKINGS
            });
      
            if(notify!=='') {
                window.$('#ModalAlert').modal('show');
            }
         })
         .catch(error => {
              this.setState({ Notifications: error.toString() });
              console.error('There was an error!', error);
          });
   }

    componentDidMount() {
        this.getBookingDetails();
    }

    render() {
        if(!localStorage.getItem('userData')) {
            return (<Redirect to={'/logout'} />)
        }

        if(!this.state.bookingDet) {
            return (<Redirect to={'/home'} />)
        }

        let AppHeaderClass = 'appHeader no-border bg-coach-header text-light pt-2 pb-2';
        let AppHeaderLeft = 'showLINK';
        let AppHeaderLeftLink = '/home';
        let AppHeaderTitle = <h5 className="text-upper lt-spc1 text-grey5b mb-0 pt-05 pb-05">Book an Appointment</h5>
        let AppHeaderRight = '';
        let AppHeaderRightLink = '';

        return(
        <>
            {this.state.LOADING && 
                <Loader />
            }

            <AppHeader 
                AppHeaderClass={AppHeaderClass} 
                AppHeaderLeft={AppHeaderLeft} 
                AppHeaderLeftLink={AppHeaderLeftLink} 
                AppHeaderTitle={AppHeaderTitle} 
                AppHeaderRight={AppHeaderRight} 
                AppHeaderRightLink={AppHeaderRightLink} 
            />

            <div id="appCapsule" className="pt-1">
               <div className="section pt-1 pb-1 mt-6 mb-2">
                  <div className="form-wizard-section appoint-process">
                     <Link to="#" className="button-item active">
                        <strong>
                           <ion-icon name="checkmark-outline"></ion-icon>
                        </strong>
                        <p className="pt-05 text-upper font-12 lt-spc05">Selection</p>
                     </Link>
                     <Link to="#" className="button-item active">
                        <strong>
                           <ion-icon name="checkmark-outline"></ion-icon>
                        </strong>
                        <p className="pt-05 text-upper font-12 lt-spc05">Choose Slot</p>
                     </Link>
                     <Link to="#" className="button-item active">
                        <strong>
                           <ion-icon name="checkmark-outline"></ion-icon>
                        </strong>
                        <p className="pt-05 text-upper font-12 lt-spc05">Confirmed</p>
                     </Link>
                  </div>
               </div>
               <div className="program-outer">
                  <div className="workout-types">
                     {this.state.bookingDet && this.state.bookingDet.length > 0 && this.state.payStatus === 'SUCCESS' ?
                        this.state.bookingDet.map((booking, i) => {
                           return(
                              <>
                                 <div className="image-cover">
                                    <ion-icon name="checkmark-outline"></ion-icon>
                                 </div>
                                 <div className="price-block mt-2" key={'rp-'+i}>
                                    <h4 className="text-upper font-12 ft-wt-400  lt-spc1 text-grey mb-0 pt-05 pb-1">Your booking is confirmed</h4>
                                    <p>{'#'+booking.BOOKING_CODE}</p>
                                    <div className="pay-date mb-0 text-white font-16 ft-wt-400 l-20 mb-0">
                                       <img src="assets/images/appointment-icon.png" alt="calendar icon" className="imaged w24 mr-2" />
                                       <p className="ft-wt-700 font-16 pb-0 mb-0 lt-spc05 text-grey">
                                          <Moment date={booking.DT_APPOINTMENT} format="DD MMM YYYY"/>
                                       </p>
                                    </div>
                                     <div className="pay-date mb-0 text-white font-16 ft-wt-400 l-20 mb-0">
                                       <img src="assets/images/time.png" alt="calendar icon" className="imaged w24 mr-2" />
                                       <p className="ft-wt-700 font-16 pb-0 mb-0 lt-spc05 text-grey">
                                          {booking.TIME_SLOT}
                                       </p>
                                    </div>
                                    {/*<div className="text-left font-14 ft-wt-200 lt-spc05 text-dgrey">Drift into sleep; reduce stress and anxiety with specialized program</div>*/}
                                 </div>
                              </>
                           )
                        })
                        :
                        <>
                           <div className="image-cover failure">
                              <ion-icon name="close-outline"></ion-icon>
                           </div>
                           <div className="price-block mt-2">
                              <h4 className="text-upper font-12 ft-wt-700  lt-spc1 text-grey mb-0 pt-05 pb-05">Oops! Something went wrong</h4>
                              <div className="text-left font-14 ft-wt-200 lt-spc05 text-dgrey">We could not place your booking due to some technical error, please come back and try again later.</div>
                           </div>
                        </>
                     }
                  </div>
               </div>
               <div className="section pt-1 pr-2 pl-2 pb-2 mr-5 ml-5">
                  <Link to={'/appointments'} className="btn w-100 rounded bt-bg-gold btn-block btn-lg mr-0 ml-0 text-upper font-14 lt-spc2 ft-wt-700 btn-ht50 box-shadowg">View Details</Link>
               </div>
               <div className="section pt-1 pr-2 pl-2 pb-3 mr-4 ml-4">
                  <Link to={'/category'} className="another-service text-center btn-block btn-lg mr-0 ml-0 text-upper font-12 lt-spc2 ft-wt-700">Book another service</Link>
               </div>
            </div>

            <AppBottomMenu activeClass="appointment" />
            <ModalAlert modalType={this.state.modalType} Notifications={this.state.Notifications} type={this.state.notifType} modalTitle={this.state.modalTitle} />
        </>
        )
    }
}

export default PaymentResponse;