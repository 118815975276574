import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import AppBottomMenu from './AppBottomMenu';
import { CompareDate, formatDate } from './Common';
import Moment from 'react-moment';
import ReactStars from "react-rating-stars-component";
import $ from "jquery";

class Appointments extends Component {
    constructor(props) {
    	super(props);

    	this.state = {
    		LOADING: true,
    		Notifications: false,
    		TODAY: formatDate(new Date()),
    		modalType: 'ALERT', 
    		notifType: 'error', 
    		modalTitle: '',
    		customerid: localStorage.getItem('userData'),
    		customerdet:  [],
    		bookings:[],
    		statusIcon: "",
    		history_url: "U",
    		history_text: "History",
    		statusMsg: ""
    	}

    	this.getUserDetails = this.getUserDetails.bind(this);
    	this.getCustomerBookings = this.getCustomerBookings.bind(this);
    	this._booking_history = this._booking_history.bind(this);
	}

	getStatus = (status) => {
		// console.log('booking status:', status);
		let icon = "";
		let msg = "";

		if(status=='P') {
			// pending
			icon = <ion-icon name="alert-circle-outline"></ion-icon>;
			msg = "Your booking request is pending for approval";
		}
		else if(status=='U') {
			// upcoming
			icon = <ion-icon name="calendar-outline"></ion-icon>;
			msg = "Thank you for booking with us! <br/>We await your visit.";
		}
		else if(status=='C') {
			// completed
			icon = <ion-icon name="checkmark-circle-outline"></ion-icon>;
			msg = "Your booking is complete, please contact us incase of any queries or grievances";
		}

		this.setState({
			statusIcon: icon,
			statusMsg: msg
		})

		if(msg!=='')
			window.$('#statusAlert').modal('show');
	}

	getUserDetails() {
		const posturl = global.config.bURL+"userDetails.php";
		const postData = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ mode: 'CUSTOMER', id: this.state.customerid })
		}

		fetch(posturl, postData)
		.then(res => res.json())
		.then(result => {
			// console.log('results obtained', result);

			let notify = '';
			if(result.err!=='' && result.err!==undefined)
				notify = result.err;

			this.setState({
				customerdet: result.user,
				LOADING: false
			})

			if(notify!=='')
				window.$('#ModalAlert').modal('show');
		})
		.catch(error => {
			this.setState({ Notifications: error.toString() });
			console.error('There was an error!', error);
		});
	}

	getCustomerBookings = (b_cond="and dtAppointment>='"+this.state.TODAY+"' order by dtAppointment, iBookingID desc, FIELD (cStatus,'A','P','C')") => {
		const posturl = global.config.bURL+"getBooking.php";
		const postData = {
			method: 'POST',
		    headers: { 'Content-Type': 'application/json' },
		    body: JSON.stringify({mode: 'CUSTOMER_BOOKING', customerid:this.state.customerid, b_cond:b_cond })
		}
		
		fetch(posturl, postData)
			.then(res => res.json())
			.then(result => {
				// console.log('results obtained', result);
		
				let notify = '';
				if(result.err!=='' && result.err!==undefined)
				    notify = result.err;
		
				this.setState({ 
					LOADING: false,
					bookings: result.BOOKINGS
				});
		
				if(notify!=='') {
		    		window.$('#ModalAlert').modal('show');
				}
			})
			.catch(error => {
		        this.setState({ Notifications: error.toString() });
		        console.error('There was an error!', error);
		    });
	}

	_booking_history = (h_url) => {
		this.setState({
			LOADING: true,
			history_text: (h_url == 'U')?'Upcoming':'History',
			history_url: (h_url == 'U')?'P':'U'
		}, ()=>{
			var b_cond = "";
			if(h_url == 'U')
				b_cond = "and dtAppointment<'"+this.state.TODAY+"' order by dtAppointment desc, iBookingID desc"; //FIELD (cStatus,'A','P','C')";
			else
				b_cond = "and dtAppointment>='"+this.state.TODAY+"' order by dtAppointment, iBookingID desc, FIELD (cStatus,'A','P','C')";

			this.getCustomerBookings(b_cond);
		})		
	}

	componentDidMount() {
	    this.getCustomerBookings();
	    this.getUserDetails();
	}

	render() {
		let AppHeaderClass = 'appHeader no-border';
		let AppHeaderLeft = '';
		let AppHeaderLeftLink = '/login';
		let AppHeaderTitle = 'Appointments';
		let AppHeaderIMAGE = '';
		let AppHeaderRight = 'showPROFILE';
		let AppHeaderRightLink = '';
		let AppHeaderRightPic = process.env.PUBLIC_URL+'/assets/images/unknown-b.jpg';
		let customerName = (this.state.customerdet)?this.state.customerdet.NAME:'Customer';

		return(
			<>
				{this.state.LOADING && 
	                <Loader />
	            }

	            {AppHeaderTitle != '' &&
	              <AppHeader 
	                  AppHeaderClass={AppHeaderClass} 
	                  AppHeaderLeft={AppHeaderLeft} 
	                  AppHeaderLeftLink={AppHeaderLeftLink} 
	                  AppHeaderTitle={AppHeaderTitle} 
	                  AppHeaderRight={AppHeaderRight} 
	                  AppHeaderRightLink={AppHeaderRightLink} 
	                  AppHeaderRightPic={AppHeaderRightPic} 
	                  customerName={customerName}
	              />
	            }

	            <div id="appCapsule" className="pt-0">
	            	<div className="section pt-1">
	            	   <ul className="customer-name listview image-listview media mt-5 mb-1 no-border">
	            	      <li>
	            	         <div className="item pr-0 pl-0 pt-3">
	            	            <div className="in">
	            	               <div>
	            	                  <h3 className="pb-0 mb-0 font-23 lt-spc05  ft-wt-600">{/*Hi, {customerName}*/}</h3>
	            	               </div>
	            	               <Link onClick={()=>this._booking_history(this.state.history_url)} className="text-gold" to={'#'}>
	            	               		{this.state.history_text}
	            	               	</Link>
	            	               	{/*<Link to={'/notifications'}>
	            	               		<img src="assets/images/bell-icon.png" alt="bell icon" />
	            	               	</Link>*/}
	            	            </div>
	            	         </div>
	            	      </li>
	            	   </ul>
	            	</div>

	            	<div className="section pr-2 pl-2 appoint-block appnt">
	            		{this.state.bookings && this.state.bookings.length > 0 ?
	            			this.state.bookings.map((booking, i) => {
	            				let msg_class = "btn btn-outline-secondary rounded text-upper btn-ssm mb-0 font-12";
	            				let msg_text = "Upcoming";
	            				let completedClass = "text-white ft-wt-600 font-12 lt-spc05";
	            				let completed2 = "text-white text-right pr-0";
	            				let liCompleted = "";
	            				let booking_status = "U";
	            				let bookingfeedback = (booking.FEEDBACK)?booking.FEEDBACK:[];
	            				{/*upcoming,closed,cancelled,completed*/}
	            				if(formatDate(booking.DT_APPOINTMENT) < this.state.TODAY && booking.STATUS!='C') {
	            					msg_class = "btn btn-outline-elapsed rounded text-upper btn-ssm mb-0 font-12";
	            					msg_text = "Elapsed";
	            					booking_status = "X";
	            				}
	            				else if(booking.STATUS=='P') {
	            					msg_class = "btn btn-outline-danger rounded text-upper btn-ssm mb-0 font-12";
	            					msg_text = "Pending";
	            					booking_status = "P";
	            				}
	            				else if(formatDate(booking.DT_APPOINTMENT) < this.state.TODAY && booking.STATUS=='C') {
	            					msg_class = "btn text-gold app-completed rounded text-upper btn-ssm mb-0 font-12";
	            					msg_text = <div className="check-btn"><ion-icon name="checkmark-outline"></ion-icon></div>
	            					completedClass = "text-white ft-wt-600 font-12 lt-spc05 text-gold";
	            					completed2 = "text-white text-right pr-0 text-gold";
	            					liCompleted = "Completed";
	            					booking_status = "C";
	            				}
	            				else if(this.state.TODAY > formatDate(booking.DT_APPOINTMENT) && booking.STATUS=='A') {
	            					msg_class = "btn btn-outline-secondary rounded text-upper btn-ssm mb-0 font-12";
	            					msg_text = "Upcoming";
	            					booking_status = "U";
	            				}
	            				
	            				let msg_str = <span className={msg_class}>{msg_text}</span>
	            				let f_link = "#";
	            				if(booking_status == 'C') f_link = "/feedback/"+booking.BOOKING_ID_ENC;
	            				
	            				return(
		            					<div className="card bg-dlyellow mb-3 bg-white" key={'booking-'+i}>
	            					{/*<Link to={f_link} key={'booking-'+i}>*/}
		            					    <div className="card-header">
		            					         <ul className="listview image-listview media mb-0 no-border bg-transparent">
		            					            <li>
		            					             <div className="item">
	            					                  	{'#'+booking.BOOKING_CODE}
	            					                  </div>
		            					            </li>
		            					         </ul>
		            					    </div>
		            					    <div className="card-header">
		            					         <ul className="listview image-listview media mb-0 no-border bg-transparent">
		            					            <li className={liCompleted}>
		            					             <div className="item pt-0 pb-0 pl-0">
	            					                  	<div className="in">
	            					                     	<div className={completedClass}>
	            					                        	<Moment date={booking.DT_APPOINTMENT} format="DD MMM YYYY"/>, 
	            					                        	<span className="ft-wt-400">{(booking.TIME_SLOT && booking.TIME_SLOT!='')?' '+booking.TIME_SLOT:''}</span>
	            					                     	</div>
	            					                     	{msg_str}
	            					                  	</div>
	            					                  </div>
		            					            </li>
		            					         </ul>
		            					    </div>
		            					    <div className="card-body pt-1 pb-1">
		            					        <ul className="listview list-internal image-listview media mb-0 no-border bg-transparent">
		            					        	{booking.DAT && booking.DAT.length > 0 &&
		            					        		booking.DAT.map((booking_dat, i)=>{
		            					        			return(
			            					        			<li key={'bdat-'+i}>
			            					        			   <div className="item pt-0 pb-0 pl-0">
			            					        			      <div className="in">
			            					        			         <div className="text-white1 l-17 ft-wt-400 font-13">
			            					        			            {booking_dat.SERVICE_NAME}
																			&nbsp;&nbsp;
			            					        			         </div>
			            					        			         <div className="text-white text-right pr-0 ft-wt-400 font-13">
			            					        			         	<span>Qty: {booking_dat.QTY} | 
			            					        			            <span className="arial">&nbsp; ₹</span> {booking_dat.DISP_RATE} </span>
			            					        			         </div>
			            					        			        {/* <div className="text-lightred ft-wt-600 font-16 w-35a">
			            					        			            
			            					        			         </div>*/}
			            					        			      </div>
			            					        			   </div>
			            					        			</li>	
			            					        		)
		            					        		})
		            					        	}
		            					        </ul>
		            					    </div>
		            					     <div className="card-header card-footer">
		            					         <ul className="listview image-listview media mb-0 no-border bg-transparent">
		            					            <li className="pt-05">
		            					               <div className="item pt-0 pb-0 pl-0">
		            					                  <div className="in ft-wt-600">
		            					                     <div className="text-white1 ft-wt-400 font-14 lt-spc1"><span>TOTAL</span></div>
		            					                     <div className={completed2}>
		            					                     	<span className="arial"> ₹</span> {booking.BOOKING_DISP_VALUE} 
		            					                     </div>
		            					                  </div>
		            					               </div>
		            					            </li>
													{booking.TAX>0 &&
														<li className="pt-05">
															<div className="item pt-0 pb-0 pl-0">
																<div className="in ft-wt-600">
																	<div className="text-white1 ft-wt-400 font-14 lt-spc1">
																		<span>Tax</span>
																	</div>
																	<div className={completed2}>
																		<span className="arial"> ₹</span> {booking.TAX} 
																	</div>
																</div>
															</div>
														</li>
												 	}
													{booking.COUPAN_CODE!=='' && booking.COUPAN_AMT>0 && 
														<li className="pt-05">
															<div className="item pt-0 pb-0 pl-0">
																<div className="in ft-wt-600">
																	<div className="text-white1 ft-wt-400 font-14 lt-spc1">
																		<span>{booking.COUPON_DETAILS}</span>
																	</div>
																	<div className={completed2}>
																		<span className="arial"> - ₹</span> {booking.COUPAN_AMT} 
																	</div>
																</div>
															</div>
														</li>
												 	}
		            					            <li className="pt-05">
		            					               <div className="item pt-0 pb-0 pl-0">
		            					                  <div className="in ft-wt-600">
		            					                     <div className="text-white1 ft-wt-400 font-14 lt-spc1"><span>GRAND TOTAL</span></div>
		            					                     <div className={completed2}>
		            					                     	<span className="arial"> ₹</span> {booking.PAYABLE} 
		            					                     </div>
		            					                  </div>
		            					               </div>
		            					            </li>
													<li className="pt-05">
		            					               <div className="item pt-0 pb-0 pl-0">
		            					                  <div className="in ft-wt-600">
		            					                     <div className="text-white1 ft-wt-400 font-14 lt-spc1">
																 {f_link!=='' && f_link!=='#' && <Link to={f_link}><span className="btn btn-outline-danger rounded text-upper btn-ssm mb-0 font-12">Feedback</span></Link>}
															 </div>
		            					                     <div className="text-white1 ft-wt-400 font-14 lt-spc1">
		            					                     	<Link to={`/Reorder/${booking.BOOKING_ID_ENC}`}><span className="btn btn-outline-secondary rounded text-upper btn-ssm mb-0 font-12">Re-Order</span></Link>
		            					                     </div>
		            					                  </div>
		            					               </div>
		            					            </li>
		            					         </ul>
		            					    </div>

		            					    {booking_status == "C" &&
		            					    	<div className="card-header card-footer appointment-footer">
			            					    	<ReactStars
			            					    		classNames=""
			            					    	    count={5}
			            					    	    value={(bookingfeedback.RATING)?bookingfeedback.RATING:0}
			            					    	    edit={false}
			            					    	    onChange={false}
			            					    	    size={24}
			            					    	    isHalf={false}
			            					    	    emptyIcon={<i className="far fa-star"></i>}
			            					    	    halfIcon={<i className="fa fa-star-half-alt"></i>}
			            					    	    fullIcon={<i className="fa fa-star"></i>}
			            					    	    activeColor="#b9a12f"
			            					    	    color="#fff"
			            					    	/>
			            					    	<p>{(bookingfeedback.FEEDBACK)?bookingfeedback.FEEDBACK:""}</p>
			            					    </div>
		            					    }
		            				{/*</Link>*/}
									</div>
	            				)		
	            			})
	            			:
            				<div className="item pt-1 pb-3">                  
            					<div className="in">
            						<div className="set1 close-service title-cut-sub text-grey4 font-15 ft-wt-500 lt-spc05 pb-05">No appointments yet</div>
            					</div>
            				</div>
	            		}
	            	</div>
	            	<div className="section pr-2 pl-2 pb-3">
	            	   <Link to="/category" className="btn w-100 rounded bt-bg-gold btn-block btn-lg mr-0 ml-0 text-upper font-14 lt-spc2 ft-wt-600 btn-ht50 box-shadowg">Book an Appointment</Link>
	            	</div>
	            </div>

	            <div className="modal fade dialogbox overlay-back" id="statusAlert" data-backdrop="static" tabIndex="-1" role="dialog">
	                <div className="modal-dialog" role="document">
	                    <div className="modal-content">
                            <div className="modal-icon text-gold mb-2">{this.state.statusIcon}</div>

	                        <div className="modal-body"> 
	                                <p className="pb-0 mb-0" dangerouslySetInnerHTML={{ __html: this.state.statusMsg}} />
	                        </div>
	                        <div className="modal-footer">
	                            <div className="btn-inline"> 
	                                <button type="button" className="btn" data-dismiss="modal">OK</button>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	            </div>

                <AppBottomMenu activeClass="appointment" />
            </>
		)
	}
}

export default Appointments;