import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import Loader from './Loader';
import ModalAlert from './ModalAlert';
import AppBottomMenu from './AppBottomMenu';
import AppHeader from './AppHeader';
import HomeSubCatSlider from './HomeSubCatSlider';
import HomeOfferSlider from './HomeOfferSlider';
import HomeTopSlider from './HomeTopSlider';
import BookingStrip2 from './BookingStrip2';
import SearchBar from './SearchBar';
import Moment from 'react-moment';
import $ from "jquery";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
		   LOADING: true,
		   Logged: false,
		   Notifications: "",
		   modalType:'ALERT', 
		   notifType:'error', 
		   modalTitle:'',
		   serviceCat: [],
		   serviceSubCat: [],
		   services: [],
		   searchService: "",
		   customerid: localStorage.getItem('userData'),
		   customerdet: [],
		   upcomingBooking: [],
		   offersdet: [],
		   offerdet2: [],
		   offercat: [],
		   maincat:[],
		   mainCatId: (localStorage.getItem('maincatid'))?localStorage.getItem('maincatid'):1,
		   redirectCat: 'N',
		   sliderdet: []
	   };

		this.getMainCat = this.getMainCat.bind(this);
		this.getSubCat = this.getSubCat.bind(this);
		// this.getFeaturedService = this.getFeaturedService.bind(this);
		this.getCustomerBookings = this.getCustomerBookings.bind(this);
		this.getUserDetails = this.getUserDetails.bind(this);
		this.getOffers = this.getOffers.bind(this);
		this.getSlider = this.getSlider.bind(this);
		localStorage.setItem('maincatid', this.state.mainCatId);
	  // localStorage.removeItem("cartData"); // clearing the cart;;
	}

	getCustomerBookings() {
	   const posturl = global.config.bURL+"getBooking.php";
	   const postData = {
		  method: 'POST',
		   headers: { 'Content-Type': 'application/json' },
		   body: JSON.stringify({mode: 'CUSTOMER_BOOKING', customerid:this.state.customerid })
	   }
	   
	   fetch(posturl, postData)
		  .then(res => res.json())
		  .then(result => {
			 // console.log('results obtained', result);
	   
			 let notify = '';
			 if(result.err!=='' && result.err!==undefined)
				 notify = result.err;
	   
			 this.setState({ 
				upcomingBooking: (result.BOOKINGS)?result.BOOKINGS[0]:[]
			 });
	   
			 if(notify!=='') {
				window.$('#ModalAlert').modal('show');
			 }
		  })
		  .catch(error => {
			   this.setState({ Notifications: error.toString() });
			   console.error('There was an error!', error);
		   });
	}

	getUserDetails() {
		const posturl = global.config.bURL+"userDetails.php";
		const postData = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ mode: 'CUSTOMER', id: this.state.customerid })
		}

		fetch(posturl, postData)
			.then(res => res.json())
			.then(result => {
				// console.log('results obtained', result);

				let notify = '';
				if(result.err!=='' && result.err!==undefined)
					notify = result.err;

				this.setState({
					customerdet: result.user,
				})
				
				if(notify!=='')
					window.$('#ModalAlert').modal('show');
			})
			.catch(error => {
				this.setState({ Notifications: error.toString() });
				console.error('There was an error!', error);
			});
	}

	getMainCat() {
		const posturl = global.config.bURL+"serviceCategory.php";
		const postData = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ mode: 'SERVICE_CATEGORY', type: "parent", cond: "" })
		}

		fetch(posturl, postData)
			.then(res => res.json())
			.then(result => {
				// console.log('results obtained', result);

				let notify = '';
				if(result.err!=='' && result.err!==undefined)
					notify = result.err;

				this.setState({
					serviceCat: result.category,
			   maincat: result.types,
				})
				
				if(notify!=='')
					window.$('#ModalAlert').modal('show');
			})
			.catch(error => {
				this.setState({ Notifications: error.toString() });
				console.error('There was an error!', error);
			});
	}

	getSubCat() {
		const posturl = global.config.bURL+"serviceCategory.php";
		const postData = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ mode: 'SERVICE_CATEGORY', type: "subcat", cond: "" })
		}

		fetch(posturl, postData)
			.then(res => res.json())
			.then(result => {
				// console.log('results obtained', result);

				let notify = '';
				if(result.err!=='' && result.err!==undefined)
					notify = result.err;

				this.setState({
					serviceSubCat: result.category2,
					LOADING: false
				})
				
				if(notify!=='')
					window.$('#ModalAlert').modal('show');
			})
			.catch(error => {
				this.setState({ Notifications: error.toString() });
				console.error('There was an error!', error);
			});
	}

	getOffers() {
		const posturl = global.config.bURL+"offers.php";
		const postData = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({mode: 'OFFERS', customerid:this.state.customerid})
		}
		
		fetch(posturl, postData)
			.then(res => res.json())
			.then(result => {
				// console.log('results obtained', result);
		
				let notify = '';
				if(result.err!=='' && result.err!==undefined)
					notify = result.err;
		
				this.setState({ 
					offersdet: result.offers,
				});
		
				if(notify!=='') {
					window.$('#ModalAlert').modal('show');
				}
			})
			.catch(error => {
				this.setState({ Notifications: error.toString() });
				console.error('There was an error!', error);
			});
	}

	getOffers2 = (offerid) => {
		const posturl = global.config.bURL+"offers.php";
		const postData = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({mode: 'OFFERS', customerid:this.state.customerid, offerid: offerid})
		}
		// alert();
		fetch(posturl, postData)
			.then(res => res.json())
			.then(result => {
				// console.log('results obtained', result);
		
				let notify = '';
				if(result.err!=='' && result.err!==undefined)
					notify = result.err;
		
				this.setState({ 
					offerdet2: result.offers,
					offercat: result.offer_cat
				}, ()=>{
					window.$('#offerModal').modal('show');
					localStorage.setItem('maincatid', 1);
				} );
		
				if(notify!=='') {
					window.$('#ModalAlert').modal('show');
				}
			})
			.catch(error => {
				this.setState({ Notifications: error.toString() });
				console.error('There was an error!', error);
			});
	}

	getSlider() {
		const posturl = global.config.bURL+"slider.php";
		const postData = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({mode: 'SLIDER', customerid:this.state.customerid})
		}
		
		fetch(posturl, postData)
			.then(res => res.json())
			.then(result => {
				// console.log('results obtained', result);
		
				let notify = '';
				if(result.err!=='' && result.err!==undefined)
					notify = result.err;
		
				this.setState({ 
					sliderdet: result.slider,
				});
		
				if(notify!=='') {
					window.$('#ModalAlert').modal('show');
				}
			})
			.catch(error => {
				this.setState({ Notifications: error.toString() });
				console.error('There was an error!', error);
			});    
	}

	redirectCat = (main_id) => {
	  if(main_id!='' && main_id!=undefined)
	  {
		 localStorage.setItem('maincatid', main_id);
		 this.setState({
			redirectCat: 'Y'
		 })
	  }
	}

   /* getFeaturedService() {
		const posturl = global.config.bURL+"services.php";
		const postData = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ mode: 'SERVICES', type: "featured", cond: "" })
		}
		
		fetch(posturl, postData)
			.then(res => res.json())
			.then(result => {
				// console.log('results obtained', result);
		
				let notify = '';
				if(result.err!=='' && result.err!==undefined)
					notify = result.err;

				this.setState({
					services: result.services
				})

				if(notify!=='')
					window.$('#ModalAlert').modal('show');
			})
			.catch(error => {
				this.setState({ Notifications: error.toString() });
				console.error('There was an error!', error);
			});
	}*/

	_offerPop = (offerid) => {
      // console.log('offerid:', offerid);
      if(offerid)
      {
      	this.getOffers2(offerid);
      }
    }

	componentDidMount() {
		this.getMainCat(); // main category
		this.getSubCat(); // main category
		// this.getFeaturedService(); // featured services
		this.getUserDetails(); // featured services
		this.getCustomerBookings(); // featured services
		this.getOffers(); // featured services
		this.getSlider();
	}

	componentWillMount() {
		let mount = $("div").hasClass("modal-backdrop");
		if (mount == true) {
			$('body').removeClass('modal-open');
			$(".modal-backdrop").remove();
		}
	}

	render() {
		/*if(!localStorage.getItem('userData')) {
			return (<Redirect to={'/logout'}/>)
		}*/

	  if(this.state.redirectCat == 'Y') {
		 return (<Redirect to={'/category'}/>)
	  }

	   let AppHeaderClass = 'appHeader no-border';
		let AppHeaderLeft = '';
		let AppHeaderLeftLink = '/login';
		let AppHeaderTitle = 'Home';
		let AppHeaderIMAGE = '';
		let AppHeaderRight = 'showPROFILE';
		let AppHeaderRightLink = '';
		let AppHeaderRightPic = process.env.PUBLIC_URL+'/assets/images/unknown-b.jpg';
		let customerName = (this.state.customerdet)?this.state.customerdet.NAME:'Customer';

		const off_options = {
		    items: 2,
		    nav: false,
		    loop: false,
		    dots: false,
		    // autoplay: true,
		    stagePadding: 30
		};

		return(
			<>
				{this.state.LOADING && 
					<Loader />
				}

			  {AppHeaderTitle != '' &&
				<AppHeader 
					AppHeaderClass={AppHeaderClass} 
					AppHeaderLeft={AppHeaderLeft} 
					AppHeaderLeftLink={AppHeaderLeftLink} 
					AppHeaderTitle={AppHeaderTitle} 
					AppHeaderRight={AppHeaderRight} 
					AppHeaderRightLink={AppHeaderRightLink} 
					AppHeaderRightPic={AppHeaderRightPic} 
					customerName={customerName}
				/>
			  }
				{/* NEW HEADER SECTION : START */}
				
				{/*<div className="section full mt-2">
					<div className="appHeader no-border">
						<div className="left">
							<Link to="#" className="headerButton" data-toggle="modal" data-target="#sidebarPanel"><ion-icon name="menu-outline"></ion-icon></Link>
						</div>

						<div className="pageTitle customer-name">
							<h5 className="text-upper lt-spc1 text-grey5b mb-0 pt-05 pb-05">Home</h5>
						</div>
						<div className="right">
							<Link to={'/profile'} className="headerButton">
							   <img className="imaged w48 rounded" src={customerPic} alt="image" />
							</Link>
						</div>
					</div>
				</div>*/}
				{/* NEW HEADER SECTION : END */}

				<div id="appCapsule" className="pt-0">
				   <div className="mt-6 pt-1 bg-layout">
					  <ul className="customer-name listview image-listview media mb-1 no-border">
						 <li>
							<div className="item pr-2 pl-2 pt-3">
							   <div className="in">
								  <div className="inext">
									 <h3 className="pb-1 mb-0 font-20 lt-spc05  ft-wt-600">Hi, {customerName}</h3>
									 <div className="section service-selection pt-2 pb-0 pr-0 pl-0 mt-0 mb-0 item-tab">
									  <div className="pl-0 pr-0">
										 <div className="category-list wide-block wide-block2 pb-0 mb-0 pr-0 pl-0">
											<div className="text-upper pb-0" style={{display: "flex"}}>
											   {this.state.maincat && this.state.maincat.length > 0 &&
												  this.state.maincat.map((main, i)=>{
													 
													 let tabUrl = main.URLNAME;
													 let activeClass = "service-name";

													 if(main.ID == this.state.mainCatId)
														activeClass = "service-name active";

													 return(
														<Link className={activeClass} to="#" onClick={()=>this.redirectCat(main.ID)} id={main.ID} key={i}>
														   <div dangerouslySetInnerHTML={{__html: main.ICON}} />
														   <p className="ft-wt-400 mb-0 font-13">{main.NAME}</p>
														</Link>
													 )
												  })
											   }
											</div>
										 </div>
									  </div>
								   </div>
								  </div>
								  {/*<Link to="/notifications">
									<img src="assets/images/bell-icon.png" alt="bell icon" />
								  </Link>*/}
							   </div>
							</div>
						 </li>
					  </ul>
				   </div>

			   {/*this.state.upcomingBooking &&
				   <div className="section calendar-view pt-0">
					  <ul className="listview image-listview media mb-1 bd-rad6 no-border pl-2 pr-2">
						 <li>
							<Link to={'/appointments'} className="item pr-0 pl-0">
							   <div className="in">
								  <div className="text-grey font-14 ft-wt-400 l-17">
									 <img src="assets/images/calendar-icon.png" alt="calendar icon" />
									 You have an upcoming appointment
								  </div>
								  <button type="button" className="btn bt-bg-brown btn-ssm mb-0 text-upper ft-wt-400 font-10 l-20 lt-spc05">
									{this.state.upcomingBooking.TIME_SLOT}<br/><span className="ft-wt-600"><Moment date={this.state.upcomingBooking.DT_APPOINTMENT} format="DD MMM YYYY"/></span>
								  </button>
							   </div>
							</Link>
						 </li>
					  </ul>
				   </div>
			   */}

			   <SearchBar />

			   {/* OFFER SLIDER : START */}
			   {/*this.state.sliderdet && this.state.sliderdet.length !==0 &&
					<div className="full-slider section full mt-2 mb-3">
						<HomeTopSlider slider={this.state.sliderdet} />
					</div>
				*/}
				{this.state.offersdet && this.state.offersdet.length !==0 &&
					<div className="item-list section full mb-1 pb-6">
						<div className="section-title ft-wt-600 section-head font-size-21 pt-2 mb-05 pr-2 pl-2 text-sdgrey">Offers
					  		{/*<Link to={'/offers'} className="badge badge-transparent ft-wt-500 lt-spc1 text-upper font-11 ht-20 text-grey6">View All</Link> */}
						</div>
						<div className="full-img service-slider carousel-multiple pb-0">
						{/*<HomeOfferSlider offers={this.state.offersdet} />*/}
						{this.state.offersdet && this.state.offersdet.length !==0 &&
							<OwlCarousel className="owl-theme" loop margin={15} {...off_options}>
							{this.state.offersdet && 
								this.state.offersdet.map((offer, i) => {
							        let s_url = '/offerdetails/'+offer.ID;
							        let image_str = (offer.PIC)?offer.PIC:process.env.PUBLIC_URL+"/assets/images/hair-img.jpg";
							        return (
							                <Link className="pb-2" to={'#'} key={i} onClick={()=>this._offerPop(offer.ID)}>
							                   <div className
							                   ="item">
							                      <div className="card mb-0">
							                         <div className="slider-cover slider-offer">
							                            <img src={image_str} className="card-img-top imaged w-100" alt={offer.NAME} />
							                         </div>
							                         <div className="offer-body card-body pt-07 pb-07 pr-06 pl-06">
							                            <p className="card-text text-upper lt-spc05 font-12 ft-wt-600 mb-0">{offer.NAME}</p>
							                         </div>
							                      </div>
							                   </div>
							                </Link>
							        )
							    })
							}
							</OwlCarousel>
						}
				  		</div>
					</div>
				}
			<div className="spec-offer calendar-modal dialogbox fade" id="offerModal" tabIndex="-1" role="dialog">
			   <div className="modal-dialog" role="document">
			      <div className="modal-content">
			        <div className="modal-header pageTitle">
			        	<Link to="#" data-dismiss="modal" className="font-16 text-black ft-wt-700">x</Link>
			        </div>
			        <div className="modal-body pt-0">
				        <div className="section full">
				            <div className="">
				            	<img src={(this.state.offerdet2 && this.state.offerdet2.length>0)?this.state.offerdet2[0].PIC:''} width="100%" alt="service banner image" />
				            </div>
				        </div>

				        <div className="offers-details service-mod section-curve service-list">
				           	<ul className="listview image-listview media no-bborder pb-0 mb-0 no-border">
				               <li>
				                  <div className="item pt-2 pb-1 pl-0">
				                     <div className="in">
				                        <div className="text-gre5 ft-wt-600 font-16 w-65 text-upper text-left">
				                           {(this.state.offerdet2 && this.state.offerdet2.length>0)?this.state.offerdet2[0].NAME:''}
				                        </div>
				                        <div className="text-lightred ft-wt-600 font-13 w-40 text-right">
				                           {(this.state.offerdet2 && this.state.offerdet2.length>0)?this.state.offerdet2[0].TYPE:''}
				                        </div>
				                     </div>
				                  </div>
				               </li>
				           	</ul>

				           	<p className="font-13 pb-05 mb-05">{(this.state.offerdet2 && this.state.offerdet2.length>0)?this.state.offerdet2[0].FULL_DESC:''}</p>
				          {/* 	{this.state.offerdet2 && this.state.offerdet2.length>0 &&
				             <p className="font-13 text-yellow"><span  className="ft-wt-600">Validity:</span> {this.state.offerdet2[0].FROM_DATE}- {this.state.offerdet2[0].TO_DATE}</p>
				           	}*/}

							{this.state.offercat.length > 0 &&
								<div className="service-chip">
									<p className="font-13 text-yellow text-upper ft-wt-600 pt-2">Book now</p>
									<div className="text-upper pb-2">
										{console.log(this.state.offercat.length)}
									{this.state.offercat[0].map((cat, i) => {
										return(
											<Link to={'/services/'+cat.ID} key={i} className="btn btn-outline-mix rounded newshadowed btn-lsm mr-1 ml-1 mb-1">{cat.NAME}</Link>       
										)
										})
									}
									</div>
								</div>
							}
				        </div>
			        </div>
			      </div>
			   </div>
			</div>				
			   {/*<div className="section full mt-2 mb-3">
				   <div className="carousel-full owl-carousel owl-theme">
					   <div className="item">
						   <img src="../assets/images/hair-img.jpg" alt="alt" className="imaged w-100 square"/>
					   </div>
					   <div className="item">
						   <img src="../assets/images/hair-img.jpg" alt="alt" className="imaged w-100 square"/>
					   </div>
					   <div className="item">
						   <img src="../assets/images/hair-img.jpg" alt="alt" className="imaged w-100 square"/>
					   </div>
				   </div>
			   </div>*/}
			  {/* OFFER SLIDER SECTION : END */}


				   <div className="section pt-2 pb-0 pr-0 pl-0 mb-0 item-tab">
					  <div className="section-title ft-wt-600 section-head font-size-21 pt-2 mb-05 pr-2 pl-2 text-sdgrey">Salon Services
					  {/*<span className="badge badge-transparent ft-wt-500 lt-spc1 text-upper font-10 ht-20">View All</span>*/} </div>
					 <div className="pl-2 pr-2">
						<div className="category-list wide-block wide-block2 pb-0 mb-0 pr-0 pl-0">
							 <div className="text-upper space-btw pb-0" style={{display: "flex"}}>
								{this.state.serviceCat && this.state.serviceCat.length !==0 &&
									this.state.serviceCat.map((category, i) => {
										let c_url = '/category/'+category.ID+'/1';
										return(
											<Link className="btn btn-outline-mix curved btn-lsm mr-1 newshadowed" to={c_url} key={i}>
											   <div className="cat-image">
												  <img src={category.ICON} alt={category.NAME} />
											   </div>
											   <p className="ft-wt-500 mb-0">{category.NAME}</p>
											</Link>
										)
									})
								}
							 </div>
						</div>
					  </div>
				   </div>
				   <div className="section pt-2 pb-0 pr-0 pl-0 mb-0 item-tab">
					  <div className="section-title ft-wt-600 section-head font-size-21 pt-2 mb-05 pr-2 pl-2 text-sdgrey">Home Services
					  {/*<span className="badge badge-transparent ft-wt-500 lt-spc1 text-upper font-10 ht-20">View All</span>*/} </div>
					 <div className="pl-2 pr-2">
						<div className="category-list wide-block wide-block2 pb-0 mb-0 pr-0 pl-0">
							 <div className="text-upper space-btw pb-0" style={{display: "flex"}}>
								{this.state.serviceCat && this.state.serviceCat.length !==0 &&
									this.state.serviceCat.map((category, i) => {
										let c_url = '/category/'+category.ID+'/2';
										return(
											<Link className="btn btn-outline-mix curved btn-lsm mr-1 newshadowed" to={c_url} key={i}>
											   <div className="cat-image">
												  <img src={category.ICON1} alt={category.NAME} />
											   </div>
											   <p className="ft-wt-500 mb-0">{category.NAME}</p>
											</Link>
										)
									})
								}
							 </div>
						</div>
					  </div>
				   </div>

				   <div className="item-list section full mb-3">
					  <div className="section-title  ft-wt-600 section-head font-size-21 pt-2 mb-05 pr-2 pl-2 text-sdgrey">Amanora Exclusives
						<Link to={'/category'} className="badge badge-transparent text-grey6 ft-wt-500 lt-spc1 text-upper font-10 ht-20">View All</Link> 
					  </div>
					  <div className="slider-block pb-6">
					 {this.state.serviceSubCat && this.state.serviceSubCat.length !==0 &&
							<HomeSubCatSlider serviceSubCat={this.state.serviceSubCat} type_id={this.state.mainCatId} />
						}
				  </div>
				   </div>

				   	{/*this.state.offersdet && this.state.offersdet.length !==0 &&
						<div className="item-list section full mb-3 pb-6">
							<div className="section-title ft-wt-600 section-head font-size-21 pt-2 mb-05 pr-2 pl-2 text-sdgrey">Offers
						  		<Link to={'/offers'} className="badge badge-transparent ft-wt-500 lt-spc1 text-upper font-11 ht-20 text-grey6">View All</Link> 
							</div>
							<div className="service-slider carousel-multiple pb-7">
							{this.state.offersdet && this.state.offersdet.length !==0 &&
								<HomeOfferSlider offers={this.state.offersdet} />
							}
					  		</div>
						</div>
					*/}


				</div>

				<AppBottomMenu activeClass="home" />

			{this.state.Notifications!='' &&
				  <ModalAlert modalType={this.state.modalType} Notifications={this.state.Notifications} type={this.state.notifType} modalTitle={this.state.modalTitle} />
			}

			<BookingStrip2 />
			</>
			);
	}
}
export default Home;