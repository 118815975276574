import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import AppBottomMenu from './AppBottomMenu';
import ModalAlert from './ModalAlert';
import Moment from 'react-moment';
import $ from "jquery";

class EditProfile extends Component {
    constructor(props) {
    	super(props);

        this.state = {
            LOADING: true,
            Notifications: false,
            modalType: 'ALERT', 
            notifType: 'error', 
            modalTitle: '',
            customerid: localStorage.getItem('userData'),
            customerdet: [],
            UPDATED: "N"
        }

        this.getUserDetails = this.getUserDetails.bind(this);
    }

    getUserDetails() {
        const posturl = global.config.bURL+"userDetails.php";
        const postData = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'CUSTOMER', id: this.state.customerid })
        }

        fetch(posturl, postData)
            .then(res => res.json())
            .then(result => {
                // console.log('results obtained', result);

                let notify = '';
                if(result.err!=='' && result.err!==undefined)
                    notify = result.err;

                this.setState({
                    customerdet: result.user,
                    LOADING: false
                })
                
                if(notify!=='')
                    window.$('#ModalAlert').modal('show');
            })
            .catch(error => {
                this.setState({ Notifications: error.toString() });
                console.error('There was an error!', error);
            });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let err = 0;
        if ($('#frmUpdate')[0].checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            err++;
        }
        $('#frmUpdate')[0].classList.add('was-validated');

        if(err===0)
        {
            $('#submitLoading').css('display','block');

            const method = "POST";
            const body = new FormData(this.form);
            fetch(global.config.bURL+'customerUpdate.php', { method, body })
            .then(function (response) {
              return response.json();
          })
            .then(result => {
              let notify = '';
              let notifyType = "success";

              if(result.err!=='' && result.err!==undefined) {
                notify = result.err;
                notifyType = "error";
              }

              if(result.UPDATED=='Y') {
                 notify = "Updated Successfully";
              }

            let customer = result.customer;
            if(customer!=='' && customer!==undefined)
            {
                localStorage.setItem('userData',customer);
            }

            this.setState({ 
                notifType:notifyType,
                Notifications: notify,
                UPDATED: result.UPDATED
            });
            $('#submitLoading').css('display','none');
            if(notify!=='')
                window.$('#ModalAlert').modal('show');
        });
        }
    }

    componentDidMount() {
        this.getUserDetails();   
    }

    render() {
        if(!localStorage.getItem('userData')) {
            return (<Redirect to={'/logout'} />)
        }

        let AppHeaderClass = 'appHeader no-border bg-coach-header text-light pt-2 pb-2';
        let AppHeaderLeft = 'showLINK';
        let AppHeaderLeftLink = '/profile';
        let AppHeaderTitle = <h5 className="text-upper lt-spc1 text-grey5b mb-0 pt-05 pb-05">Edit Profile</h5>
        let AppHeaderRight = '';
        let AppHeaderRightLink = '';
        

        return(
            <>
                {this.state.LOADING && 
                    <Loader />
                }

                <AppHeader 
                    AppHeaderClass={AppHeaderClass} 
                    AppHeaderLeft={AppHeaderLeft} 
                    AppHeaderLeftLink={AppHeaderLeftLink} 
                    AppHeaderTitle={AppHeaderTitle} 
                    AppHeaderRight={AppHeaderRight} 
                    AppHeaderRightLink={AppHeaderRightLink} 
                />

                <div id="appCapsule">
                    <div className="section register full mt-0 mb-2 basic-form">
                        <div className="wide-block no-border mt-1 pb-1 pt-2 pr-3 pl-3">
                            <form id="frmUpdate" ref={el =>(this.form = el)} onSubmit={this.handleSubmit} className="needs-validation" noValidate autoComplete="off">
                                <input type="hidden" value="UPDATE" className="form-control" name="mode" id="mode"  />
                                <input type="hidden" value={localStorage.getItem('userData')} className="form-control" name="customerid" id="customerid"  />
                                <input type="hidden" defaultValue={localStorage.getItem('WEBPUSHR_ID')} className="form-control" name="push_id" id="push_id"  />

                                <div className="listview image-listview no-line no-space flush mr-2 ml-2 pb-4">              
                                    <div className="form-group basic">
                                        <div className="input-wrapper guest-detail float-label pt-0">
                                            <label className="eform-label" htmlFor="txtname">Name</label>
                                            <input type="text" className="form-control" id="txtname" name="txtname" placeholder="Enter Name" required="required" defaultValue={this.state.customerdet.NAME} />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>

                                    <div className="form-group basic">
                                        <div className="input-wrapper guest-detail float-label">
                                            <label className="eform-label" htmlFor="txtemail">Email</label>
                                            <input type="email" className="form-control" id="txtemail" name="txtemail" placeholder="natasha@gmail.com" required="required" defaultValue={this.state.customerdet.EMAIL} readOnly />

                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>

                                    <div className="form-group basic">
                                        <div className="input-wrapper guest-detail float-label">
                                            <label className="eform-label" htmlFor="txtphone">Phone</label>
                                            <input type="text" className="form-control" id="txtphone" name="txtphone" placeholder="9898778768" required="required" minLength="10" maxLength="10" pattern="[0-9]{10}" defaultValue={this.state.customerdet.MOBILE} />
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>

                                    {this.state.customerdet && this.state.customerdet.GENDER &&
                                        <>
                                            <div className="form-group basic gender-label">
                                                <label className="eform-label float-label pb-1">Select gender</label>
                                                <div className="clear"></div>
                                                <div className="custom-control custom-radio mb-05 mr-1 divide-50l">
                                                    <input type="radio" className="custom-control-input" name="cmbgender" id="gende_M" defaultChecked={this.state.customerdet.GENDER==='M'} defaultValue="F" />
                                                    <label htmlFor="gende_M" className="custom-control-label">Male</label>
                                                </div>
                                                <div className="custom-control custom-radio mb-05 ml-1 divide-50l">
                                                    <input type="radio" className="custom-control-input" name="cmbgender" id="gende_F" defaultChecked={this.state.customerdet.GENDER==='F'} defaultValue="F" />
                                                    <label htmlFor="gende_F" className="custom-control-label">Female</label>
                                                </div>
                                                <div className="clear"></div>
                                            </div>
                                        </>
                                    }
                                    {/*<div className="form-group basic edit-address">
                                        <div className="input-wrapper guest-detail float-label pt-1">
                                            <label className="eform-label" htmlFor="txtaddress">Address</label>
                                            <textarea rows="4" className="form-control pr-0" id="txtaddress" name="txtaddress" placeholder="Enter address" defaultValue={this.state.customerdet.ADDRESS}></textarea>
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>*/}

                                    <div className="form-group basic">
                                        <div className="input-wrapper">
                                            <div className="w-100 pt-3">
                                              <button type="submit" className="btn w-100 rounded bt-bg-gold btn-block btn-lg mr-0 ml-0 text-upper font-14 lt-spc2 ft-wt-600 btn-ht60 box-shadowg">Submit</button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </form>
                      </div>
                  </div>

                  <ModalAlert modalType={this.state.modalType} Notifications={this.state.Notifications} type={this.state.notifType} modalTitle={this.state.modalTitle} />
                </div>
            </>
        )
    }
}

export default EditProfile;