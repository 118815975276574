import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';

class BookingStrip2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalAmount: 0,
      totalAmount2: 0,
      minOrder: 0,
      redirectBooking: false,
      type: "error",
      Notifications: '',
      mainCatId: (localStorage.getItem('maincatid2'))?localStorage.getItem('maincatid2'):1,
    }

    this.getCartDetails = this.getCartDetails.bind(this);
  }

  getCartDetails() {
    this.setState({cartUpdated:'N'});
    // console.log('cart updatedddd:', this.state.cartUpdated);
    const posturl = global.config.bURL+"getCartDetails.php";
    const postData = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({mode: 'CART', mainid:this.state.mainCatId, service_det: localStorage.getItem('cartData') })
    }

    fetch(posturl, postData)
    .then(res => res.json())
    .then(result => {
      // console.log('results obtained', result);

      let notify = '';
      if(result.err!=='' && result.err!==undefined)
        notify = result.err;

      this.setState({ 
        selectedCartData: result.cart_id,
        totalAmount: result.cart_total,
        totalAmount2: result.cart_total2,
        cartUpdated: "Y",
        minOrder: result.MIN_ORDER
      });

      if(notify!=='') {
        window.$('#bookingStripModal').modal('show');
      }
    })
    .catch(error => {
      this.setState({ Notifications: error.toString() });
      console.error('There was an error!', error);
    });
  }

  validateMinOrder = () => {
    var tot = this.state.totalAmount2;
    var min = this.state.minOrder;

    if(tot < min) {
      this.setState({
        Notifications: "Minimum order value to be &#8377;"+min,
        redirectBooking: false
      })
      window.$('#bookingStripModal').modal('show');
    }
    else {
      this.setState({
        redirectBooking: true
      })
    }
  }

  componentDidMount() {
    this.getCartDetails();
  }

  render() {
    if(this.state.redirectBooking === true) {
      return(<Redirect to='/booking' />)
    }
    let selectedCount = (this.state.selectedCartData)?this.state.selectedCartData.length:0;

    if(selectedCount>0)
    {
      return(
        <>
        <div className="form-button-group2 bg-gold">
          <div className="col-12">
            <div className="section-title w-100 d-flex justify-content-between pt-1 pb-1 pr-0 pl-0 medium">
                <div className="w-50 text-left">
                    <p className="mb-0 text-upper ft-wt-500 text-white font-12">{selectedCount}{(selectedCount > 1)?" Treatments":" Treatment"}<br/>
                    <span className="ml-0 ft-wt-700 font-17 text-gre3">&#8377; {this.state.totalAmount}</span></p>
                </div>
                <div className="w-50 text-right">
                <p className="mb-0 text-upper">
                  <Link to="#" className="btn btn-coach-pink btn-checkout anchor rounded mr-0 bt-skip lt-spc1 p-0" onClick={()=>this.validateMinOrder()} >Book Now</Link>
                </p>
            </div>
            </div>
          </div>
        </div>

        <div className="modal fade dialogbox overlay-back" id="bookingStripModal" data-backdrop="static" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    {this.state.type=='error' &&
                        <div className="modal-icon text-danger mb-2">
                            <ion-icon name="close-circle"></ion-icon>
                        </div>
                    }

                    {this.state.type=='success' &&
                        <div className="modal-icon text-success mb-2">
                            <ion-icon name="checkmark-circle"></ion-icon>
                        </div>   
                    }

                    <div className="modal-body"> 
                        <p className="pb-0 mb-0" dangerouslySetInnerHTML={{ __html: this.state.Notifications}} />
                    </div>
                    <div className="modal-footer">
                        <div className="btn-inline"> 
                            <button type="button" className="btn" data-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
      )
    }
    return(
      <div>
      </div>
    )
  }
}

export default BookingStrip2;