import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import ModalAlert from './ModalAlert';
import AppBottomMenu from './AppBottomMenu';
import BookingCalendar from './BookingCalendar';
import Moment from 'react-moment';
import $ from "jquery";


const COUPON = (props) => { 
	let Coupons = props.coupons.map((post, i)=>{ 
		return ( 
			<div className="coupon-bx custom-control custom-radio" key={i}> 
				<div className="custom-control-label" htmlFor="customCheck4">
					<span className="coupon-code"> {post.CODE}</span>
					<h5 className="pt-1">{post.DESC}</h5>
				</div> 
				<span className="apply-coupon badge badge-light">
					<button className="text-upper font-weg-600" onClick={()=>{props.AddCouponToCart(props.cartTOTAL,post.MIN_PURCHASE,post.ID,post.CODE,post.TYPE,post.VALUE,post.TEXT)}}>Apply</button>
				</span>
			</div>
		); 		
	});

    return (
        <div>
            {Coupons}
        </div>
    );
}


const TAX = (props) =>{

	//console.log(props);

	let Taxes = props.taxes.map((post, i)=>{

		//console.log(post);

		return ( 
			<li className="ht-70 noafter">
				<div className="item">                  
					<div className="in">
						<div className="set1 title-cut-sub text-grey4 font-15 ft-wt-500 lt-spc05 pb-05">Tax {post.PERC}%</div>
						<div className="set2 mt-0 text-center"></div>
						<div className="set3 mt-0 text-right font-18 ft-wt-600">₹ {post.VALUE}</div> 
					</div>
				</div>
			</li>
		); 		
	});

    return (
        <ul className="listview image-listview no-border">
            {Taxes}
        </ul>
    );
}

class Booking2 extends Component {
    constructor(props) {
    	super(props);

    	this.state = {
    		LOADING: true,
    		Notifications: false,
    		modalType: 'ALERT', 
    		notifType: 'error',    		
    		modalTitle: '',
    		customerid: localStorage.getItem('userData'),
    		customerdet:  [],
    		bookingDet: [],
    		bookingTotal: 0,
    		bookingTotal2: 0,
    		timeslot: [],
    		bookingid: "", 
    		redirectPayment: 'N',
    		paymentAmt: 0,
    		minimumOrder: 0,
    		selectedType: localStorage.getItem('maincatid2'),
    		defaultAddress: '0',
    		minOrder:0,
    		couponArr: [],
            couponSUCCESS: '',
            couponFAILURE: '',
            disc_total: 0,
            tax_total: 0,
            sub_total: 0,
            tax_arr: [],
            couponTOTAL: 0,
            grandTotal: 0,
    	}

    	// console.log('cartData:', localStorage.getItem('cartData'));
    	// console.log('cartDate:', localStorage.getItem('cartDate'));
    	// console.log('cartTime:', localStorage.getItem('cartTime'));

    	this.getCartDetails = this.getCartDetails.bind(this);
    	this.getUserAddresses = this.getUserAddresses.bind(this);
    	this.changeAddress = this.changeAddress.bind(this);
	}

	getUserAddresses() {
	   const posturl = global.config.bURL+"userDetails.php";
	   const postData = {
	      method: 'POST',
	        headers: { 'Content-Type': 'application/json' },
	        body: JSON.stringify({ mode: 'CUSTOMER', id: this.state.customerid })
	   }

	   fetch(posturl, postData)
	      .then(res => res.json())
	      .then(result => {
	         // console.log('results obtained', result);

	         let notify = '';
	         if(result.err!=='' && result.err!==undefined)
	             notify = result.err;

	         this.setState({
	            LOADING: false,
	            customerdet: result.user
	         })
	         
	         if(notify!=='')
	             window.$('#ModalAlert').modal('show');
	      })
	      .catch(error => {
	            this.setState({ Notifications: error.toString() });
	            console.error('There was an error!', error);
	        });
	 }

	getCartDetails() {

        let COUPON_APPLIED = localStorage.getItem('COUPON_APPLIED');
        if(COUPON_APPLIED==null) 
            COUPON_APPLIED = '';

        let couponID = '';
        let couponAPPLIED = '';
        if(COUPON_APPLIED!='')
        {
            let COUPON_APPLIED2 =  COUPON_APPLIED.split('~~**~~');
            couponID =  COUPON_APPLIED2[0];
            couponAPPLIED =  COUPON_APPLIED2[1];
        }

		const posturl = global.config.bURL+"getCartDetails.php";
		const postData = {
			method: 'POST',
		    headers: { 'Content-Type': 'application/json' },
		    body: JSON.stringify({mode: 'CART', service_det: localStorage.getItem('cartData'), mainid: this.state.selectedType, selectedType: this.state.selectedType, coupon_id: couponID, coupon_code: couponAPPLIED })
		}
		
		fetch(posturl, postData)
			.then(res => res.json())
			.then(result => {
				// console.log('results obtained', result);
		
				let notify = '';
				if(result.err!=='' && result.err!==undefined)
				    notify = result.err;
		
				if(result.APPLY_COUPON==='N')
					localStorage.removeItem('COUPON_APPLIED');

				this.setState({ 
					LOADING:false,
					bookingDet: result.cart,
					bookingTotal: result.cart_total,
					bookingTotal2: result.cart_total2,
		            disc_total: result.disc_total,
		            tax_total: result.tax_total,
		            sub_total: result.sub_total,
		            tax_arr: result.tax_arr,
					couponTOTAL: result.coupon_total,
					grandTotal: result.grand_total,
					timeslot: result.timeslots,
					minOrder: result.MIN_ORDER,
					couponArr: result.active_coupons
				});
		
				if(notify!=='') {
		    		window.$('#ModalAlert').modal('show');
				}
			})
			.catch(error => {
		        this.setState({ Notifications: error.toString() });
		        console.error('There was an error!', error);
		    });
	}

	changeAddress() {
		var new_addrid = $('input[name=rdaddress]:checked').val();
		var new_addrstr = $('input[name=rdaddress]:checked').data('address');
		// $('#submitLoading').css('display','block');
		window.$('#modalAddress').modal('hide');
		localStorage.setItem('addrId', new_addrid);
		localStorage.setItem('addrStr', new_addrstr);
		this.getCartDetails();
		this.getUserAddresses();	
	}

	handlePayNow = () => {

        let COUPON_APPLIED = localStorage.getItem('COUPON_APPLIED');
        if(COUPON_APPLIED==null) 
            COUPON_APPLIED = '';

        let couponID = '';
        let couponAPPLIED = '';
        if(COUPON_APPLIED!='')
        {
            let COUPON_APPLIED2 =  COUPON_APPLIED.split('~~**~~');
            couponID =  COUPON_APPLIED2[0];
            couponAPPLIED =  COUPON_APPLIED2[1];
        }

		// console.log('processing booking ...');
		var valid = true;
		var valid2 = false;
		var hasAddr = localStorage.getItem('addrId');
		var addrStr = localStorage.getItem('addrStr');
		const posturl = global.config.bURL+"newBooking.php";
		const postData = {
			method: 'POST',
		    headers: { 'Content-Type': 'application/json' },
		    body: JSON.stringify({mode: 'NEW_BOOKING', cart: this.state.bookingDet, date: localStorage.getItem('cartDate'), timeslot: localStorage.getItem('cartTime'), customerid: this.state.customerid, addrId:hasAddr, addrStr:addrStr, typeid:this.state.selectedType, coupon_id: couponID, coupon_code: couponAPPLIED })
		}

		if(this.state.selectedType ==='2' && (hasAddr === '0' || hasAddr === 0 || hasAddr === null || hasAddr ===  undefined || hasAddr ===  'undefined' || addrStr ===  undefined || addrStr ===  'undefined') )
		{
			this.setState({ 
				Notifications: "Please select an address",
			}, () => {
				// console.log(this.state.Notifications);
				window.$('#ModalAlert').modal('show');
			});
		}
		else if(this.state.bookingTotal2 < this.state.minOrder)
		{
			this.setState({ 
				Notifications: "Minimum order value should be ₹"+this.state.minOrder,
			}, () => {
				// console.log(this.state.Notifications);
				window.$('#ModalAlert').modal('show');
			});
		}
		else
		{
			fetch(posturl, postData)
				.then(res => res.json())
				.then(result => {
					// console.log('results obtained', result);
			
					let notify = '';
					if(result.err!=='' && result.err!==undefined)
					    notify = result.err;

					localStorage.setItem('bookingCode', result.bookingCode);
					this.setState({ 
						bookingid: result.bookingCode,
						redirectPayment: result.redirect,
						paymentAmt: result.tot_amt
					});

			
					if(notify!=='') {
			    		window.$('#ModalAlert').modal('show');
					}
				})
				.catch(error => {
			        this.setState({ Notifications: error.toString() });
			        console.error('There was an error!', error);
			    });
		}
	}

	setBookingAddress = (adr_id, adr_str) => {
		// console.log(adr_id, adr_str);
		localStorage.setItem('addrId', adr_id);
		localStorage.setItem('addrStr', adr_str);
	}

	confirmRemove = (idstr) => {
		this.setState({rmvidStr:idstr});

		if(idstr!='')
			window.$('#confirmAlert').modal('show');
	}

	handleRemove = (idstr) => {
		let divId = idstr;
		let idarr = idstr.split('-');
		let sid = idarr[2];
		let new_arr = []

		// console.log('removing: ', idstr);

		if(sid!='' && sid!=undefined) {
			// remove sid from array
			let service_arr = JSON.parse(localStorage.getItem('cartData'));
			// console.log('before arr', service_arr);
			delete service_arr[sid];
			
			service_arr.forEach((element, index) => {
				if(element!=null && element!=undefined)
					new_arr.push(element);
			});
			// console.log('after arr', new_arr);

			// set the new array
			let newarr = localStorage.setItem('cartData', JSON.stringify(new_arr));
			this.getCartDetails();
		}
	}

	componentWillMount() {
	    let mount = $("div").hasClass("modal-backdrop");
	    if (mount == true) {
	        $('body').removeClass('modal-open');
	        $(".modal-backdrop").remove();
	    }
	}

	componentDidMount() {
		this.getCartDetails();
		this.getUserAddresses();
	}

    AddCouponToCart = (cartTOTAL,minPURCHASE,couponID,couponCODE,couponTYPE,couponVALUE,couponTEXT) => {

        if(parseFloat(cartTOTAL)>=parseFloat(minPURCHASE))
        {
            let COUPON = 0;
            if(couponTYPE=='P')
                COUPON = (parseFloat(cartTOTAL) * parseFloat(couponVALUE))/100;
            else if(couponTYPE='A')
                COUPON = couponVALUE;

            COUPON = Math.round(COUPON);

            let msg = '';
            if(COUPON!=0 && COUPON!='0')
            { 
                msg = `<p className="font-weg-600 font-size-13 text-upper mb-05">'${couponCODE}'`+' applied</p> <p className="mb-05 ft-wt-600 font-22 text-red" style="color:red; font-weight:600;    font-size: 30px;">Rs '+`${COUPON}`+'</p> <p className="font-size-12"> savings with this coupon</p>';
            }
            else
            {
                msg = `<p className="font-weg-600 font-size-13 text-upper mb-05">'${couponCODE}'`+' applied</p> <p className="mb-05 font-size-12 text-danger">'+`${couponTEXT}`+'</p> <p className="font-size-12"> free with this coupon</p>';
            }

            let msg2 = couponCODE;

            localStorage.setItem('COUPON_APPLIED',couponID+'~~**~~'+couponCODE);

            let grandTotal = parseFloat(cartTOTAL) - parseFloat(COUPON);

            this.setState({ couponSUCCESS:msg, couponTOTAL:COUPON, grandTotal:grandTotal, couponAPPLIED:msg2 });
            window.$('#ModalCouponList').modal('hide');
            window.$('#couponSuccessModal').modal('show');
        }
        else
        {
            localStorage.removeItem('COUPON_APPLIED');
            this.setState({ couponFAILURE:'Cart value is not sufficient'});
            window.$('#ModalCouponList').modal('hide');
            window.$('#couponFailureModal').modal('show');
        }
    }

    RemoveCouponFromCart = () => {
        localStorage.removeItem('COUPON_APPLIED');
    }

	render() {
		if(!localStorage.getItem('userData')) {
			return (<Redirect to={'/logout'}/>)
		}

		if(!this.state.bookingDet) {
			return (<Redirect to={'/home'}/>)	
		}

		if(this.state.redirectPayment=='Y' && (this.state.paymentAmt==0 || this.state.selectedType == 1) ) {
			// console.log('/paymentresponse2');
			// redirect to payment gateway
			// return (<Redirect to={'/onlinepay'}/>)	// payment not integrated
			return (<Redirect to={'/paymentresponse2'}/>)	
		}
		
		if(this.state.redirectPayment=='Y' && this.state.paymentAmt!=0) {
			// console.log('/onlinepay');
			// redirect to payment gateway
			return (<Redirect to={'/onlinepay'}/>)	// payment not integrated
			// return (<Redirect to={'/paymentresponse'}/>)	
		}

		let AppHeaderClass = 'appHeader no-border bg-coach-header text-light pt-2 pb-2';
		let AppHeaderLeft = 'showLINK';
		let AppHeaderLeftLink = '/booking';
		let AppHeaderTitle = <h5 className="text-upper lt-spc1 text-grey5b mb-0 pt-05 pb-05">Book an Appointment</h5>
		let AppHeaderRight = '';
		let AppHeaderRightLink = '';

		let bookingTotal = 0;

		let cart_date = localStorage.getItem('cartDate');
		let cart_time = localStorage.getItem('cartTime');
		let selectedAddressId = localStorage.getItem('addrId');
		let selectedAddress = localStorage.getItem('addrStr');

		let COUPON_APPLIED = localStorage.getItem('COUPON_APPLIED');
        if(COUPON_APPLIED==null) 
            COUPON_APPLIED = '';

        let couponID = '';
        let couponAPPLIED = '';
        if(COUPON_APPLIED!='')
        {
            let COUPON_APPLIED2 =  COUPON_APPLIED.split('~~**~~');
            couponID =  COUPON_APPLIED2[0];
            couponAPPLIED =  COUPON_APPLIED2[1];
        }

		return(
			<>
				{this.state.LOADING && 
	                <Loader />
	            }

	            <AppHeader 
                    AppHeaderClass={AppHeaderClass} 
                    AppHeaderLeft={AppHeaderLeft} 
                    AppHeaderLeftLink={AppHeaderLeftLink} 
                    AppHeaderTitle={AppHeaderTitle} 
                    AppHeaderRight={AppHeaderRight} 
                    AppHeaderRightLink={AppHeaderRightLink} 
                />

                <div id="appCapsule" className="pt-1">
                	<div className="section pt-1 pb-1 mt-6">
                		<div className="form-wizard-section appoint-process">
                			<Link to="/booking" className="button-item active">
                				<strong><ion-icon name="checkmark-outline"></ion-icon></strong>
                				<p className="pt-05 text-upper font-12 lt-spc05">Selection</p>
                			</Link>
                			<Link to="/booking2" className="button-item active">
                				<strong><ion-icon name="checkmark-outline"></ion-icon></strong>
                				<p className="pt-05 text-upper font-12 lt-spc05">Choose Slot</p>
                			</Link>
                			<Link to="#" className="button-item">
                				<strong><ion-icon name="checkmark-outline"></ion-icon></strong>
                				<p className="pt-05 text-upper font-12 lt-spc05">Confirmed</p>
                			</Link>
                		</div>
                	</div>
                	{this.state.selectedType == '2' &&
	                	<ul className="address-popup user-edits address-option listview image-listview media mb-0 mt-2 pb-0 pt-1">
	                	   <li>
	                	      <div className="item">
	                	         <div className="icon-box">
	                	            <ion-icon name="business" role="img" classNamename="md hydrated" aria-label="image outline" className="md hydrated"></ion-icon>
	                	         </div>
	                	         <div className="in">
	                	         	{(selectedAddressId=='' || selectedAddressId==null || selectedAddressId ==undefined)?
	                	         		<div>
		                           		   	<div className="">
		                           		      	<span className="ft-wt-500"></span>	
												{(this.state.customerdet && this.state.customerdet.ADDRESSES && this.state.customerdet.ADDRESSES.length>0) ? <button type="button" className="btn-address-pop" data-toggle="modal" data-target="#modalAddress">Select Address</button>:<div>
					                           	<div className="">
					                              	<span className="ft-wt-500"></span>
					                              	<Link to="/addaddress2" className="btn-address-pop" >Add Address</Link>
					                           	</div>
					                        </div>}

		                           		      	<p className="font-13 text-dgrey pt-1 pb-05 mb-05" dangerouslySetInnerHTML={{__html: "No address" }} />
		                           		   	</div>
		                           		</div>
		                           		:
		                           		(this.state.customerdet && this.state.customerdet.ADDRESSES && this.state.customerdet.ADDRESSES.length>0) ?
					                        this.state.customerdet.ADDRESSES.map((address, i)=>{
				                        	if(selectedAddressId == address.CADDR_ID)
					                        	{
						                           	let address_str = address.ADDRESS

						                           	if(address.ADDRESS2!='' && address.ADDRESS2!=null)
						                              address_str += ', '+address.ADDRESS2;

						                           	if(address.ADDRESS3!='' && address.ADDRESS3!=null)
						                              address_str += ', '+address.ADDRESS3;

						                           	if(address.ROAD!='' && address.ROAD!=null)
						                              address_str += '<br/>'+address.ROAD;

						                           	if(address.AREA!='' && address.AREA!=null)
						                              address_str += ' - '+address.AREA;

						                           	if(address.LANDMARK!='' && address.LANDMARK!=null)
						                              address_str += '<br/>Landmark: '+address.LANDMARK;

						                           	if(address.CITY!='' && address.CITY!=null)
						                              address_str += '<br/>'+address.CITY;

						                           	if(address.STATE!='' && address.STATE!=null)
						                              address_str += ' '+address.STATE;

						                           	if(address.PINCODE!='' && address.PINCODE!=null)
						                              address_str += ' '+address.PINCODE;

						                           return(
						                           		<div key={i}>
						                           		   	<div className="">
						                           		      	<span className="ft-wt-500">{address.LABEL}</span>
						                           		      	<button type="button" className="btn-address-pop" data-toggle="modal" data-target="#modalAddress">Select Address</button>
						                           		      	<p className="font-13 text-dgrey pt-1 pb-05 mb-05" dangerouslySetInnerHTML={{__html: address_str }} />
						                           		   	</div>
						                           		</div>
						                           )
						                       }
					                        })
				                        	:
					                        <div>
					                           	<div className="">
					                              	<span className="ft-wt-500"></span>
					                              	<Link to="/addaddress2" className="btn-address-pop" >Add Address</Link>
					                              	<p className="font-13 text-dgrey pt-1 pb-05 mb-05">No address selected</p>
					                           	</div>
					                        </div>
	                	         	}
	                	         </div>
	                	      </div>
	                	   </li>
	                	</ul>
                	}

                	{/*<div className="apply-coupon mb-3">
	                		<ul className="listview image-listview flush transparent mb-0">
		          		        <li>
		          		           <div className="in font-weg-600 font-size-13 text-upper item"> 
		          		              <form className="search-form" action="" autoComplete="off">
		          		              		<div className="form-group searchbox search-cover mb-1 mt-1">
	          		              				<input type="text" className="form-control mr-05" name="search" placeholder="Enter the coupon code" defaultValue="" />
    		          		                    <button type="submit" className="btn bt-bg-gold btn-search">Apply</button>
		          		                    </div>
		          		              </form>
			          		        </div>
		          		        </li>
	          		      </ul>
                	</div>*/}

                	<div className="section date-time slot-change pt-05 mt-1 mb-1">
                	   <ul className="listview image-listview media mt-0 p-1 no-border bdr-radius8 bg-lyellow" data-toggle="modal" data-target="#ModalCalendar">
                	      <li>
                	         <Link to="#" className="item pr-05 pl-05 pt-0 pb-0">
                	            <div className="in">
                	               <div className="cal-date text-black font-16 ft-wt-400 l-20">
                	                  <div className="equal-div text-left">
                	                     <p className="slot-titles ft-wt-400 font-13 pb-1 mb-0 lt-spc2">Date</p>
                	                     <p className="ft-wt-700 font-12 pb-0 mb-0 lt-spc0">
                	                     	<Moment date={cart_date} format="DD MMM YYYY"/>
                	                     </p>
                	                  </div>
                	                  <div className="equal-div-mid text-center">
                	                     <img src="assets/images/appointment-icon.png" alt="calendar icon" className="imaged w24" />
                	                    <p className="slot-titles ft-wt-400 text-upper font-8 pt-1 pb-1 mb-0 lt-spc1">Change</p>
                	                  </div>
                	                  <div className="equal-div text-right">
                	                     <p className="slot-titles ft-wt-400 font-13 pb-1 mb-0 lt-spc2">Timings</p>
                	                     <p className="ft-wt-700 font-12 pb-0 mb-0 lt-spc0">{(this.state.timeslot && this.state.timeslot!='')?this.state.timeslot[cart_time]:'n/a'}</p>
                	                  </div>
                	               </div>
                	            </div>
                	         </Link>
                	      </li>
                	   </ul>
                	</div>
                	<div className="section treatment pt-1">
                		<ul className="listview image-listview no-border">
                			<li className="cart-list-head noafter">
                				<div className="item">
                					<div className="in">
                						<div className="set1 font-12 mb-0 ft-wt-500 pr-1 pl-0 pt-0 pb-0 text-grey7">Treatment </div>
                						<div className="set2 mt-0 text-center font-12 text-grey7 ft-wt-500"> Duration </div>
                						<div className="set3 mt-0 text-right font-12 text-grey7 ft-wt-500">Amount</div> 
                						<div className="set4 mt-0 text-center font-12 text-grey7 ft-wt-500">Qty</div>
                						<div className="set4 mt-0 text-center font-12 text-grey7 ft-wt-500"></div>
                					</div>
                				</div>
                			</li>
                			{this.state.bookingDet && this.state.bookingDet.length!=0 &&
                				this.state.bookingDet.map((cart, i) => {
                					let srnum = i+1;
                					let is_added = true;
                					let id_str = 'booking-list-'+i;
                					{/*bookingTotal += parseInt(cart.RATE);*/}

                					if(!isNaN(i))
                					{
	                					return(
	                						<li key={i} id={id_str}>
	                							<div className="item">
	                								<div className="in">
	                									<div className="set1 set-name title-cut-sub text-grey4 font-14 ft-wt-500 pb-0">{cart.NAME}</div>
	                									<div className="set2 mt-0 text-center text-grey7 text-upper ft-wt-400 font-12 lt-spc1">{(cart.TIME && cart.TIME!='')?cart.TIME:''}</div>
	                									{cart.OFFER_PRICE == 0 ?
	                										<div className="set3 mt-0 text-right font-8 ft-wt-600 text-grey7">On consultation</div>
	                										:
	                										<div className="set3 mt-0 text-right font-16 ft-wt-600 text-lightred">₹ {cart.OFFER_PRICE_DISP}</div> 
	                									}
	                									<div className="set4 mt-0 text-right font-16 ft-wt-600">{cart.PAX}</div> 
	                									<button type="button" onClick={()=>this.confirmRemove(id_str)} className="set4 btn-set4 mt-0 text-center font-12 ft-wt-700 text-lightred">
	                										<ion-icon name="trash"></ion-icon>
	                									</button>
	                								</div>
	                							</div>
	                						</li>
	                					)
	                				}
                				})
                			}

                			{this.state.bookingDet && this.state.bookingDet.length!=0 ?
	                			<li className="ht-70 noafter">
	                				<div className="item">                  
	                					<div className="in">
	                						<div className="set1 title-cut-sub text-grey4 font-15 ft-wt-500 lt-spc05 pb-05">Total</div>
	                						<div className="set2 mt-0 text-center"></div>
	                						<div className="set3 mt-0 text-right font-18 ft-wt-600">₹ {this.state.bookingTotal}</div> 
	                						{/*<div className="set4 mt-0 text-center font-12 text-grey4 ft-wt-500"></div>*/}
	                					</div>
	                				</div>
	                			</li>
	                			:
	                			<li className="ht-70 noafter">
	                				<div className="item">                  
	                					<div className="in">
	                						<div className="set1 close-service title-cut-sub text-grey4 font-15 ft-wt-500 lt-spc05 pb-05">No services selected for booking</div>
	                					</div>
	                				</div>
	                			</li>
	                		}
                		</ul>
            			{this.state.disc_total>0 && 
            				<ul className="listview image-listview no-border">
	                			<li className="ht-70 noafter">
	                				<div className="item">                  
	                					<div className="in">
	                						<div className="set1 title-cut-sub text-grey4 font-15 ft-wt-500 lt-spc05 pb-05">Discount</div>
	                						<div className="set2 mt-0 text-center"></div>
	                						<div className="set3 mt-0 text-right font-18 ft-wt-600">₹ {this.state.disc_total}</div> 
	                					</div>
	                				</div>
	                			</li>
							</ul>
            			}

            			{this.state.tax_arr.length>0 && 
            				<TAX taxes={this.state.tax_arr} />
            			}

               			{this.state.sub_total>0 && 
            				<ul className="listview image-listview no-border">
	                			<li className="ht-70 noafter">
	                				<div className="item">                  
	                					<div className="in">
	                						<div className="set1 title-cut-sub text-grey4 font-15 ft-wt-500 lt-spc05 pb-05">Sub Total</div>
	                						<div className="set2 mt-0 text-center"></div>
	                						<div className="set3 mt-0 text-right font-18 ft-wt-600">₹ {this.state.sub_total}</div> 
	                					</div>
	                				</div>
	                			</li>
							</ul>
               			}
                	</div>

                	<div className="modal fade modalbox modal-address" id="modalAddress" tabIndex="-1" role="dialog">
                	    <div className="modal-dialog" role="document">
                	        <div className="modal-content">
                	            <div className="modal-header">
                	                <h5 className="modal-title font-14">Please Select the address</h5>
                	                <Link to="javascript:;" data-dismiss="modal"><ion-icon name="close-outline"></ion-icon></Link>
                	            </div>
                	            <div className="modal-body p-0">
                	            	<div className="input-list">
                	            	{this.state.customerdet && this.state.customerdet.ADDRESSES ?
				                        this.state.customerdet.ADDRESSES.map((address, i)=>{
				                           let address_str = address.LABEL+'<br/>';
				                           let isChecked = false;

				                           if(address.CADDR_ID == selectedAddressId)
				                           	isChecked = true;

				                           //address_str += '&nbsp; '+address.LABEL;

				                           address_str += address.ADDRESS;

				                           if(address.ADDRESS2!='' && address.ADDRESS2!=null)
				                              address_str += ', '+address.ADDRESS2;

				                           if(address.ADDRESS3!='' && address.ADDRESS3!=null)
				                              address_str += ', '+address.ADDRESS3;

				                           if(address.ROAD!='' && address.ROAD!=null)
				                              address_str += '<br/>'+address.ROAD;

				                           if(address.AREA!='' && address.AREA!=null)
				                              address_str += ' - '+address.AREA;

				                           if(address.LANDMARK!='' && address.LANDMARK!=null)
				                              address_str += '<br/>Landmark: '+address.LANDMARK;

				                           if(address.CITY!='' && address.CITY!=null)
				                              address_str += '<br/>'+address.CITY;

				                           if(address.STATE!='' && address.STATE!=null)
				                              address_str += ' '+address.STATE;

				                           if(address.PINCODE!='' && address.PINCODE!=null)
				                              address_str += ' '+address.PINCODE;

				                           return(
				                           		<div className="custom-control custom-checkbox" key={i}>
				                           		    <input type="radio" defaultChecked={isChecked} className="custom-control-input" data-address={address_str} id={"rdaddress"+i} name="rdaddress" defaultValue={address.CADDR_ID} />
				                           		    <label className="custom-control-label font-13" htmlFor={"rdaddress"+i} dangerouslySetInnerHTML={{__html: address_str }} />
				                           		</div>
				                           )
				                        })
				                        :
				                        <div className="custom-control custom-checkbox">
		                           		    <p>No addresses available</p>
		                           		</div>
				                    }
				                    </div>
				                    <div className="form-button-group bg-back">
				                    <button type="button" onClick={this.changeAddress} className="btn w-100 rounded bt-bg-gold btn-block btn-lg mr-0 ml-0 text-upper font-14 lt-spc2 ft-wt-600 btn-ht50 box-shadowg">
				                    Save
				                    <span className="spinner-border spinner-border-sm mr-05" id="submitLoading" style={{ display: 'none' }} role="status" aria-hidden="true"></span>
				                    </button>
				                    </div>
                	            </div>
                	        </div>
                	    </div>
                	</div>


                	{this.state.bookingDet && this.state.bookingDet.length!=0 && this.state.couponArr.length>0 &&
                		<div>
		                	<div className="apply-coupon mb-3">
			                		<ul className="listview image-listview flush transparent mb-0">
				          		        <li>
				          		           <div className="in font-weg-600 font-size-13 text-upper item"> 
				          		              
				          		              {couponAPPLIED==='' &&
					          		              <div className="coupon-a">
					          		              	<Link to="#" className="text-grey" data-toggle="modal" data-target="#ModalCouponList">
					          		              		<div>
					          		              		   <img class="pr-1" src="/assets/images/voucher.png" alt="coupon icon"/>
					          		              		   Apply Coupon
					          		              		</div>
					          		              	    <ion-icon name="arrow-forward-circle-outline"></ion-icon>
					          		              	</Link>
					          		              </div>
					          		          }
				          		              {couponAPPLIED!='' &&
					          		              <div className="coupon-a">
					          		              	<div className="sub-coupon">
						          		              	{couponAPPLIED}
						          		              	<Link to="#" onClick={this.RemoveCouponFromCart} className="text-grey">
						          		              		<ion-icon name="close-circle-outline"></ion-icon>
						          		              	</Link>
						          		            </div>
					          		              </div>
					          		           }
					          		        </div>
				          		        </li>
			          		      </ul>
		                	</div>

		                	{couponAPPLIED!='' &&
		                		<ul className="listview image-listview no-border">
		                			<li className="ht-70 noafter">
		                				<div className="item">                  
		                					<div className="in">
		                						<div className="set1 title-cut-sub text-grey4 font-15 ft-wt-500 lt-spc05 pb-05">Coupon Discount</div>
		                						<div className="set2 mt-0 text-center"></div>
		                						<div className="set3 mt-0 text-right font-18 ft-wt-600">₹ {this.state.couponTOTAL}</div> 
		                					</div>
		                				</div>
		                			</li>
		                			<li className="ht-70 noafter">
		                				<div className="item">                  
		                					<div className="in">
		                						<div className="set1 title-cut-sub text-grey4 font-15 ft-wt-500 lt-spc05 pb-05">Grand Total</div>
		                						<div className="set2 mt-0 text-center"></div>
		                						<div className="set3 mt-0 text-right font-18 ft-wt-600">₹ {this.state.grandTotal}</div> 
		                					</div>
		                				</div>
		                			</li>
	                			</ul>
	                		}

		                	<div className="modal fade dialogbox" id="DialogIconedInfo" data-backdrop="static" tabIndex="-1" role="dialog">
		                	    <div className="modal-dialog" role="document">
		                	        <div className="modal-content">
		                	            <div className="modal-header">
		                	                {/*<h5 className="modal-title"></h5>*/}
		                	            </div>
		                	            <div className="modal-body pr-0 pl-0">
		                	                <div className="section-title  ft-wt-500 section-head font-size-18 pt-1 mb-05 pr-2 pl-2 text-sdgrey">Amanora Exclusives
		                	                     <Link className="badge badge-transparent text-grey6 ft-wt-500 lt-spc1 text-upper font-10 ht-20" to="">Apply</Link>
		                	                </div>
		                	                <div className="section-title  ft-wt-500 section-head font-size-18 pt-1 mb-05 pr-2 pl-2 text-sdgrey">Amanora Exclusives
		                	                     <Link className="badge badge-transparent text-grey6 ft-wt-500 lt-spc1 text-upper font-10 ht-20" to="">Apply</Link>
		                	                </div>
		                	                <div className="section-title  ft-wt-500 section-head font-size-18 pt-1 mb-05 pr-2 pl-2 text-sdgrey">Amanora Exclusives
		                	                     <Link className="badge badge-transparent text-grey6 ft-wt-500 lt-spc1 text-upper font-10 ht-20" to="">Apply</Link>
		                	                </div>

		                	            </div>
		                	            <div className="modal-footer">
		                	                <div className="btn-inline">
		                	                    <Link to="#" className="btn" data-dismiss="modal">CLOSE</Link>
		                	                </div>
		                	            </div>
		                	        </div>
		                	    </div>
		                	</div>

		                	<div className="modal fade modalbox delivery-coupon" id="ModalCouponList" tabIndex="-1" role="dialog">
		                        <div className="modal-dialog" role="document">
		                            <div className="modal-content">
		                                <div className="modal-header">
		                                    <h5 className="text-upper w-100 lt-spc1 text-center text-grey5b mb-0 pt-05 pb-05">Available coupons</h5>
		                                    <Link to="#" data-dismiss="modal" className="font-16 text-grey7 ft-wt-700">x</Link>
		                                </div>
		                                <div className="offer-modal modal-body p-0">
		                                    <div className="input-list">
		                                        <COUPON coupons={this.state.couponArr} cartTOTAL={this.state.sub_total} AddCouponToCart={this.AddCouponToCart} />
		                                    </div>
		                                </div>
		                          	</div>
		                        </div>
		                    </div>

		                    <div className="modal fade dialogbox" id="couponSuccessModal" data-backdrop="static" tabIndex="-1" role="dialog">
		                        <div className="modal-dialog" role="document">
		                            <div className="modal-content">
		                                <div className="modal-header m-0 p-0">
		                                    <h5 className="modal-title">&nbsp;</h5>
		                                </div>
		                                <div className="modal-body"> 
		                                    <div className="modal-icon bg-oran text-success mt-0 mb-1">
		                                        <ion-icon name="checkmark-circle"></ion-icon>
		                                    </div>
		                                    <div dangerouslySetInnerHTML={{ __html: this.state.couponSUCCESS }} />
		                                </div>
		                                <div className="modal-footer">
		                                    <div className="btn-inline"> 
		                                        <button type="button" className="btn bt-oran btn-text-success" data-dismiss="modal">Ok</button>
		                                    </div>
		                                </div>
		                            </div>
		                        </div>
		                    </div>

			                <div className="modal fade dialogbox" id="couponFailureModal" data-backdrop="static" tabIndex="-1" role="dialog">
			                    <div className="modal-dialog" role="document">
			                        <div className="modal-content">
			                            <div className="modal-header m-0 p-0">
			                                <h5 className="modal-title">&nbsp;</h5>
			                            </div>
			                            <div className="modal-body"> 
			                                <div className="modal-icon text-danger mt-0 mb-1">
			                                    <ion-icon name="close-circle"></ion-icon>
			                                </div>
			                                {this.state.couponFAILURE} 
			                            </div>
			                            <div className="modal-footer">
			                                <div className="btn-inline"> 
			                                    <button type="button" className="btn btn-text-danger" data-dismiss="modal">Ok</button>
			                                </div>
			                            </div>
			                        </div>
			                    </div>
			                </div>


		                </div>
	                }


                	{this.state.bookingDet && this.state.bookingDet.length!=0 &&
                		<div className="section pt-1 pr-2 pl-2 pb-3">
                	   		<button type="button" onClick={()=>this.handlePayNow()} className="btn w-100 rounded bt-bg-gold btn-block btn-lg mr-0 ml-0 text-upper font-14 lt-spc2 ft-wt-600 btn-ht60 box-shadowg">Book Now
                	   		</button>
                		</div>
                	}
                	{/*<div className="section-instruction">
                	   <div className="text-center p-1">
                	         <h5 className="pt-05 text-upper font-12 lt-spc05 text-grey4 ft-wt-500">Instruction</h5>
                	         <div>
                	            <ul className="listview image-listview no-border bg-transparent ">
                	                <li>
                	                    <div className="item">
                	                         <div className="pr-2">
                	                           <ion-icon name="checkmark-outline"></ion-icon>
                	                        </div>
                	                        <div className="in">
                	                            <div className="text-left font-14 ft-wt-200 lt-spc05 text-dgrey">Drift into sleep; reduce stress and anxiety with specialized program</div>
                	                        </div>
                	                    </div>
                	                </li>
                	            </ul>
                	         </div>
                	   </div>
                	</div>*/}
                </div>

                <AppBottomMenu activeClass="appointment" />
                <BookingCalendar selected_date={cart_date} selected_timeslot={cart_time} />
                <ModalAlert modalType={this.state.modalType} Notifications={this.state.Notifications} type={this.state.notifType} modalTitle={this.state.modalTitle} />

                <div className="modal fade dialogbox" id="confirmAlert" data-backdrop="static" tabIndex="-1" role="dialog">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <p className="modal-title text-upper font-15 pb-1">Remove Item</p>
                      </div>
                      <div className="modal-body mb-05"><p className="font-15">This item will be removed from your booking</p></div>
                      <div className="modal-footer">
                        <div className="btn-inline"> 
                            <button type="button" className="btn btn-text-secondary text-red font-14" data-dismiss="modal">CANCEL</button> 
                            <button type="button" onClick={()=>this.handleRemove(this.state.rmvidStr)} className="btn btn-text-primary text-gold font-14" data-dismiss="modal">CONFIRM</button> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
			</>
		)
	}
}

export default Booking2;