import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';

class BookingStrip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "error",
      redirectBooking: false,
      Notifications: ''
    }
  }

  componentDidMount() {

  }

  validateMinOrder = () => {

    var tot = this.props.totalAmount2;
    var min = this.props.minOrder;
    // console.log(tot,min);

    if(tot < min) {
      this.setState({
        Notifications: "Minimum order value to be &#8377;"+min,
        redirectBooking: false
      })
      window.$('#bookingStripModal').modal('show');
    }
    else {
      this.setState({
        redirectBooking: true
      })
    }
  }

  render() {

    if(this.state.redirectBooking === true) {
      return(<Redirect to='/booking' />)
    }

    return(
      <>
      <div className="form-button-group2 bg-gold">
        <div className="col-12">
          <div className="section-title w-100 d-flex justify-content-between pt-1 pb-1 pr-0 pl-0 medium">
              <div className="w-50 text-left">
                  <p className="mb-0 text-upper ft-wt-500 text-white font-12">{this.props.selectedCount}{(this.props.selectedCount > 1)?" Treatments":" Treatment"}<br/>
                  <span className="ml-0 ft-wt-700 font-17 text-gre3">&#8377; {this.props.totalAmount}</span></p>
              </div>
              <div className="w-50 text-right">
              <p className="mb-0 text-upper">
                <Link to="#" className="btn btn-coach-pink btn-checkout anchor rounded mr-0 bt-skip lt-spc1 p-0" onClick={()=>this.validateMinOrder()}>Book Now</Link>
              </p>
          </div>
          </div>
        </div>
      </div>

      <div className="modal fade dialogbox overlay-back" id="bookingStripModal" data-backdrop="static" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
              <div className="modal-content">
                  {this.state.type=='error' &&
                      <div className="modal-icon text-danger mb-2">
                          <ion-icon name="close-circle"></ion-icon>
                      </div>
                  }

                  {this.state.type=='success' &&
                      <div className="modal-icon text-success mb-2">
                          <ion-icon name="checkmark-circle"></ion-icon>
                      </div>   
                  }

                  <div className="modal-body"> 
                      <p className="pb-0 mb-0" dangerouslySetInnerHTML={{ __html: this.state.Notifications}} />
                  </div>
                  <div className="modal-footer">
                      <div className="btn-inline"> 
                          <button type="button" className="btn" data-dismiss="modal">OK</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      </>
    )
  }
}

export default BookingStrip;