import React from 'react';
import ReactDOM from 'react-dom';
import Loader from './Loader';

const mapStyles = {
  map: {
    position: 'absolute',
    width: '100%',
    height: '100%'
  }
};

export class CurrentLocation extends React.Component {

    constructor(props) {
        super(props);
        const { lat, lng } = this.props.initialCenter;
        this.state = {
            currentLocation: {
                lat: lat,
                lng: lng,
            },
            address: '',
            address3:'',
            road: '',
            area: ''
        };
    }

    recenterMap() {
        const map = this.map;
        const current = this.state.currentLocation;
    
        const requestOption = {
          method: 'GET'
        };

        fetch('https://maps.googleapis.com/maps/api/geocode/json?address='+current.lat+','+current.lng+'&key=AIzaSyCTkUK9u3gNBcNnw4PDgbIfXCYDiixfd7U', requestOption)
        .then(res => res.json())
        .then(response => {


          var road = '';
          var block = '';
          var area = '';

          for(var j = 0; j<response.results.length; j++)
          {
              if(response.results[j].address_components)
              {
                  for(var i in response.results[j].address_components)
                  {
                      if(response.results[j].address_components[i].types[0]==='route')
                          road = response.results[j].address_components[i].long_name;
                      if(response.results[j].address_components[i].types[0]==='neighborhood' && response.results[j].address_components[i].types[1]==='political')
                          block = response.results[j].address_components[i].long_name;
                      if(response.results[j].address_components[i].types[0]==='political' && response.results[j].address_components[i].types[1]==='sublocality')
                          area = response.results[j].address_components[i].long_name;
                      if(area==='' && response.results[j].address_components[i].types[0]==='locality' && response.results[j].address_components[i].types[1]==='political')
                          area = response.results[j].address_components[i].long_name;

                      //console.log(response.results[j].address_components[i].long_name);
                  }
              }
          }
          
          let address = response.results[0].formatted_address;
          //console.log('Inside Recenter');
          //console.log(address);
          this.setState({ address: address, road:road, address3:block, area:area });
          this.handleGetAddress2();
        })
        .catch(error => {
            console.warn(error);
        });

        const google = this.props.google;
        const maps = google.maps;
    
        if (map)
        {
            let center = new maps.LatLng(current.lat, current.lng);
            map.panTo(center);
        }
    }

    loadMap()
    {

        if (this.props && this.props.google) {
          // checks if google is available
          const { google } = this.props;
          const maps = google.maps;
    
          const mapRef = this.refs.map;
    
          // reference to the actual DOM element
          const node = ReactDOM.findDOMNode(mapRef);
          let { zoom } = this.props;
          const { lat, lng } = this.state.currentLocation;
          const center = new maps.LatLng(lat, lng);
          const mapConfig = Object.assign(
            {},
            {
              center: center,
              zoom: zoom
            }
          );
    
          // maps.Map() is constructor that instantiates the map
          this.map = new maps.Map(node, mapConfig);
        }
    }

    handleGetAddress2 = () =>{

        const { lat, lng } = this.state.currentLocation;
        this.props.handleGetAddress(this.state.address,this.state.address3,this.state.road,this.state.area,lat,lng);  
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.google !== this.props.google) {
          //console.log('loadmap');
          this.loadMap();
        }
        if (prevState.currentLocation !== this.state.currentLocation) {
          //console.log('recentermap');
          this.recenterMap();
        }
      }

    componentDidMount() 
    {
      //console.log('mounted');
      /*if (this.props.centerAroundCurrentLocation) {
        if (navigator && navigator.geolocation) {

            //navigator.geolocation.watchPosition(pos => {
            navigator.geolocation.getCurrentPosition(pos => {
              const coords = pos.coords;
              const requestOption = {
                  method: 'GET'
              };
              
              //console.log(coords);

              let address = '';
              fetch('https://maps.googleapis.com/maps/api/geocode/json?address='+coords.latitude+','+coords.longitude+'&key=AIzaSyCTkUK9u3gNBcNnw4PDgbIfXCYDiixfd7U', requestOption)
              .then(res => res.json())
              .then(response => {
                address = response.results[0].formatted_address;
                this.setState({
                  currentLocation: {
                    lat: coords.latitude,
                    lng: coords.longitude
                  },
                  address: address
                });
                  
              })
              .catch(error => {
                  console.warn(error);
              });
            });
          }
        }*/
        this.loadMap();
        this.handleGetAddress2();
    }

    renderChildren() {
        const { children } = this.props;
    
        if (!children) return;

        //console.log(this.state.currentLocation);

        return React.Children.map(children, c => {
          if (!c) return;
          return React.cloneElement(c, {
            map: this.map,
            google: this.props.google,
            mapCenter: this.state.currentLocation
          });
        });
    }
    
    render() {
        const style = Object.assign({}, mapStyles.map);
        return (
            <div>
                <div style={{width: '100%', height: '300px', 'overFlow': 'hidden'}} ref="map"> {/*style={style} */}
                  <Loader />
                </div>
                {this.renderChildren()}
            </div>
       );
    }

}


export default CurrentLocation;

CurrentLocation.defaultProps = {
  zoom: 14,
  initialCenter: {
    lat: 15.470236, 
    lng: 73.8254184,
  },
  centerAroundCurrentLocation: false,
  visible: true
};