import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import AppBottomMenu from './AppBottomMenu';
import BookingStrip2 from './BookingStrip2';
import Moment from 'react-moment';
import $ from "jquery";

class Membership extends Component {
    constructor(props) {
    	super(props);

        this.state = {
            LOADING: true,
            Notifications: false,
            modalType: 'ALERT', 
            notifType: 'error', 
            modalTitle: '',
            customerid: localStorage.getItem('userData'),
            offersdet: []
        }

    }

    componentWillMount() {
        let mount = $("div").hasClass("modal-backdrop");
        if (mount == true) {
            $('body').removeClass('modal-open');
            $(".modal-backdrop").remove();
        }
    } 

    componentDidMount() {
        this.setState({
            LOADING: false
        })
    }

    render() {
    	/*if(!localStorage.getItem('userData')) {
    	    return (<Redirect to={'/logout'} />)
    	}*/

    	let AppHeaderClass = 'appHeader no-border bg-coach-header text-light pt-2 pb-2';
    	let AppHeaderLeft = 'showLINK';
    	let AppHeaderLeftLink = '/home';
    	let AppHeaderTitle = <h5 className="text-upper lt-spc1 text-grey5b mb-0 pt-05 pb-05">Membership</h5>
    	let AppHeaderRight = '';
    	let AppHeaderRightLink = '';

    	return(
    		<>
    			{this.state.LOADING && 
    			    <Loader />
    			}

    			<AppHeader 
    			    AppHeaderClass={AppHeaderClass} 
    			    AppHeaderLeft={AppHeaderLeft} 
    			    AppHeaderLeftLink={AppHeaderLeftLink} 
    			    AppHeaderTitle={AppHeaderTitle} 
    			    AppHeaderRight={AppHeaderRight} 
    			    AppHeaderRightLink={AppHeaderRightLink} 
    			/>

    			<div id="appCapsule">
    			   <div className="section membership-section offer-section service-slider service-block pt-1 pb-7">
    			      <div className="row">
                            {/*Bronze Package*/}
                            <div class="section-title  ft-wt-600 section-head font-size-21 pt-2 mb-05 pr-2 pl-2 text-sdgrey">
                                Amanora Bronze Package
                            </div>

                            <div className="col-12">
                               <Link to=''>
                                  <div className="item">
                                     <div className="card mb-3">
                                        <div className="slider-cover">
                                           <img src='assets/images/amanora-bronze-package1.jpg' className="card-img-top imaged w-100" alt="image" />
                                        </div>
                                        <div className="card-body pt-2 pb-3 pr-2 pl-2">
                                           <p className="card-text font-13 l-17 ft-wt-400 mb-05 ">This package includes all Salon services to the extent of ₹ 12500/- only.</p>
                                           <p className="card-text font-11 ft-wt-700 mb-0 text-black">* excluding hair extension, microblading, eyelash extension, makeup  </p>
                                        </div>
                                     </div>
                                  </div>
                               </Link>
                            </div>


                            {/*Sliver Package*/}
                            <div class="section-title  ft-wt-600 section-head font-size-21 pt-2 mb-05 pr-2 pl-2 text-sdgrey">
                                Amanora Silver Package
                            </div>

                            <div className="col-12">
                               <Link to=''>
                                  <div className="item">
                                     <div className="card mb-3">
                                        <div className="slider-cover">
                                           <img src='assets/images/amanora-silver-package2.jpg' className="card-img-top imaged w-100" alt="image" />
                                        </div>
                                        <div className="card-body pt-2 pb-3 pr-2 pl-2">
                                           <p className="card-text font-13 l-17 ft-wt-400 mb-05 ">This package includes all Salon services to the extent of ₹ 35000/- only.</p>
                                           <p className="card-text font-11 ft-wt-500 mb-0 text-black">* excluding hair extension, microblading, eyelash extension, makeup</p>
                                        </div>
                                     </div>
                                  </div>
                               </Link>
                            </div>


                            {/*Gold Package*/}
                            <div class="section-title  ft-wt-600 section-head font-size-21 pt-2 mb-05 pr-2 pl-2 text-sdgrey">
                                Amanora Gold Package
                            </div>

                            <div className="col-12">
                               <Link to=''>
                                  <div className="item">
                                     <div className="card mb-3">
                                        <div className="slider-cover">
                                           <img src='assets/images/amanora-gold-package.jpg' className="card-img-top imaged w-100" alt="image" />
                                        </div>
                                        <div className="card-body pt-2 pb-3 pr-2 pl-2">
                                           <p className="card-text font-13 l-17 ft-wt-400 mb-05 ">This package includes all Salon services to the extent of ₹ 70000/- only.</p>
                                           <p className="card-text font-11 ft-wt-500 mb-0 text-black">* excluding hair extension</p>
                                        </div>
                                     </div>
                                  </div>
                               </Link>
                            </div>

                            {/*Platinum Package*/}
                            <div class="section-title  ft-wt-600 section-head font-size-21 pt-2 mb-05 pr-2 pl-2 text-sdgrey">
                                Amanora Platinum Package
                            </div>

                            <div className="col-12">
                               <Link to=''>
                                  <div className="item">
                                     <div className="card mb-3">
                                        <div className="slider-cover">
                                           <img src='assets/images/amanora-platinum-package.jpg' className="card-img-top imaged w-100" alt="image" />
                                        </div>
                                        <div className="card-body pt-2 pb-3 pr-2 pl-2">
                                           <p className="card-text font-13 l-17 ft-wt-400 mb-05 ">This package includes all Salon services to the extent of ₹ 150000/- only.</p>
                                           <p className="card-text font-11 ft-wt-500 mb-0 text-black">* excluding hair extension</p>
                                        </div>
                                     </div>
                                  </div>
                               </Link>
                            </div>
    			      </div>
    			   </div>
    			</div>

                <AppBottomMenu activeClass="offers" />
    		</>
    	)
    }
}

export default Membership;