import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import AppBottomMenu from './AppBottomMenu';
import Loader from './Loader';
import $ from "jquery";

class Contact extends Component {
	constructor(props) {
		super(props);
		this.state = {
			LOADING:true
		}
	}

	componentDidMount() {
		this.setState({
			LOADING:false
		})
	}

	componentWillMount() {
	    let mount = $("div").hasClass("modal-backdrop");
	    if (mount == true) {
	        $('body').removeClass('modal-open');
	        $(".modal-backdrop").remove();
	    }
	}

	render() {
		let AppHeaderClass = 'appHeader no-border bg-coach-header text-light pt-2 pb-2';
		let AppHeaderLeft = 'showLINK';
		let AppHeaderLeftLink = '/profile';
		let AppHeaderTitle = <h5 className="text-upper lt-spc1 text-grey5b pt-05 pb-0 mb-0">Contact Us</h5>;
		let AppHeaderRight = '';
		let AppHeaderRightLink = '';

		return(
			<>
			    {this.state.LOADING && 
			        <Loader />
			    }

			    <AppHeader 
                    AppHeaderClass={AppHeaderClass} 
                    AppHeaderLeft={AppHeaderLeft} 
                    AppHeaderLeftLink={AppHeaderLeftLink} 
                    AppHeaderTitle={AppHeaderTitle} 
                    AppHeaderRight={AppHeaderRight} 
                    AppHeaderRightLink={AppHeaderRightLink} 
                />

                <div id="appCapsule">
                    <div className="contact-section section register full mt-0 mb-2 basic-form">
                        <div className="wide-block no-border mt-1 pb-1 pt-0 pr-3 pl-3">
                            <h5 className="text-yellow text-upper pb-0 mb-05">Location</h5>
                            <p id="pin-here" className="font-13 l-17 ft-wt-400 lt-spc05 text-dgrey7d">Amanora Beauty Lounge,<br/> S4, Porvorim Trade Centre, NH 14,<br/> Porvorim, Bardez Goa</p><br/>             
                            
                            <h5 className="text-yellow text-upper pb-0 mb-05">Customer Services</h5>
                            <p className="call-cover pt-05 mb-05 font-13 ft-wt-400 lt-spc05 text-dgrey7d"> Tel: 
                                <a className="banner-contact text-gre5 mt-0 wow fadeInUp" href="tel:+917438888666"> +91 7438888666 /</a>
                                <a className="banner-contact mt-0 wow fadeInUp" href="tel:+917438888777"> +91 7438888777</a>
                            </p>
                            <p className="call-cover pt-05 mt-0 mb-0 font-13 ft-wt-400 lt-spc05 text-dgrey7d">Email:  
                                <a className="banner-contact mt-0 wow fadeInUp" href="mailto:info@amanoralounge.com"> info@amanorabeautylounge.com</a>
                            </p>
                        </div>
                    </div>
                </div>
                <AppBottomMenu activeClass="profile" />
			</>
		)
	}
}
export default Contact;