import React, { Component } from 'react';
import Routes from './routes';

class App extends Component {
  constructor() {
    super();
    this.state = {
      appName: "Amanora Beauty Lounge",
    }
  }

  render() {
    return (
        <Routes name={this.state.appName} />
    );
  }
}

export default App;