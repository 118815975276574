import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import AppHeader from './AppHeader';
import Loader from './Loader';
import ModalAlert from './ModalAlert';
import $ from "jquery";

const responseGoogle = (response) => {
    // console.log(response);
}

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LOADING: true,
            Notifications: "",
            modalType:'ALERT', 
            notifType:'', 
            modalTitle:'',
            Logged: false,
            CUSTOMER_DET: ''
        };

        this.loginGoogleUser = this.loginGoogleUser.bind(this);
        localStorage.removeItem('addrId');
        localStorage.removeItem('addrStr');
    }

    loginGoogleUser = (response) => {

        let profileObj = response.profileObj;
        var userObject = {
                            mode:"GOOGLE",
                            googleId: response.googleId,
                            accessToken:response.accessToken,
                            email:profileObj.email,
                            lastName:profileObj.familyName,
                            firstName:profileObj.givenName,
                            profileImage: profileObj.imageUrl,
                            fullName: profileObj.name,
                            webpushr_id:localStorage.getItem('WEBPUSHR_ID')
                        };

        localStorage.setItem('userObject', JSON.stringify(userObject));
        var userData = localStorage.getItem('userObject');
        var userDataJson = JSON.parse(userData);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(userObject)
        };

        var that = this;
        // fetch check if google id/email id exists
        fetch(global.config.bURL+'login_api.php', requestOptions)
            .then(function(response) {
                return response.json();
            })
            .then(function(result) {
                let notify = '';
                if(result.err!=='' && result.err!==undefined)
                    notify = result.err;

                let logged = false;

                let customer = result.customer;
                if(customer!=='' && customer!==undefined)
                {
                    localStorage.setItem('userData',customer);
                    logged = true;
                }

                that.setState({ Notifications: notify, Logged:logged });
                if(notify!=='')
                    window.$('#ModalAlert').modal('show');
            });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let err = 0;
        if ($('#frmLogin')[0].checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            err++;
        }
        $('#frmLogin')[0].classList.add('was-validated');

        if (err == 0) {
            var pass = $('#txtpassword').val();
            $('#txtpassword').val(window.hex_md5(pass));
            
            $('#submitLoading').css('display','block');

            const method = "POST";
            const body = new FormData(this.form);
            var that = this;
            
            fetch(global.config.bURL+'customerLogin.php', { method, body })
            .then(function (response) {
                return response.json();
            })
            .then(result => {
                // console.log(result);
                let notify = '';
                let notifyType = "success";
                if(result.err!=='' && result.err!==undefined) {
                    notify = result.err;
                    notifyType = "error";
                }

                let logged = false;
                let customer = result.customer;
                if(customer!=='' && customer!==undefined)
                {
                    logged = true;
                    localStorage.setItem('userData',customer);
                }
                this.setState({ 
                    Logged: logged,
                    notifType:notifyType,
                    Notifications: notify
                });

                $('#submitLoading').css('display','none');
                if(notify!=='')
                    window.$('#ModalAlert').modal('show');
            })
        }
    }

    componentDidMount() {
        this.setState({
            LOADING: false
        })
    }

    render() {

        if(localStorage.getItem('userData')) {
            return (<Redirect to={'/Home'}/>)
        }

        if (this.state.Logged) {
            return (<Redirect to={'/Home'}/>)
        }

        let AppHeaderClass = 'appHeader no-border bg-coach-header text-light pt-2 pb-2';
        let AppHeaderLeft = 'showLINK';
        let AppHeaderLeftLink = '/';
        let AppHeaderTitle = <h5 className="text-upper lt-spc1 text-grey5b pt-05 pb-05">Login</h5>; //<img src="assets/images/amanora-logo2.png" alt="alt" className="imaged square" />
        let AppHeaderRight = '';
        let AppHeaderRightLink = '';


        return (
            <>
                {this.state.LOADING && 
                    <Loader />
                }
                
                <AppHeader 
                    AppHeaderClass={AppHeaderClass} 
                    AppHeaderLeft={AppHeaderLeft} 
                    AppHeaderLeftLink={AppHeaderLeftLink} 
                    AppHeaderTitle={AppHeaderTitle} 
                    AppHeaderRight={AppHeaderRight} 
                    AppHeaderRightLink={AppHeaderRightLink} 
                />

                <div id="appCapsule">
                    <div className="section login-form full mt-0 mb-2 basic-form">
                        <div className="wide-block no-border mt-1 pb-1 pt-3 pr-3 pl-3">
                            <form name="frmLogin" id="frmLogin" ref={el => (this.form = el)} onSubmit={this.handleSubmit} className="needs-validation" noValidate autoComplete="off">
                                <input type="hidden" value="SIGNIN" className="form-control" name="mode" id="mode" required />
                                <input type="hidden" value={localStorage.getItem('WEBPUSHR_ID')?localStorage.getItem('WEBPUSHR_ID'):''} className="form-control" name="push_id" id="push_id" />

                                <ul className="listview image-listview no-line no-space flush mr-1 pb-4">
                                    <li>
                                        <div className="item">
                                            <div className="icon-box">
                                                <ion-icon className="field-icons" name="person-outline"></ion-icon>
                                            </div>
                                            <div className="in">

                                                <div className="form-group basic">
                                                    <div className="input-wrapper guest-detail float-label">
                                                        <input type="text" className="form-control" name="txtusername" id="txtusername" required="required" minLength="10" maxLength="10" pattern="[0-9]{10}" />
                                                        <label className="floating-label" htmlFor="name">Mobile</label>
                                                        <i className="clear-input">
                                                            <ion-icon name="close-circle"></ion-icon>
                                                        </i>
                                                        <div className="invalid-feedback">Enter a valid mobile number</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="item">
                                            <div className="icon-box">
                                                <ion-icon className="field-icons" name="lock-closed-outline"></ion-icon>
                                            </div>
                                            <div className="in">
                                                <div className="form-group basic">
                                                    <div className="input-wrapper guest-detail float-label">
                                                        <input type="password" className="form-control" name="txtpassword" id="txtpassword"  required="requireds" />
                                                        <label className="floating-label" htmlFor="password6">Password</label>
                                                        <i className="clear-input">
                                                            <ion-icon name="close-circle"></ion-icon>
                                                        </i>
                                                        <div className="invalid-feedback">Enter a valid password</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="item">
                                            <div className="in">
                                                <div className="form-group basic">
                                                    <div className="input-wrapper">
                                                        <div className="form-links links-inline pt-4">
                                                            <div className="">
                                                                <Link to="/forgotpassword" className="text-dgreyb font-weg-500 font-13">Forgot Password?</Link>
                                                            </div>
                                                            <div className="w-55">
                                                                <button type="submit" className="btn w-100 rounded bt-bg-gold btn-block btn-lg mr-0 ml-0 text-upper font-14 lt-spc2 ft-wt-700 btn-ht60 box-shadowg">
                                                                    <span className="spinner-border spinner-border-sm mr-05" id="submitLoading" style={{ display: 'none' }} role="status" aria-hidden="true"></span>
                                                                    Login
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </form>

                            {/*<div className="mt-6 pt-6">
                                <div className="sep-border mb-2"></div>
                                <p className="font-14 lt-spc1 ft-wt-400 text-dgrey5 text-center sep-p">or login using</p>
                                <div className="pt-4 pb-1">
                                    <div className="row">
                                        <div className="col-3"></div>
                                        <div className="col-3">
                                            <GoogleLogin 
                                                clientId="202481004765-nifnb1i75832bb6it98e7cptr1sehtsr.apps.googleusercontent.com"
                                                buttonText="LOGIN USING GOOGLE"
                                                cookiePolicy={'single_host_origin'}
                                                onSuccess={this.loginGoogleUser}
                                                onFailure={responseGoogle}
                                                className="outer-circle mar-auto bg-google"
                                                style={{width:'100%'}}
                                            />
                                        </div>
                                        <div className="col-3"></div>
                                    </div>

                                </div>
                            </div>*/}
                            <div className="section double mt-1 mb-5">
                                <div className="form-button-group bg-brown">
                                    <Link to="/Register" className="btn btn-coach-pink rounded btn-block text-gold btn-mid lt-spc05">You don't have an account? Sign Up</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ModalAlert modalType={this.state.modalType} Notifications={this.state.Notifications} type={this.state.notifType} modalTitle={this.state.modalTitle} />
            </>
        )
    }
}

export default Login;