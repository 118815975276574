import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import AppBottomMenu from './AppBottomMenu';
import ModalAlert from './ModalAlert';
import Moment from 'react-moment';
import ReactStars from "react-rating-stars-component";
import $ from "jquery";

class EditProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            LOADING: true,
            Notifications: "",
            modalType: 'ALERT', 
            notifType: 'error', 
            modalTitle: '',
            rate_stars: 2,
            rate_feedback: "",
            customerid: localStorage.getItem('userData'),
            feedback_det: [],
            booking_id: props.match.params.bid,
            UPDATED: "N",
            status: "",
        }

        this.getBookingDetails = this.getBookingDetails.bind(this);
        this.getFeedbackDetails = this.getFeedbackDetails.bind(this);
        this._changeStar = this._changeStar.bind(this);
    }

    getBookingDetails() {
        const posturl = global.config.bURL+"getBooking.php";
        const postData = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({mode: 'GET_DETAILS', customerid:this.state.customerid, bookingid:this.state.booking_id })
        }
        
        fetch(posturl, postData)
            .then(res => res.json())
            .then(result => {
                console.log('results obtained', result);
        
                let notify = '';
                if(result.err!=='' && result.err!==undefined)
                    notify = result.err;
        
                this.setState({ 
                    Notifications:notify,
                    bookings: result.BOOKINGS
                });
        
                if(notify!=='') {
                    window.$('#ModalAlert').modal('show');
                }
            })
            .catch(error => {
                this.setState({ Notifications: error.toString() });
                console.error('There was an error!', error);
            });
    }

    getFeedbackDetails() {
        const posturl = global.config.bURL+"feedback.php";
        const postData = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({mode: 'FEEDBACK2', customerid:this.state.customerid, bookingid:this.state.booking_id })
        }
        
        fetch(posturl, postData)
            .then(res => res.json())
            .then(result => {
                console.log('results obtained', result);
        
                let notify = '';
                if(result.err!=='' && result.err!==undefined)
                    notify = result.err;
        
                this.setState({ 
                    Notifications: notify,
                    rate_stars: (result.FEEDBACK)?result.FEEDBACK.RATING:0,
                    rate_feedback: (result.FEEDBACK)?result.FEEDBACK.FEEDBACK:'',
                    status: result.status,
                    LOADING: false
                });
        
                if(notify!=='') {
                    window.$('#ModalAlert').modal('show');
                }
            })
            .catch(error => {
                this.setState({ Notifications: error.toString() });
                console.error('There was an error!', error);
            });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let err = 0;
        if ($('#frmUpdate')[0].checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            err++;
        }
        $('#frmUpdate')[0].classList.add('was-validated');

        if(err===0)
        {
            $('#submitLoading').css('display','block');

            const method = "POST";
            const body = new FormData(this.form);
            fetch(global.config.bURL+'feedback.php', { method, body })
            .then(function (response) {
              return response.json();
          })
            .then(result => {
              let notify = '';
              let notifyType = "success";

              if(result.err!=='' && result.err!==undefined) {
                notify = result.err;
                notifyType = "error";
              }

              if(result.UPDATED=='Y') {
                 notify = "Updated Successfully";
              }

            let customer = result.customer;
            if(customer!=='' && customer!==undefined)
            {
                localStorage.setItem('userData',customer);
            }

            this.setState({ 
                notifType:notifyType,
                Notifications: notify,
                UPDATED: result.UPDATED
            });
            $('#submitLoading').css('display','none');
            if(notify!=='')
                window.$('#ModalAlert').modal('show');
        });
        }
    }

    _changeStar = (new_star) => {
        this.setState({
            rate_stars: new_star
        })
    }

    componentDidMount() {
        this.getBookingDetails();
        this.getFeedbackDetails();
    }

    render() {
        if(!localStorage.getItem('userData')) {
            return (<Redirect to={'/logout'} />)
        }

        let AppHeaderClass = 'appHeader no-border bg-coach-header text-light pt-2 pb-2';
        let AppHeaderLeft = 'showLINK';
        let AppHeaderLeftLink = '/appointments';
        let AppHeaderTitle = <h5 className="text-upper lt-spc1 text-grey5b mb-0 pt-05 pb-05">Feedback</h5>
        let AppHeaderRight = '';
        let AppHeaderRightLink = '';
        

        return(
            <>
                {this.state.LOADING && 
                    <Loader />
                }

                <AppHeader 
                    AppHeaderClass={AppHeaderClass} 
                    AppHeaderLeft={AppHeaderLeft} 
                    AppHeaderLeftLink={AppHeaderLeftLink} 
                    AppHeaderTitle={AppHeaderTitle} 
                    AppHeaderRight={AppHeaderRight} 
                    AppHeaderRightLink={AppHeaderRightLink} 
                />

                <div id="appCapsule">
                    <div className="section register full mt-0 mb-2 basic-form">
                        <div className="wide-block no-border mt-1 pb-1 pt-2 pr-3 pl-3">
                            <form id="frmUpdate" ref={el =>(this.form = el)} onSubmit={this.handleSubmit} className="star-feedback needs-validation" noValidate autoComplete="off">
                                <input type="hidden" value="FEEDBACK" className="form-control" name="mode" id="mode"  />
                                <input type="hidden" value={localStorage.getItem('userData')} className="form-control" name="customerid" id="customerid"  />
                                <input type="hidden" value={this.state.booking_id} className="form-control" name="bookingid" id="bookingid"  />
                                <input type="hidden" defaultValue={localStorage.getItem('WEBPUSHR_ID')} className="form-control" name="push_id" id="push_id"  />
                                <input type="hidden" defaultValue={this.state.rate_stars} className="form-control" name="rating" id="rating"  />

                                <div className="listview image-listview no-line no-space flush mr-2 ml-2 pb-4">
                                    {this.state.status!='' &&
                                        <ReactStars
                                            classNames=""
                                            count={5}
                                            value={this.state.rate_stars}
                                            edit={(this.state.status == 'N')?true:false}
                                            onChange={this._changeStar}
                                            size={50}
                                            isHalf={false}
                                            emptyIcon={<i className="far fa-star"></i>}
                                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                                            fullIcon={<i className="fa fa-star"></i>}
                                            activeColor="#ebae28"
                                        />
                                    }
                                    <div className="form-group basic">
                                        <div className="input-wrapper guest-detail float-label pt-3">
                                            <label className="eform-label" htmlFor="txtname">Your comment</label>
                                            <textarea id="feedback" readOnly={(this.state.status == 'N')?false:true} name="feedback" rows="1" class="form-control" defaultValue={this.state.rate_feedback}></textarea>
                                            <i className="clear-input">
                                                <ion-icon name="close-circle"></ion-icon>
                                            </i>
                                        </div>
                                    </div>

                                    {this.state.status == 'N' &&
                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <div className="w-100 pt-3">
                                                  <button type="submit" className="btn w-100 rounded bt-bg-gold btn-block btn-lg mr-0 ml-0 text-upper font-14 lt-spc2 ft-wt-600 btn-ht60 box-shadowg">
                                                    <span className="spinner-border spinner-border-sm mr-05" id="submitLoading" style={{ display: 'none' }} role="status" aria-hidden="true"></span>
                                                    Submit
                                                  </button>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                          </form>
                      </div>
                  </div>

                  {this.state.Notifications != '' &&
                    <ModalAlert modalType={this.state.modalType} Notifications={this.state.Notifications} type={this.state.notifType} modalTitle={this.state.modalTitle} />
                  }
                </div>
            </>
        )
    }
}

export default EditProfile;