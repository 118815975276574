import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import ModalAlert from './ModalAlert';
import AppBottomMenu from './AppBottomMenu';
import BookingStrip2 from './BookingStrip2';
import SearchBar from './SearchBar';
import $ from "jquery";

class Category extends Component {
    constructor(props) {
    	super(props);

    	this.state = {
    		LOADING: true,
    		Notifications: false,
    		modalType: 'ALERT', 
    		notifType: 'error', 
    		modalTitle: '',
    		categoryid: (props.match.params.cid)?props.match.params.cid:0,
    		mainCatId: (props.match.params.tid)?props.match.params.tid:localStorage.getItem('maincatid'),
    		serviceCat: [],
    		serviceSubCat: [],
    		maincat:[],
    		customerid: localStorage.getItem('userData'),
    		propUrl: '',
    		propRedirect: false
    	}

    	this.getSubCat = this.getSubCat.bind(this);
    	localStorage.setItem('backClick', this.state.categoryid);
    	localStorage.setItem('maincatid', this.state.mainCatId);
    }

    getMainCat() {
    	const posturl = global.config.bURL+"serviceCategory.php";
    	const postData = {
    		method: 'POST',
	        headers: { 'Content-Type': 'application/json' },
	        body: JSON.stringify({ mode: 'SERVICE_CATEGORY', type: "parent", cond: "" })
    	}

    	fetch(posturl, postData)
    		.then(res => res.json())
    		.then(result => {
    			// console.log('results obtained', result);

    			let notify = '';
    			if(result.err!=='' && result.err!==undefined)
    			    notify = result.err;

    			this.setState({
    				serviceCat: result.category
    			})
    			
    			if(notify!=='')
    			    window.$('#ModalAlert').modal('show');
    		})
    		.catch(error => {
	            this.setState({ Notifications: error.toString() });
	            console.error('There was an error!', error);
	        });
    }

    getSubCat() {
    	const posturl = global.config.bURL+"serviceCategory.php";
    	let cond_str = "";//(this.state.categoryid)?" and iParentID="+this.state.categoryid: " and iParentID<>0";
    	
    	/*if(this.state.mainCatId!=0 && this.state.mainCatId!=undefined && this.state.mainCatId!="")
    		cond_str += " and iTypeID="+this.state.mainCatId;*/

    	const postData = {
    		method: 'POST',
	        headers: { 'Content-Type': 'application/json' },
	        body: JSON.stringify({ mode: 'SERVICE_CATEGORY', type: "subcat", cond: cond_str })
    	}

    	fetch(posturl, postData)
    		.then(res => res.json())
    		.then(result => {
    			// console.log('results obtained', result);

    			let notify = '';
    			if(result.err!=='' && result.err!==undefined) {
    			    notify = result.err;
    			}
    			else {
	    			this.setState({
	    				serviceSubCat: result.category2,
	    				maincat: result.types,
	    				LOADING: false
	    			})
	    		}
    			
    			if(notify!=='')
    			    window.$('#ModalAlert').modal('show');
    		})
    		.catch(error => {
	            this.setState({ Notifications: error.toString() });
	            console.error('There was an error!', error);
	        });
    }

    registermaincat = (main_id) => {
    	// console.log('changing main id', main_id);
    	localStorage.setItem('maincatid', main_id);
    }

    componentDidMount() {
    	this.getMainCat();
    	this.getSubCat();
    }

    componentDidUpdate(prevProps) {
    	var catid = prevProps.match.params.cid;
    	var typeid = prevProps.match.params.tid;
    	var new_catid = this.props.match.params.cid;
    	var new_typeid = this.props.match.params.tid;

  		if (new_catid !== catid || new_typeid !== typeid ) 
  		{
  			window.location.reload();
  			// console.log('reloading now');
  			/*this.setState({
  				propUrl: this.props.match.url,
				propRedirect: true
  			});*/
  		}
	}

	handleTabSwitch = (catid) => {
		this.setState({
			categoryid:catid,
		})
	}

    render() {
    	/*if(!localStorage.getItem('userData')) {
    		return (<Redirect to={'/logout'}/>)
    	}*/

    	let AppHeaderClass = 'appHeader no-border bg-coach-header text-light pt-2 pb-2';
    	let AppHeaderLeft = 'showLINK';
    	let AppHeaderLeftLink = '/home';
    	let AppHeaderTitle = <h5 className="text-upper lt-spc1 text-grey5b mb-0 pt-05 pb-05">Services</h5>
    	let AppHeaderRight = '';
    	let AppHeaderRightLink = '';

    	if(this.state.propRedirect)
    	{
    		return(<Redirect to={this.state.propUrl} />);
    	}

    	return(
			<>
				{this.state.LOADING && 
	                <Loader />
	            }

	            <AppHeader 
                    AppHeaderClass={AppHeaderClass} 
                    AppHeaderLeft={AppHeaderLeft} 
                    AppHeaderLeftLink={AppHeaderLeftLink} 
                    AppHeaderTitle={AppHeaderTitle} 
                    AppHeaderRight={AppHeaderRight} 
                    AppHeaderRightLink={AppHeaderRightLink} 
                />

                <div id="appCapsule">
                	<div className="section full mt-0 mb-2 multitab">
                		<div className="choose-from wide-block pt-0 pb-0 pr-0 pl-0 mt-2  mr-2 ml-2 curve">
                			<ul className="nav nav-tabs style1" role="tablist">
	                        	{this.state.maincat && this.state.maincat.length > 0 &&
	                        		this.state.maincat.map((main, i)=>{
	                        			
	                        			let tabUrl = main.URLNAME;
	                        			let activeClass = "nav-link";

	                        			if(main.ID == this.state.mainCatId)
	                        				activeClass = "nav-link active";

	                        			return(
		                        			<li className="nav-item" id={main.ID} key={i}>
		                        			    <a className={activeClass} data-toggle="tab" href={'#'+tabUrl} role="tab" onClick={()=>this.registermaincat(main.ID)}>
		                        			    	<div dangerouslySetInnerHTML={{__html: main.ICON}} />
		                        			        <p className="mb-0 text-upper">{main.NAME}</p>
		                        			    </a>
		                        			</li>
		                        		)
	                        		})
	                        	}
                			</ul>
                		</div>

                		<div className="tab-content mt-1 pb-5">
	                		{this.state.maincat && this.state.maincat.length > 0 &&
	                			this.state.maincat.map((main, i)=>{
	                				
	                				let tabUrl = main.URLNAME;
	                				let activeClass = "tab-pane fade";

	                				if(main.ID == this.state.mainCatId)
	                					activeClass = "tab-pane fade show active";

	                				return(
				                    	<div className={activeClass} id={tabUrl} role="tabpanel" key={i}>
				                    		<SearchBar />
				                    	    <div className="section pt-2 pb-0 pr-0 pl-0 mb-0">
					                    	    <div className="pl-2 pr-2 ht-less">
					                    	    	<ul className="nav nav-tabs lined" role="tablist">
                      		    			         	{this.state.serviceCat && this.state.serviceCat.length !==0 &&
                      		    			         		this.state.serviceCat.map((category, i) => {
                      		    			         			let c_url = '/category/'+category.ID+'/'+main.ID;
                      		    			         			let categoryIcon = category.ICON;
                      		    			         			let activeClass2 = "nav-link";
                      		    			         			let id = "#subtab-"+main.ID+category.ID;

                      		    			         			if(this.state.categoryid == 0 && i==0) activeClass2 = "nav-link active";
                      		    			         			else if(this.state.categoryid == category.ID) activeClass2 = "nav-link active";

                      		    			         			if(main.ID == 2) categoryIcon = category.ICON1;

                      		    			         			return(
	         					                    	    	    <li className="nav-item" key={i}>
	         					                    	    	        <a className={activeClass2} data-toggle="tab" href={id} role="tab" onClick={()=>this.handleTabSwitch(category.ID)}>
	         					                    	    	            <div className="cat-image">
	         					                    	    	               <img src={categoryIcon} width="100%" alt={category.NAME} />
	         					                    	    	            </div>
	         				                    	    	                <p className="ft-wt-500 mb-0 pt-05">{category.NAME}</p>
	         					                    	    	        </a>
	         					                    	    	    </li>
                      			    			         		)
                      		    			         		})
                      		    			         	}
	      					                      	</ul>

	      					                      	<div className="tab-content mt-1">
	      					                      		{this.state.serviceCat && this.state.serviceCat.length !==0 &&
	      					                      			this.state.serviceCat.map((category, i) => {
                      		    			         			let id = "subtab-"+main.ID+category.ID;

                      		    			         			let activeClass3 = "tab-pane fade";
                      		    			         			if(this.state.categoryid == 0 && i==0) activeClass3 = "tab-pane fade show active";
                      		    			         			else if(this.state.categoryid == category.ID) activeClass3 = "tab-pane fade show active";

                      		    			         			return(
		                    	    	                        	<div className={activeClass3} id={id} role="tabpanel" key={i}>
		                    	    	                        	    <div className="section pt-2 pb-0 pr-0 pl-0 mb-0">
		                    	    	    	                    	    <div className="row">
	            	    	      					                      		{this.state.serviceSubCat && this.state.serviceSubCat.length!==0 &&
	            	    	      					                      			this.state.serviceSubCat.map((subcat, i) => {
	            	    	      					                      				let s_url = '/services/'+subcat.ID;
	            	    	      					                      				let image_str = (subcat.IMAGE)?subcat.IMAGE:process.env.PUBLIC_URL+"/assets/images/hair-img.jpg";

	            	    	      					                      				if(subcat.TYPE_ID == main.ID && subcat.PARENT_ID == category.ID)
	            	    	      					                      				{
	            	    		      					                      				return(
	            	    		      					                      					<div className="col-6" key={i}>
	            	    		      								                            	<Link to={s_url}>
	            	    		      								                               		<div className="item">
	            	    		      								                                  		<div className="card mb-3">
	            	    		      								                                     		<div className="slider-cover">
	            	    		      								                                        		<img src={image_str} className="card-img-top imaged w-100" alt={subcat.NAME} />
	            	    		      								                                     		</div>
	            	    		      								                                     		<div className="card-body pt-1 pb-3 pr-2 pl-2">
	            	    		      								                                        		<p className="card-text text-upper font-13 ft-wt-600 mb-0">{subcat.NAME}</p>
	            	    		      								                                     		</div>
	            	    		      								                                  		</div>
	            	    		      								                               		</div>
	            	    		      								                            	</Link>
	            	    		      								                         	</div>
	            	    		      					                      				)
	            	    		      					                      			}
	            	    	      					                      			})
	            	    	      					                      		}
		                    	    	    	                    	    </div>
		                    	    	                        	    </div>
		                    	    	                        	</div>
                      			    			         		)	
	      					                      			})
	      					                      		}
	      					                      	</div>
					                    	    </div>
					                    	</div>
					                    </div>
	                				)
	                			})
	                		}

                		</div>
                	</div>
                </div>

	            <AppBottomMenu activeClass="category" />
	            <BookingStrip2 />
	            <ModalAlert modalType={this.state.modalType} Notifications={this.state.Notifications} type={this.state.notifType} modalTitle={this.state.modalTitle} />
	        </>
    	)
    }
}

export default Category;