import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import ModalAlert from './ModalAlert';
import AppBottomMenu from './AppBottomMenu';
import BookingCalendar from './BookingCalendar';
import $ from "jquery";

class Booking extends Component {
    constructor(props) {
    	super(props);

    	this.state = {
    		LOADING: true,
    		Notifications: false,
    		modalType: 'ALERT', 
    		notifType: 'error', 
    		modalTitle: '',
    		customerid: localStorage.getItem('userData'),
    		bookingDet: [],
    		bookingTotal: 0,
    		allowNext: false,
    		rmvidStr: '',
    		varid: 0,
    		rmvall: "N",
    		mainCatId: (localStorage.getItem('maincatid2'))?localStorage.getItem('maincatid2'):1,
    	}

    	// console.log('cartData:', localStorage.getItem('cartData'));
    	// console.log('cartDate:', localStorage.getItem('cartDate'));
    	// console.log('cartTime:', localStorage.getItem('cartTime'));

    	// localStorage.removeItem('cartData');
    	localStorage.removeItem('cartDate');
    	localStorage.removeItem('cartTime');
    	localStorage.removeItem('cartTime2');
    	localStorage.removeItem('bookingCode'); //clear existing booking history
    	localStorage.removeItem('COUPON_APPLIED');

    	this.getCartDetails = this.getCartDetails.bind(this);
    	this.getUserAddresses = this.getUserAddresses.bind(this);
	}

	getUserAddresses() {
	   const posturl = global.config.bURL+"userDetails.php";
	   const postData = {
	      method: 'POST',
	        headers: { 'Content-Type': 'application/json' },
	        body: JSON.stringify({ mode: 'CUSTOMER', id: this.state.customerid })
	   }

	   fetch(posturl, postData)
	      .then(res => res.json())
	      .then(result => {
	         // console.log('results obtained', result);

	         let notify = '';
	         if(result.err!=='' && result.err!==undefined)
	             notify = result.err;

	         this.setState({
	            LOADING: false,
	            customerdet: result.user
	         })

	         /*if(!localStorage.getItem('addrId') || localStorage.getItem('addrId')==0 || localStorage.getItem('addrId')==null || localStorage.getItem('addrId')==undefined)
	         	localStorage.setItem('addrId', result.defaultAddr);*/
	         
	         // if(notify!=='')
	         //     window.$('#ModalAlert').modal('show');
	      })
	      .catch(error => {
	            this.setState({ Notifications: error.toString() });
	            console.error('There was an error!', error);
	        });
	 }

	getCartDetails() {
		const posturl = global.config.bURL+"getCartDetails.php";
		const postData = {
			method: 'POST',
		    headers: { 'Content-Type': 'application/json' },
		    body: JSON.stringify({mode: 'CART', mainid: this.state.mainCatId, service_det: localStorage.getItem('cartData') })
		}
		
		fetch(posturl, postData)
			.then(res => res.json())
			.then(result => {
				// console.log('results obtained', result);
		
				let notify = '';
				if(result.err!=='' && result.err!==undefined)
				    notify = result.err;
		
				this.setState({ 
					LOADING:false,
					bookingDet: result.cart,
					bookingTotal: result.cart_total
				});
		
				if(notify!=='') {
		    		window.$('#ModalAlert').modal('show');
				}
			})
			.catch(error => {
		        this.setState({ Notifications: error.toString() });
		        console.error('There was an error!', error);
		    });
	}

	confirmRemove = (idstr, varid) => {
		this.setState({rmvidStr:idstr, varid:varid});

		if(idstr!='')
			window.$('#confirmAlert').modal('show');
	}

	addPax = (idstr, varid) => {
		let divId = idstr;
		let idarr = idstr.split('-');
		let sid = idarr[2];
		let new_arr = [];
		var newPax = 1;
		var thisItem = "";
		let service_arr = JSON.parse(localStorage.getItem('cartData'));
		service_arr.forEach((element, index) => {
			// // console.log(index+'=>'+element.id);
			if(varid == element.id)
			{
				thisItem = service_arr[index];
				newPax = parseInt(thisItem.pax)+1;
				return false;
			}
		});
		// add pax to this item
		
		thisItem.pax = newPax;

		// console.log("pax added: ", thisItem);
		service_arr.forEach((element, index) => {
			// // console.log(element);
			if(element!=null && element!=undefined)
				new_arr.push(element);
		});
		// console.log('after arr', new_arr);

		// set the new array
		let newarr = localStorage.setItem('cartData', JSON.stringify(new_arr));
		this.getCartDetails();
	}

	removePax = (idstr, varid) => {
		let divId = idstr;
		let idarr = idstr.split('-');
		let sid = idarr[2];
		let new_arr = [];
		var newPax = 1;
		var thisItem = "";
		var currentPax = newPax;

		let service_arr = JSON.parse(localStorage.getItem('cartData'));
		service_arr.forEach((element, index) => {
			// // console.log(index+'=>'+element.id);
			if(varid == element.id)
			{
				thisItem = service_arr[index];
				currentPax = parseInt(thisItem.pax);
				newPax = currentPax-1;
				return false;
			}
		});
		// var thisItem = service_arr[sid];

		// remove pax to this item
		// var currentPax = parseInt(thisItem.pax);
		// var newPax = currentPax-1;
		thisItem.pax = newPax;
		
		if(newPax != 0)
		{
			// console.log("pax removed: ", service_arr);
			service_arr.forEach((element, index) => {
				if(element!=null && element!=undefined)
					new_arr.push(element);
			});
			// console.log('after arr', new_arr);

			// set the new array
			let newarr = localStorage.setItem('cartData', JSON.stringify(new_arr));
			this.getCartDetails();
		}
		else
		{
			this.confirmRemove(idstr, varid);
		}
	}

	_confirm_removeall = () => {
		this.setState({
			rmvidStr:'abc-0-0', 
			varid:0, rmvall:"Y"
		}, ()=>{
			window.$('#confirmAlert').modal('show');
		});
	}

	handleRemove = (idstr, varid, all="N") => {
		let divId = idstr;
		let idarr = idstr.split('-');
		let sid = idarr[2];
		let new_arr = []

		console.log('removing: ', idstr);

		if(sid!='' && sid!=undefined) {
			// remove sid from array
			let service_arr = JSON.parse(localStorage.getItem('cartData'));
			// console.log('before arr', service_arr);
			// delete service_arr[sid];
			if(all =='Y')
			{
				// remove all
			}
			else
			{
				service_arr.forEach((element, index) => {
					// if(element!=null && element!=undefined)
					if(varid != element.id)
						new_arr.push(element);
				});
			}
			// console.log('after arr', new_arr);

			// set the new array
			let newarr = localStorage.setItem('cartData', JSON.stringify(new_arr));
			this.getCartDetails();
		}
	}

	componentDidMount() {
		this.getCartDetails();
		this.getUserAddresses();

		if(localStorage.getItem('cartDate') && localStorage.getItem('cartTime')) {
			this.setState({
				allowNext: true
			})
		}
	}

	render() {
		if(!localStorage.getItem('userData')) {
			return (<Redirect to={'/logout'} />)
		}

		if(!this.state.bookingDet) {
			return (<Redirect to={'/home'} />)
		}

		let AppHeaderClass = 'appHeader no-border bg-coach-header text-light pt-2 pb-2';
		let AppHeaderLeft = 'showLINK';
		let AppHeaderLeftLink = '/home';
		let AppHeaderTitle = <h5 className="text-upper lt-spc1 text-grey5b mb-0 pt-05 pb-05">Book an Appointment</h5>
		let AppHeaderRight = '';
		let AppHeaderRightLink = '';

		let bookingTotal = 0;

		let cart_date = localStorage.getItem('cartDate');
		let cart_time = localStorage.getItem('cartTime');

		return(
			<>
				{this.state.LOADING && 
	                <Loader />
	            }

	            <AppHeader 
                    AppHeaderClass={AppHeaderClass} 
                    AppHeaderLeft={AppHeaderLeft} 
                    AppHeaderLeftLink={AppHeaderLeftLink} 
                    AppHeaderTitle={AppHeaderTitle} 
                    AppHeaderRight={AppHeaderRight} 
                    AppHeaderRightLink={AppHeaderRightLink} 
                />

                <div id="appCapsule" className="pt-1">
                	<div className="section pt-1 pb-1 mt-6">
                		<div className="form-wizard-section appoint-process">
                			<Link to="/booking" className="button-item active">
                				<strong><ion-icon name="checkmark-outline"></ion-icon></strong>
                				<p className="pt-05 text-upper font-12 lt-spc05">Selection</p>
                			</Link>
                			<Link to={(this.state.allowNext)?'/booking2':'#'} className="button-item">
                				<strong><ion-icon name="checkmark-outline"></ion-icon></strong>
                				<p className="pt-05 text-upper font-12 lt-spc05">Choose Slot</p>
                			</Link>
                			<Link to="#" className="button-item">
                				<strong><ion-icon name="checkmark-outline"></ion-icon></strong>
                				<p className="pt-05 text-upper font-12 lt-spc05">Confirmed</p>
                			</Link>
                		</div>
                	</div>
                	<div className="section date-time pt-05 mt-1 mb-1">
                		<ul className="listview image-listview media mt-0 p-1 no-border bdr-radius8 bg-lyellow" data-toggle="modal" data-target="#ModalCalendar">
                			<li>
                				<Link to="#" className="item pr-1 pl-1 pt-1 pb-1">
                					<div className="in">
                						<div className="cal-date text-black font-16 ft-wt-400 l-20">
                							<img src="assets/images/appointment-icon.png" alt="calendar icon" className="imaged w24" />
                							<p className="ft-wt-300 text-center text-upper font-13 pb-0 mb-0 lt-spc05"><span className="font-11 l-17">Choose</span><br/>Date & time</p>
                						</div>
                					</div>
                				</Link>
                			</li>
                		</ul>
                	</div>
                	<div className="section treatment treatment-edit pt-1">
                		<ul className="listview image-listview no-border">
                			<li className="cart-list-head noafter">
                				<div className="item">
                					<div className="in">
                						<div className="set1 font-12 mb-0 ft-wt-500 pr-1 pl-0 pt-0 pb-0 text-grey7">Treatment </div>
                						<div className="set2 mt-0 text-center font-12 text-grey7 ft-wt-500"> Duration </div>
                						<div className="set3 mt-0 text-right font-12 text-grey7 ft-wt-500">Amount</div> 
                						<div className="set4 mt-0 text-center font-12 text-grey7 ft-wt-500">Qty</div>
                					</div>
                				</div>
                			</li>
                			{this.state.bookingDet && this.state.bookingDet.length!=0 &&
                				this.state.bookingDet.map((cart, i) => {
                					let is_added = true;
                					let id_str = 'booking-list-'+i;
                					let id_str2 = 'booking-pax-'+i;
                					{/*bookingTotal += parseInt(cart.RATE);*/}

                					if(!isNaN(i))
                					{
	                					return(
	                						<li key={i} id={id_str} data-sid={cart.SVARID}>
	                							<div className="item">
	                								<div className="in">
	                									<div className="set1 set-name title-cut-sub text-grey4 font-14 ft-wt-500 pb-0">{cart.NAME}</div>
	                									<div className="set2 mt-0 text-center text-grey7 text-upper ft-wt-400 font-12 lt-spc1">{(cart.TIME && cart.TIME!='')?cart.TIME:''}</div>
	                									{cart.OFFER_PRICE == 0 ?
	                										<div className="set3 mt-0 text-right font-8 ft-wt-600 text-grey7">On consultation</div>
	                										:
	                										<div className="set3 mt-0 text-right font-16 ft-wt-600 text-lightred">₹ {cart.OFFER_PRICE_DISP}</div> 
	                									}
	                									{/*<button type="button" onClick={()=>this.confirmRemove(id_str)} className="set4 btn-set4 mt-0 text-center font-12 text-grey7 ft-wt-700">x</button>*/}
	                									<div className="qty-cover">
	                										<button type="button" onClick={()=>this.removePax(id_str, cart.SVARID)} className="set4 btn-set4 mt-0 text-center font-16 text-grey7 ft-wt-700 qtyl">-</button>
		                									<input type="text" className="qty" id={id_str2} value={cart.PAX} readOnly/>
		                									<button type="button" onClick={()=>this.addPax(id_str, cart.SVARID)} className="set4 btn-set4 mt-0 text-center font-16 text-grey7 ft-wt-700 qtyr">+</button>
	                									</div>
	                								</div>
	                							</div>
	                						</li>
	                					)
	                				}
                				})
                			}
                			{this.state.bookingDet && this.state.bookingDet.length!=0 ?
                				<>
	                			<li className="ht-70 noafter">
	                				<div className="item">                  
	                					<div className="in">
	                						<div className="set1 title-cut-sub text-grey4 font-15 ft-wt-500 lt-spc05 pb-05">Total</div>
	                						<div className="set2 mt-0 text-center"></div>
	                						<div className="set3 mt-0 text-right font-18 ft-wt-600">₹ {this.state.bookingTotal}</div> 
	                						{/*<div className="set4 mt-0 text-center font-12 text-grey4 ft-wt-500"></div>*/}
	                					</div>
	                				</div>
	                			</li>
	                			<li className="ht-70 noafter">
	                				<div className="item">                  
	                					<div className="in">
	                						<Link to="#" onClick={()=>this._confirm_removeall()} className="set1 close-service title-cut-sub text-grey4 font-15 ft-wt-500 lt-spc05 pb-05">Clear Cart</Link>
	                					</div>
	                				</div>
	                			</li>
                				<div className="section mt-1 pt-1 pr-2 pl-2 pb-3">
                			   		<Link data-toggle="modal" data-target="#ModalCalendar" to="#" className="btn w-100 rounded bt-bg-gold btn-block btn-lg mr-0 ml-0 text-upper font-14 lt-spc2 ft-wt-600 btn-ht60 box-shadowg">Choose Date
                			   		</Link>
                				</div>
                				</>
	                			:
	                			<li className="ht-70 noafter">
	                				<div className="item">                  
	                					<div className="in">
	                						<div className="set1 close-service title-cut-sub text-grey4 font-15 ft-wt-500 lt-spc05 pb-05">No services selected for booking</div>
	                					</div>
	                				</div>
	                			</li>
	                		}
                		</ul> 
                	</div>
                </div>

                <AppBottomMenu activeClass="appointment" />
                <BookingCalendar selected_date={cart_date} selected_timeslot={cart_time} />
                <ModalAlert modalType={this.state.modalType} Notifications={this.state.Notifications} type={this.state.notifType} modalTitle={this.state.modalTitle} />

                <div className="modal fade dialogbox" id="confirmAlert" data-backdrop="static" tabIndex="-1" role="dialog">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <p className="modal-title text-upper font-15 pb-1">Remove Item</p>
                      </div>
                      <div className="modal-body mb-05"><p className="font-15">This item will be removed from your booking</p></div>
                      <div className="modal-footer">
                        <div className="btn-inline"> 
                            <button type="button" className="btn btn-text-secondary text-red font-14" data-dismiss="modal">CANCEL</button> 
                            <button type="button" onClick={()=>this.handleRemove(this.state.rmvidStr, this.state.varid, this.state.rmvall)} className="btn btn-text-primary text-gold font-14" data-dismiss="modal">CONFIRM</button> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
			</>
		)
	}
}

export default Booking;